import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "../../../../axios-order";
import moment from "moment";
import { ValidatorForm } from "react-material-ui-form-validator";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Select, MenuItem, FormControl, InputLabel, Button, Box } from '@mui/material';


const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;



export default function EInvoiceRepository() {
    const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
    const [fileName, setFileName] = useState("");
    const [fileDataToUpload, setFileDataToUpload] = useState("");
    //error
    const [showSuccessOnly, setShowSuccessOnly] = useState(false)

    //...............For Error Message..........................
    const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
        useState("hidden");
    const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
        useState("none");
    const [recordForTable, setRecordForTable] = useState([]);
    const [recordForTableSearch, setRecordForTableSearch] = useState([]);

    const [loaderForTable, setLoaderForTable] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseData, setResponseData] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [selectedOption, setSelectedOption] = useState("");

    // Function to open Snackbar
    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");
    };


    const handleToastifyNotification = (uploadFailReason, color) => {
        handleSnackbarOpen(uploadFailReason, color);
    };
    const v = {
        upload_status: true,
        upload_fail_reason: 'Upload failed due to an error.',
    };


    useEffect(() => {

        const timer = setTimeout(() => {
            setResponseMessage("");
        }, 7000);

        return () => clearTimeout(timer);
    }, [responseMessage]);


    //.............Open And Close Event For Model.............
    const handleOpenAndClose = () => {
        setOpenAndCloseModel(!openAndCloseModel);
        functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
        setFileDataToUpload("");
        setFileName("");
    };

    const fileUploadCancelButtonHandler = () => {
        functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
        setFileDataToUpload("");
        setFileName("");
    };

    //.......Handle Search....................
    const handleSearch = (e) => {
        let Search = e.target.value.trim();
        const searchName = recordForTableSearch.filter((v) => {
            return (
                String(v.gstr_upload_file_type)
                    .toLowerCase()
                    .indexOf(Search.toLowerCase()) >= 0 ||
                String(v.financial_year).toLowerCase().indexOf(Search.toLowerCase()) >=
                0 ||
                String(v.generation_date).toLowerCase().indexOf(Search.toLowerCase()) >=
                0 ||
                String(moment(v.tax_period, "MMYYYY").format("MM-YYYY")).indexOf(
                    Search
                ) >= 0 ||
                String(
                    moment(v.upload_date, "YYYY-MM-DD").format("DD-MM-YYYY")
                ).indexOf(Search) >= 0
            );
        });
        setRecordForTable(searchName);
    };

    //........For Succesfull Message..................
    const functionForSuccesfullMessageAndErroeMessage = (
        color,
        visible,
        display,
        message
    ) => {
        setColorForErrorMessage(color);
        setErrorMessageVisibilityStyle(visible);
        setErrorMessageDisplayStyle(display);
        setErrorMessage(message);
    };

    //.............File Upload Data Saving in useState..............
    const handleFileUpload = (e) => {
        functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
        if (e.target.files.length) {
            const file_data = e.target.files[0];
            let fileName = file_data.name;
            setFileName(fileName);
            const reader = new FileReader();
            reader.readAsDataURL(file_data);
            reader.onload = function () {
                let file_value = reader.result;
                setFileDataToUpload(file_value);
            };
            e.target.value = null;
        }
    };

    //......Submit Event For File Upload in axios.......
    const handleSubmitEvent = (e) => {
        functionForSuccesfullMessageAndErroeMessage(
            "green",
            "visible",
            "block",
            "Uploading file Please Wait..."
        );
        if (fileName === "") {
            functionForSuccesfullMessageAndErroeMessage(
                "red",
                "visible",
                "block",
                "Please Upload The File!"
            );
        } if (selectedOption === "") {
            functionForSuccesfullMessageAndErroeMessage(
                "red",
                "visible",
                "block",
                "Please Select File Type!"
            );
        } else {
            axios
                .post(`/e_invoice_repo_upload`, {
                    file: fileDataToUpload,
                    gstin: localStorage.getItem("gst"),
                    business_entity_id: localStorage.getItem('business_entity_id'),
                    file_type: selectedOption
                })
                .then((res) => {
                    getApiForEinvoiceRepo();
                    if (res.data.Status === true) {
                        // handleOpenAndClose();
                        functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
                        getApiForEinvoiceRepo();
                    } else {
                        functionForSuccesfullMessageAndErroeMessage(
                            "red",
                            "visible",
                            "block",
                            res.data.Message
                        );
                        resetFileUpload();
                    }
                })
                .catch((error) => {
                    functionForSuccesfullMessageAndErroeMessage(
                        "red",
                        "visible",
                        "block",
                        error.response.data.Message
                    );
                    resetFileUpload();
                });
        }
    };

    const resetFileUpload = () => {
        setFileDataToUpload(null);
        setFileName("");
        setSelectedOption('')
    };

    //.......................Get Api For Gst................
    const getApiForEinvoiceRepo = () => {
        setLoaderForTable(true);
        axios
            .get(`/get_e_invoice_repo_upload_summary/${localStorage.getItem("gst")}`)
            .then((res) => {
                if (res.data.status === true) {
                    setLoaderForTable(false);
                    setRecordForTable(res.data.record);
                    setRecordForTableSearch(res.data.record);
                } else {
                    setLoaderForTable(false);
                    alert(res.data.message);
                }
            })
            .catch(() => setLoaderForTable(false));
    };

    useEffect(() => {
        getApiForEinvoiceRepo();
    }, []);

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const filteredRecords = recordForTable.filter(
        (v) =>
            !(v.upload_status === "Failed" && v.upload_fail_reason === "Latest Data Already Present")
    );




    return (
        <React.Fragment>
            <ToggleSwitch
                onChange={handleOpenAndClose}
                label="Upload E-Invoice Repository"
            />
            {openAndCloseModel ? (
                <>
                    <BoxWrapper boxTitle="Upload E-Invoice Repository">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <label className="button-primary" style={{ cursor: "pointer" }}>
                                Upload
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileUpload}
                                    hidden
                                />
                            </label>
                            <div>
                                <TextField
                                    value={fileName}
                                    variant="standard"
                                    style={{ width: "350px", paddingLeft: "10px" }}
                                    placeholder="No Data Have Uploaded"
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div style={{ width: "200px", paddingLeft: "14px", paddingBottom: '14px', paddingRight: '6px' }}> {/* Adds margin-left spacing */}
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>File Type</InputLabel>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleDropdownChange}
                                        label="Select Option"
                                    >

                                        <MenuItem value="E-invoice Portal">E-invoice Portal</MenuItem>
                                        <MenuItem value="E-invoice GST">E-invoice GST</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div>
                                <button
                                    className="button-primary"
                                    style={{ marginLeft: "10px" }}
                                    onClick={handleSubmitEvent}
                                >
                                    Submit
                                </button>

                                <button
                                    className="button-outlined-secondary"
                                    onClick={fileUploadCancelButtonHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                color: colorForErrorMessage,
                                padding: "5px",
                                fontWeight: "600",
                                visibility: errorMessageVisibilityStyle,
                                display: errorMessageDisplayStyle,
                            }}
                        >
                            {errorMessage}
                        </div>
                    </BoxWrapper>
                </>
            ) : (
                ""
            )}

            {/*..................Table.......................*/}
            <BoxWrapper
                boxTitle="E-Invoice Repository"
                // search input
                enableSearchInput={true}
                searchInputPlaceholder="Search..."
                searchInputOnchange={handleSearch}

                enableAddButton={true}
                addButtonLabel="Refresh"
                addButtonHandlerFn={getApiForEinvoiceRepo}
            >
                <div className="responsive-table">
                    <table className="custome-table">
                        <thead>
                            <tr>
                                <th>Einvoice upload file</th>
                                <th>Tax Period </th>
                                <th>Financial Year </th>
                                <th>Generation Date</th>
                                <th>Upload Date</th>
                                <th>Status</th>
                                <th>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={showSuccessOnly}
                                            onChange={() => setShowSuccessOnly(!showSuccessOnly)}
                                        />
                                        Show Success Only
                                    </label>
                                </th>
                                {/* <th>Log</th> */}
                            </tr>
                        </thead>
                        <tbody>

                            {recordForTable.map((v, index) => {
                                let statusText = v.upload_status;
                                if (v.upload_status === "Success") {
                                    statusText = "Success";
                                } else if (v.upload_status === "Initiated") {
                                    statusText = "Initiated";
                                } else if (v.upload_status === "In Progress") {
                                    statusText = "In Progress";
                                }

                                if (showSuccessOnly && v.upload_status !== "Success") {
                                    return null; // Skip rendering this row
                                }


                                return (
                                    <tr key={v.id}>
                                        <td>{v.e_invoice_upload_file_type}</td>
                                        <td>
                                            {v.tax_period}
                                        </td>
                                        <td>{v.financial_year !== "None" ? v.financial_year : ""}</td>
                                        <td>{v.generation_date}</td>
                                        <td>
                                            {v.upload_date !== "None" ? moment(v.upload_date, "YYYY-MM-DD").format("DD-MM-YYYY") : ""}
                                        </td>
                                        <td>{statusText}</td>
                                        <td>
                                            {v.upload_status ? (
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleToastifyNotification(v.upload_fail_reason, v.upload_status === "Success" ? 'success' : 'error')}
                                                >
                                                    <VisibilityOutlinedIcon
                                                        className="action-icon-button showIcon"
                                                        style={{ color: v.upload_status === "Success" ? "#2938c2" : v.upload_status === "Initiated" ? "#b4bfbf" : "red" }}
                                                    />
                                                </div>
                                            ) : (
                                                <VisibilityOutlinedIcon
                                                    className="action-icon-button showIcon"
                                                    onClick={() => alert("Data Uploaded")}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                            {loaderForTable ? (
                                <tr>
                                    <td
                                        colSpan={7}
                                        style={{
                                            color: "black",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Loading…Please wait
                                    </td>
                                </tr>
                            ) : recordForTable.length === 0 ? (
                                <tr>
                                    <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                                        No Data Found!
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                        </tbody>
                    </table>
                </div>
            </BoxWrapper>
            {responseData && (
                <div>
                    <p style={{ fontSize: '20px', color: 'green' }}>DOWNLOADED GSTR DATA <br /></p>
                    <p>{responseData}</p>
                </div>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000} // Adjust as needed
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={2}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Use the state variable to determine severity
                >
                    {responseMessage}
                </MuiAlert>
            </Snackbar>


        </React.Fragment>
    );
}
