import React from 'react';
import Appbar from '../../../../components/appBar/appBar';
import Footer from '../../../../components/footer/footer';

import GSTHold from './GSThold';
function GSTInvoicetype() {
  return (
   
    <div>
      <Appbar />
      <div style={{ marginTop: "80px", marginBottom: "60px" }}>
        <GSTHold />
      </div>
      <Footer />
    </div>
  
  );
}

export default GSTInvoicetype;
