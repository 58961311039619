import React, { useState } from "react";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import DatePicker from "../../../../components/ui/DatePicker";
import { Button } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import deignCss from "./Gstprevalidation.module.css";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import CustomCard from "../../../../components/ui/Cards";

export default function Gstprevalidation() {
  const [fromDateAndMonthAndYear, setFromDateAndMonthAndYear] = useState("");
  const [toDateAndMonthAndYear, setToDateAndMonthAndYear] = useState("");
  const [fromDateMonthYearDisplay, setFromDateMonthYearDisplay] =
    useState(null);
  const [toDateMonthYearDisplay, setToDateMonthYearDisplay] = useState(null);
  const [errorMessagesModel, setErrorMessagesModel] = useState(false);
  const [dataFromApiForCard, setDataFromApiForCard] = useState([]);
  const [dataFromApiForTable, setDataFromApiForTable] = useState([]);
  const [dataFromApiForTableSearch, setDataFromApiForTableSearch] = useState(
    []
  );
  const [triggerDateError, setTriggerDateError] = useState(false);
  const [triggerDateErrorInvalidDate, setTriggerDateErrorInvalidDate] =
    useState(false);
  const [dataForExportToExcel, setDataForExportToExcel] = useState([]);
  const [trigarloader, setTrigarloader] = useState(false);

  //........function for Open and Close Model............
  const handleOpenAndCloseModel = () => {
    setFromDateAndMonthAndYear("");
    setToDateAndMonthAndYear("");
    setFromDateMonthYearDisplay(null);
    setToDateMonthYearDisplay(null);
    setErrorMessagesModel(false);
    setTrigarloader(false);
    setTriggerDateError(false);
    setTriggerDateErrorInvalidDate(false);
  };

  //.............function for From(DatePicker)..............
  const inputHandlerFromDatePicker = (v) => {
    setTriggerDateErrorInvalidDate(false);
    setErrorMessagesModel(false);
    setTriggerDateError(false);
    setFromDateMonthYearDisplay(v);
    setFromDateAndMonthAndYear(v);
  };

  //............function for To(DatePicker)...............
  const inputHandlerToDatePicker = (v) => {
    setTriggerDateErrorInvalidDate(false);
    setErrorMessagesModel(false);
    setTriggerDateError(false);
    setToDateMonthYearDisplay(v);
    setToDateAndMonthAndYear(v);
  };

  //...............Search Bar..................
  const handleSearch = (e) => {
    let search = e.target.value;
    let SearchForTable = dataFromApiForTableSearch.filter((v) => {
      return (
        String(v.record.cust_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(v.record.cust_gstin)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(v.record.invoice_no)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(v.error).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setDataFromApiForTable(SearchForTable);
  };

  //.............................Base64File........................
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //.............................Export To Excel......................
  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Gstr1 Pre-Validation.xlsx`;
    axios
      .post(`gst/gstr1prevalidation/download`, {
        data: dataForExportToExcel,
        business_entity_id: localStorage.getItem("business_entity_id"),
        from_date: moment(fromDateAndMonthAndYear).format("DD-MM-YYYY"),
        to_date: moment(toDateAndMonthAndYear).format("DD-MM-YYYY"),
      })
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert("Unable To Download Try After Some Time");
        }
      })
      .catch((error) => {
        alert("Server Error Try After Some Time");
      });
  };

  //........Submit Handler.................
  const onSubmitHandler = () => {
    if (
      new Date(fromDateMonthYearDisplay) == "Invalid Date" ||
      new Date(toDateMonthYearDisplay) == "Invalid Date"
    ) {
      setTriggerDateErrorInvalidDate(true);
    } else if (
      new Date(fromDateMonthYearDisplay) > new Date(toDateMonthYearDisplay)
    ) {
      setTriggerDateError(true);
    } else {
      setTrigarloader(true);
      const FromMonth = moment(fromDateAndMonthAndYear).format("YYYY-MM-DD");
      const ToMonth = moment(toDateAndMonthAndYear).format("YYYY-MM-DD");
      axios
        .post(`/gst/validation`, {
          fromDate: FromMonth,
          toDate: ToMonth,
          gstin: localStorage.getItem("gst"),
        })
        .then((res) => {
          setDataFromApiForTable(
            res.data.details.filter((i) => i.status === "fail")
          );
          setDataForExportToExcel(res.data);
          setDataFromApiForTableSearch(
            res.data.details.filter((i) => i.status === "fail")
          );
          setDataFromApiForCard(res.data.summary);
          setErrorMessagesModel(true);
          setTrigarloader(false);
        })
        .catch((err) => {
          setTrigarloader(false);
          console.log(err);
        });
    }
  };
  return (
    <React.Fragment>
      {/* filter */}
      <BoxWrapper boxTitle="GSTR-1 Pre-Validation">
        <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={3}>
            <Grid item sm={5}>
              <label>
                From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                // maxDate={toDateMonthYearDisplay ?? ''}
                placeholder="From Date"
                errorMessages={["This field is required"]}
                validators={["required"]}
                value={fromDateMonthYearDisplay ?? ""}
                onChange={(v) => inputHandlerFromDatePicker(v)}
              />
            </Grid>
            <Grid item sm={5}>
              <label>
                To Date<span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
                placeholder="To Date"
                errorMessages={["This field is required"]}
                validators={["required"]}
                value={toDateMonthYearDisplay ?? ""}
                onChange={(v) => inputHandlerToDatePicker(v)}
              />
            </Grid>
            <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              <button
                className="button-primary"
                type="submit"
                style={{ width: "100%", padding: "8px" }}
              >
                Submit
              </button>
            </Grid>
            <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              <button
                type="button"
                className="button-outlined-secondary"
                style={{ width: "100%", padding: "8px" }}
                onClick={handleOpenAndCloseModel}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </ValidatorForm>
        <div className="error-message">
          {trigarloader ? (
            <div
              style={{
                fontWeight: "600",

                padding: "5px",
                fontSize: "16px",
              }}
            >
              Loading...
            </div>
          ) : (
            ""
          )}

          {errorMessagesModel && dataFromApiForCard.total_records === 0 ? (
            <div style={{ color: "red", fontWeight: "700" }}>
              No Records Present
            </div>
          ) : triggerDateError ? (
            <div style={{ color: "red", fontWeight: "700" }}>
              Date is not in correct Range
            </div>
          ) : triggerDateErrorInvalidDate ? (
            <div style={{ color: "red", fontWeight: "700" }}>Invalid Date</div>
          ) : (
            ""
          )}
        </div>
      </BoxWrapper>

      {errorMessagesModel && dataFromApiForCard.total_records >= 1 ? (
        <BoxWrapper
          boxTitle="Records"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={export_to_excel}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={handleSearch}
        >
          {/* cards */}
          <div style={{ margin: "10px 0px" }}>
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <CustomCard
                  cardTitle="Total Records"
                  cardBody={dataFromApiForCard.total_records}
                  backgroundcolor="#3b3b6b"
                />
              </Grid>
              <Grid item sm={4}>
                <CustomCard
                  cardTitle="Valid Records"
                  cardBody={dataFromApiForCard.valid_records}
                  backgroundcolor="#4B5D67"
                />
              </Grid>
              <Grid item sm={4}>
                <CustomCard
                  cardTitle="Invalid Records"
                  cardBody={dataFromApiForCard.invalid_records}
                  backgroundcolor="#d04646"
                />
              </Grid>
            </Grid>
          </div>

          <div className="responsive-table" style={{ marginTop: "20px" }}>
            <table className="custome-table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Customer Name</th>
                  <th style={{ width: "20%" }}>GSTIN</th>
                  <th style={{ width: "10%" }}>Invoice/DN/CN Number</th>
                  <th style={{ width: "10%" }}>Invoice Date</th>
                  <th style={{ width: "40%" }}>Error</th>
                </tr>
              </thead>
              <tbody>
                {dataFromApiForTable.length > 0 &&
                  dataFromApiForTable.map((v) => {
                    return (
                      <tr>
                        <td>{v.record.cust_name}</td>
                        <td>{v.record.cust_gstin}</td>
                        <td>{v.record.invoice_no}</td>
                        <td>
                          {moment(v.record.invoice_date).format("DD-MM-YYYY")}
                        </td>
                        <td style={{ color: "red" }}>
                          {Object.values(v.error).map((item, i, ar) => {
                            return (
                              <div>
                                {item}
                                {""}
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}

                {dataFromApiForTable.length === 0 && (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ color: "red", textAlign: "center" }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
