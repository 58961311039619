import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Grid, Paper, Typography, Box, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {
    LineChart, Line, BarChart, Bar, ScatterChart, Scatter, PieChart, Pie, Cell,
    XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer,
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, RadialBarChart, RadialBar,
    TreeMap, Tooltip as RechartsTooltipTreeMap, Treemap
} from 'recharts'; import BarChartIcon from '@mui/icons-material/BarChart';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CircularProgress from "@mui/material/CircularProgress";
import axios from '../../../../axios-order';
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import favicon from '../favicon.ico'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";

const colors = [
    '#339BFF', '#FF33D4', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
    '#FF5733', '#CCCCFF', '#FF6384', '#FF33A1', '#33FFF5'
];

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// const graphImageUrl = 'https://img.freepik.com/premium-vector/vector-design-graph-icon-style_822882-55106.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user';
const graphImageUrl = 'https://cdn-icons-png.freepik.com/256/16170/16170706.png?ga=GA1.1.706857113.1721211877&semt=ais_hybrid'

const tableImageUrl = 'https://img.freepik.com/premium-vector/audio-symbol-icon-flat-color-circle-style_755164-19997.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                borderRadius: '40px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                backgroundImage: `url(${graphImageUrl})`, // Use graph image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
        '&:not(.Mui-checked)': {
            color: '#fff',
            transform: 'translateX(-6px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                borderRadius: '35px',
                backgroundImage: `url(${tableImageUrl})`, // Use table image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const ItcReportContainer = () => {
    let history = useHistory();
    const location = useLocation();
    const props = location.state.items || {};

    const start_period = props.from_tax_period
    const end_period = props.to_tax_period

    const [dashboardData, setDashboardData] = useState({});
    const [tableDataTotalData, setTableTotalData] = useState([])
    const [loading, setLoading] = useState(false)

    const [graphType, setGraphType] = useState('BarChart');

    const [selectedMatchResult, setSelectedMatchResult] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [graphData, setGraphData] = useState([])

    const [dispalTable, setDisplyTable] = useState(false)

    const [tableData, setTableData] = useState([])

    const [selectedValue, setSelectedValue] = useState('taxable_value');

    const [monthBreakupType, setMonthBreakupType] = useState('Current Month Bill')

    useEffect(() => {
        setLoading(true)
        getReportDetails()
    }, [])

    const getReportDetails = () => {

        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'start_period': start_period.slice(2) + start_period.slice(0, 2),
            'end_period': end_period.slice(2) + end_period.slice(0, 2),
            'recon_id': props.recon_id,
            'report_type': props.report_type
        }
        axios.post('gstr2b_itc_report', payLoadData)
            .then((res) => {
                const graph_data = res?.data?.itc_graph_data
                const first_key = Object.keys(graph_data)[0]
                setDashboardData(graph_data)
                setSelectedMatchResult(first_key)
                filterData(graph_data[first_key]['records'], monthBreakupType)
                setTableData(graph_data[first_key]['records'])
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const filterData = (data, breakupType) => {
        const result = data.filter(item => 
          item.month_breakup === breakupType
        );

        console.log('result.............', result)
        setGraphData(result);
    };


    const exportToPDF = () => {
        const input = document.getElementById('chart-container');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.setLineWidth(1);
            const logoImg = new Image();
            logoImg.src = favicon;
            pdf.addImage(logoImg, 'PNG', pdfWidth - 30, 10, 20, 20);

            pdf.setFontSize(18);
            pdf.text('ITC AVAIL REPORT', pdfWidth / 2, 20, { align: 'center' });

            const periodText = `Period: ${monthNames[parseInt(props.from_tax_period.slice(0, 2))]}-${props.from_tax_period.slice(2)} to ${monthNames[parseInt(props.to_tax_period.slice(0, 2))]}-${props.to_tax_period.slice(2)}`;
            pdf.setFontSize(12);
            pdf.text(periodText, pdfWidth / 2, 30, { align: 'center' });

            pdf.setFontSize(10);
            pdf.text('Powered by Cappitallwant Network', pdfWidth / 2, pdfHeight + 25, { align: 'center' });

            pdf.addImage(imgData, 'PNG', 15, 40, pdfWidth - 30, pdfHeight);

            pdf.save('Itc Report.pdf');
        });
    };


    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const onChangeMatchResult = (e) => {
        setSelectedMatchResult(e.target.value)
        filterData(dashboardData[e.target.value]['records'], monthBreakupType)
    }


    const numberFormat = (value) => {
       
        if (value === "(Blank)" || value === null) {
            return "₹ 0";
          }
      
        return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumSignificantDigits: 10,
        }).format(value);
      };


    const renderGraph = () => {
        if (!graphData || graphData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }
        switch (graphType) {
            case 'LineChart':
                return (
                    <LineChart data={graphData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="tax_period" />
                        <YAxis dataKey={selectedValue} hide={true} />
                        <RechartsTooltip />
                        <Legend />
                        <Line type="monotone" dataKey={selectedValue} stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                );
            case 'BarChart':
                return (
                    <BarChart data={graphData}>
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis dataKey="tax_period" />
                        <YAxis dataKey={selectedValue} hide={true} />
                        <RechartsTooltip />
                        <Legend />
                        <Bar dataKey={selectedValue} fill="#8884d8" barSize={50}/>
                    </BarChart>
                );
            case 'BubbleChart':
                return (
                    <ScatterChart width={400} height={400}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="tax_period" type="category" />
                        <YAxis dataKey={selectedValue} type="number" hide={true} />
                        <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Legend />
                        <Scatter data={graphData} fill="#8884d8" />
                    </ScatterChart>
                );
            case 'PieChart':
                return (
                    <PieChart width={'100%'} height={'100%'}>
                        <RechartsTooltip />
                        <Legend />
                        <Pie data={graphData} dataKey={selectedValue} nameKey="tax_period" cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
                            {graphData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                );
            case 'DoughnutChart':
                return (
                    <ResponsiveContainer width="100%" height={300} id="chart-container">
                        <PieChart>
                            <Pie
                                data={graphData}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey={selectedValue}
                                nameKey="tax_period"
                            >
                                {graphData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Legend />
                            <RechartsTooltip />
                        </PieChart>
                    </ResponsiveContainer>
                );
            case 'RadarChart':
                return (
                    <RadarChart outerRadius={90} width={730} height={250} data={graphData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="tax_period" />
                        <PolarRadiusAxis />
                        <Radar name={selectedValue} dataKey={selectedValue} stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        <RechartsTooltip />
                    </RadarChart>
                );
            case 'RadialBarChart':
                return (

                    <RadialBarChart
                        width={730}
                        height={250}
                        innerRadius="10%"
                        outerRadius="80%"
                        data={graphData}
                        cx="50%"
                        cy="50%"
                        barSize={10}
                    >
                        <RadialBar
                            minAngle={15}
                            label={{ position: 'insideStart', fill: '#0000' }}
                            background
                            clockWise
                            dataKey={selectedValue}
                        >
                            {
                                graphData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))
                            }
                        </RadialBar>
                        <Legend />
                        <RechartsTooltip />
                    </RadialBarChart>
                );
            case 'TreeMap':
                return (
                    <ResponsiveContainer width="100%" height={400}>
                        <Treemap
                            width={400}
                            height={200}
                            data={graphData}
                            dataKey={selectedValue}
                            ratio={4 / 3}
                            stroke="#fff"
                            fill="#8884d8"
                        >
                            <RechartsTooltipTreeMap />
                        </Treemap>
                    </ResponsiveContainer>
                );
            default:
                return null;
        }
    };
    

    const renderTable = () => {
        return (
            <>

                <BoxWrapper boxTitle="ITC AVAIL REPORT" >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    
                                    <th>FINANCIAL YEAR</th>
                                    <th>TAX PERIOD</th>
                                    <th>MATCH RESULT</th>
                                    <th>Month BREAKUP</th>
                                    <th>TAXABLE VALUE</th>
                                    <th>IGST</th>
                                    <th>CGST</th>
                                    <th>SGST</th>
                                    <th>CESS</th>
                                    <th>TOTAL INVOICE VALUE</th>
                                    
                                </tr>
                                {tableData.map((item, index) => (
                                    <tr key={index}>
                                        
                                        <td>{item.financial_year}</td>
                                        <td>{item.tax_period}</td>
                                        <td>{item.match_result}</td>
                                        <td>{item.month_breakup}</td>
                                        <td>{numberFormat(item.taxable_value)}</td>
                                        <td>{numberFormat(item.igst)}</td>
                                        <td>{numberFormat(item.cgst)}</td>
                                        <td>{numberFormat(item.sgst)}</td>
                                        <td>{numberFormat(item.cess)}</td>
                                        <td>{numberFormat(item.total_invoice_amount)}</td>

                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            </>
        );
    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleExportExcel = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem('business_entity_id'),
            'start_period': start_period.slice(2) + start_period.slice(0, 2),
            'end_period': end_period.slice(2) + end_period.slice(0, 2),
            'recon_id': props.recon_id,
            'report_type': props.report_type
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `Itc Report - ${props.report_type.toUpperCase().replace(/_/g, ' ')}.xlsx`;

        axios.post('/exportItcReportData', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);
            }).catch((err) => {

            })
    }


    const onClickCard = (key) => {
        setDisplyTable(true)
        setTableData(dashboardData[key]['records'])
    }

    const OnchageSwitch = (e) => {
        setDisplyTable(!dispalTable)
    }

    const onChnageMonthBreakup = (value) =>{
        setMonthBreakupType(value)
        const records = dashboardData[selectedMatchResult]?.records;
        if (records) {
            filterData(records, value);
        }
    }


    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '2%' }}>
                <FormControlLabel
                    control={<MaterialUISwitch defaultChecked />}
                    onChange={OnchageSwitch}
                    label={
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {dispalTable ? "Data Table" : "Graph Report"}
                        </Typography>
                    }
                />
                <div style={{ marginLeft: '24%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>ITC AVAIL REPORT <AnalyticsIcon style={{ marginBottom: '4px', color: '#ff6361' }} /></Typography>
                    <Typography variant="button" display="block" gutterBottom style={{ textAlign: 'center', marginBottom: '1%' }}>
                        Period : <Typography style={{ color: 'green', fontWeight: 'bold', display: 'inline', fontSize: 'inherit', fontWeight: 'inherit' }} variant="h7">{`${monthNames[parseInt(props.from_tax_period.slice(0, 2))-1]}-${props.from_tax_period.slice(2)}`} to {`${monthNames[parseInt(props.to_tax_period.slice(0, 2))-1]}-${props.to_tax_period.slice(2)}`}</Typography>
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom style={{ textAlign: 'center', marginBottom: '2%' }}>
                        Report Type : <Typography style={{ color: 'green', fontWeight: 'bold', display: 'inline', fontSize: 'inherit', fontWeight: 'inherit' }} variant="h7">{props.report_type.toUpperCase().replace(/_/g, ' ')}</Typography>
                    </Typography>
                </div>
                <>
                <Button onClick={() => history.push('/itcAvailReportBooks')} variant="contained" style={{marginLeft:'20%'}} startIcon={<ArrowBackIcon />}>
                    BACK
                </Button>
                <Button onClick={handleExportExcel} variant="contained" color="primary" style={{marginLeft:'2%'}}  endIcon={<FileDownloadIcon />}>
                    Export
                </Button>
                </>
                
            </div>
            {!loading ? ((!dispalTable) ? <Grid container spacing={2}>
                {!isFullScreen && Object.keys(dashboardData).map((key, index) => (
                    <Grid onClick={() => onClickCard(key)} key={index} item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                borderTop: `5px solid ${colors[index % colors.length]}`,
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                height: '90%',
                            }}
                        >
                            <Typography textAlign='center' variant="h7">{key.replace(/_/g, ' ')}</Typography>
                            <Typography textAlign='center' variant="h6">{numberFormat(dashboardData[key]['total']) ? numberFormat(dashboardData[key]['total']) : numberFormat(dashboardData[key]['total'])}</Typography>
                        </Paper>
                    </Grid>
                ))}

                <Grid item xs={12} md={12}>
                    <Paper sx={{ p: 2, borderTop: '5px solid #36A2EB', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold', marginRight: '6%' }}>ITC AVAIL REPORT</Typography>

                            <FormControl style={{ width: '15%', marginRight: '6%' }} variant="outlined">
                                <InputLabel>MATCH RESULT</InputLabel>
                                <Select
                                    value={selectedMatchResult}
                                    onChange={(e) => onChangeMatchResult(e)}
                                    label="MATCH RESULT"
                                    fullWidth
                                    style={{ height: '48px' }}
                                >
                                {Object.keys(dashboardData).map((key, index) => (
                                        <MenuItem key={index} value={key}>
                                            {key.replace(/_/g, ' ')}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: '15%', marginRight: '6%' }} variant="outlined">
                                <InputLabel>Amount Type</InputLabel>
                                <Select
                                    value={selectedValue}
                                    onChange={(e) => setSelectedValue(e.target.value)}
                                    label="VALUE TYPE"
                                    fullWidth
                                    style={{ height: '48px' }}
                                >
                                    <MenuItem value="total_invoice_amount">TOTAL INVOICE VALUE</MenuItem>
                                    <MenuItem value="taxable_value">TAXABLE VALUE</MenuItem>
                                    <MenuItem value="igst">IGST</MenuItem>
                                    <MenuItem value="cgst">CGST</MenuItem>
                                    <MenuItem value="sgst">SGST</MenuItem>
                                    <MenuItem value="cess">CESS</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: '15%', marginRight: '6%' }} variant="outlined">
                                <InputLabel>Amount Type</InputLabel>
                                <Select
                                    value={monthBreakupType}
                                    onChange={(e) => onChnageMonthBreakup(e.target.value)}
                                    label="BREAKUP TYPE"
                                    fullWidth
                                    style={{ height: '48px' }}
                                >
                                    <MenuItem value="Current Month Bill">Current Month Bill</MenuItem>
                                    <MenuItem value="Other Than Current Month">Other Than Current Month</MenuItem>
                                    
                                </Select>
                            </FormControl>
                            <div>
                                <Tooltip title="Export to PDF">
                                    <IconButton onClick={exportToPDF}>
                                        <DownloadForOfflineIcon color='primary' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
                                    <IconButton onClick={toggleFullScreen}>
                                        {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Tooltip title="Pie Chart">
                                <IconButton
                                    style={graphType === 'PieChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large'
                                    onClick={() => setGraphType('PieChart')}
                                >
                                    <PieChartIcon style={{ color:  '#FF5733'}} />
                                </IconButton>

                            </Tooltip>
                            <Tooltip title="Bar Chart">
                                <IconButton
                                    style={graphType === 'BarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('BarChart')}>
                                    <BarChartIcon style={{ color: '#36A2EB' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Line Chart">
                                <IconButton
                                    style={graphType === 'LineChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('LineChart')}>
                                    <ShowChartIcon style={{ color: '#8884d8' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bubble Chart">
                                <IconButton
                                    style={graphType === 'BubbleChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('BubbleChart')}>
                                    <BubbleChartIcon style={{ color: '#FFCE56' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Doughnut Chart">
                                <IconButton
                                    style={graphType === 'DoughnutChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('DoughnutChart')}>
                                    <DonutLargeIcon style={{ color: '#4BC0C0' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Radial Chart">
                                <IconButton
                                    style={graphType === 'RadialBarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('RadialBarChart')}>
                                    <DonutSmallIcon style={{ color: '#FF9F40' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Tree Map">
                                <IconButton
                                    style={graphType === 'TreeMap' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('TreeMap')}>
                                    <AccountTreeIcon style={{ color: '#FF33A1' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Rader Chart">
                                <IconButton
                                    style={graphType === 'RadarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('RadarChart')}>
                                    <WaterfallChartIcon style={{ color: '#FF5733' }} />
                                </IconButton>
                            </Tooltip>



                        </Box>
                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderGraph()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

            </Grid> : renderTable())

                : <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>}
        </Container>
    )
}

export default ItcReportContainer