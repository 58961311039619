import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import axios from "../../../../axios-order";
import MenuItem from "@mui/material/MenuItem";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from '@mui/material/Chip';
import SelectField from '../../../../components/ui/basicSelectInput'
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import Tooltip from "@mui/material/Tooltip";
import { LoadingButton } from "@mui/lab";
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

const Quter = [
    { id: "quarter1", value: `Quarter 1 Apr-Jun` },
    { id: "quarter2", value: `Quarter 2 Jul-Sep` },
    { id: "quarter3", value: `Quarter 3 Oct-Dec` },
    { id: "quarter4", value: `Quarter 4 Jan-Mar` },
];

const month = [
    { id: "01", value: "January" },
    { id: "02", value: "February" },
    { id: "03", value: "March" },
    { id: "04", value: "April" },
    { id: "05", value: "May" },
    { id: "06", value: "June" },
    { id: "07", value: "July" },
    { id: "08", value: "August" },
    { id: "09", value: "September" },
    { id: "10", value: "October" },
    { id: "11", value: "November" },
    { id: "12", value: "December" },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "rgba(0,123,255)",
        },
    },
}));

export default function GSTComputationReport() {

    const [trigarLoader, setTrigarLoader] = useState(false);
    const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);
    const [tdsHistoryTable, setTdsHistoryTable] = useState(true);
    const [triggerButton, setTriggerButton] = useState(true);
    const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);
    const [tdsReconHistoryData, setTdsReconHistoryData] = useState([]);
    const [loaderForTable, setLoaderForTable] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState("");
    const [yearFromArr, setFromYearArr] = useState([]);
    const [year, setYear] = useState("");
    const [quarter, setQuarter] = useState(null);
    const [period, setPeriod] = useState(null);
    const [errorMessage, setErrMessage] = useState(null)
    const [reportGennerationLoading, setReportGenerationLoading] = useState(false)

    let history = useHistory();

    // Function to open Snackbar
    const handleSnackbarOpen = (message, severity) => {
        setSnackbarSeverity(severity);
        setResponseMessage(message);
        setSnackbarOpen(true);


        // setSnackbarOpen(false);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity(snackbarSeverity);
        setResponseMessage("");

    };

    const setFromYearValues = () => {
        let current_year = new Date().getFullYear();
        let yearArr = [];
        for (let i = current_year - 10; i <= current_year + 10; i++) {
            let startYear = i;
            let endYear = i + 1;
            let endYearShort = endYear.toString().slice(-2);
            yearArr.push({ id: i, year: `${startYear}-${endYearShort}` });
        }
        setFromYearArr(yearArr);
    };


    // .............use Effect ..........
    const getTdsPayableRecon = () => {

        setLoaderForTable(true);
        axios
            .get(`/get_gst_computation_summary/${localStorage.getItem("business_entity_id")}`)
            .then((res) => {
                setLoaderForTable(false);
                const records = res?.data?.records.map(record => ({
                    ...record,
                    excel_loading: false,
                    regenerateLoading: false
                }));
                setTdsReconHistoryData(records);

            })
            .catch(() => setLoaderForTable(false));
    };

    useEffect(() => {
        getTdsPayableRecon();
        setFromYearValues()
    }, []);


    //................Handle Close Event..............
    const handleOpenAndCloseModel = () => {
        setTriggerButton(true);
        setTriggerModelFromToDate(false);
        // setTrigarModel(false);
        setTrigarLoader(false);
        setTrigarForErrorMessage(false);
        setYear('')
        setQuarter('')
        setPeriod(null)
    };


    //.......................Submit Handler...............
    const onSubmitHandlerForData = () => {

        if (!year) {
            setTrigarForErrorMessage(true);
            setErrMessage("Financial Year is required!")
            return
        }
        setReportGenerationLoading(true)
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'financial_year': year,
            'quarter': quarter,
            'period': period,
            'org_gstin': localStorage.getItem('gst')
        }
        axios.post('/create_gst_computation_report', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    handleSnackbarOpen(res.data.message, 'success')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()
                } else {
                    handleSnackbarOpen(res.data.message, 'error')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()

                }

            })
            .catch((err) => {
                getTdsPayableRecon();
                handleOpenAndCloseModel()
                handleSnackbarOpen(err?.data?.message, 'error')
            }).finally(() => {
                setReportGenerationLoading(false)
            })



    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const updateReportGenerationStatus = (item) => {
        axios.post('/update_report_generation_status', item)
            .then((res) => {
                getTdsPayableRecon()
            }).catch((err) => {

            })
    }

    const regenerateGstComputationReport = (item) => {
        setTdsReconHistoryData(prevRecords =>
            prevRecords.map(record =>
                record.id === item.id ? { ...record, regenerateLoading: true } : record
            )
        );
        axios.post('/regenerate_gst_computation_report', { ...item, org_gstin: localStorage.getItem('gst') })
            .then((res) => {
                if (res.data.status) {
                    handleSnackbarOpen(res.data.message, 'success')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()
                } else {
                    handleSnackbarOpen(res.data.message, 'error')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()

                }
            }).catch((err) => {

            }).finally(() => {
                setTdsReconHistoryData(prevRecords =>
                    prevRecords.map(record =>
                        record.id === item.id ? { ...record, regenerateLoading: false } : record
                    )
                );
            })
    }


    const handleExportExcel = (item) => {
        setTdsReconHistoryData(prevRecords =>
            prevRecords.map(record =>
                record.id === item.id ? { ...record, excel_loading: true } : record
            )
        );
        const payLoadData = {
            'summary_id': item.id,
            'business_entity_id': localStorage.getItem("business_entity_id"),
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `GST Computation Report.xlsx`;

        axios.post('/computation_excel_report', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);
                updateReportGenerationStatus(item)
                
            }).catch((err) => {

            })
            .finally(() => {
                setTdsReconHistoryData(prevRecords =>
                    prevRecords.map(record =>
                        record.id === item.id ? { ...record, excel_loading: false } : record
                    )
                );
            })
    }


    const updatedShowDetails = (item) => {
        // fail_reason
        if (item.report_status === "Generated") {
            history.push({
                pathname: `/gst_computation_report_data/${item.id}`,
                state: { items: item }
            })

            localStorage.setItem('is_confirm', item.is_confirm)
        } else {
            handleSnackbarOpen(item.fail_reason, 'error')
        }
    }

    const handleLockToggle = (id, is_locked) => {

       
        setTdsReconHistoryData((prevItems) =>
            prevItems.map((item) =>
                item.id === id ? { ...item, is_locked: !is_locked } : item
            )
        );


        const payLoadData = {
            business_entity_id: localStorage.getItem('business_entity_id'),
            id: id,
            is_locked: !is_locked,
        };

        axios.post('/update_computation_report_lock_status', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    handleSnackbarOpen(res?.data?.message, 'success');
                }
            })
            .catch((error) => {
                console.error('Error updating lock status:', error);
            })
    };



    const tdsPayablesHistoryTableBody = () => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (loaderForTable) {
            return (
                <tr>
                    <td
                        style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                        colSpan={6}
                    >
                        Loading…Please wait
                    </td>
                </tr>
            );
        } else if (tdsReconHistoryData && tdsReconHistoryData.length !== 0) {
            return tdsReconHistoryData.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.financial_year}</td>
                        <td>{item.from_month}-{item.to_month}</td>

                        <td>{item.run_date_time}</td>
                        {/* <td>{item.report_type.toUpperCase().replace(/_/g, ' ')}</td> */}
                        <td>
                            <Chip
                                label={item.report_status}
                                color={
                                    item.report_status === "Generated" ? "success" :
                                        item.report_status === "Fail" ? "error" :
                                            "warning"
                                }
                                variant="outlined"
                            />
                        </td>
                        <td>


                            {!item.is_locked ? (
                                <Tooltip title="Click to view GST Computation Details">
                                    <VisibilityOutlinedIcon
                                        className="action-icon-button showIcon"
                                        onClick={() => updatedShowDetails(item)}
                                        style={{ marginTop: '5px' }}
                                        color="primary"
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Please unlock the GST Computation Report to view details">
                                    <VisibilityOutlinedIcon
                                        className="action-icon-button showIcon"
                                        onClick={() => handleSnackbarOpen("Please unlock the GST Computation Report to view details", "warning")}
                                        style={{ marginTop: '5px' }}
                                        color="disabled"
                                    />
                                </Tooltip>
                            )}


                            {
                                (item.report_status === "Generated") && (item.is_locked ? (
                                    <Tooltip title="Unlock">
                                        <LockRoundedIcon
                                            className="action-icon-button showIcon"
                                            style={{ marginLeft: '5px' }}
                                            color="primary"
                                            onClick={() => handleLockToggle(item.id, item.is_locked)}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Lock">
                                        <LockOpenOutlinedIcon
                                            className="action-icon-button showIcon"
                                            style={{ marginLeft: '5px' }}
                                            color="primary"
                                            onClick={() => handleLockToggle(item.id, item.is_locked)}
                                        />
                                    </Tooltip>
                                ))
                            }

                            {(item.is_exported_for_regeneration || item.is_invalid) && (!item.excel_loading ?
                                <Tooltip title="Export Excel">
                                    <img onClick={() => handleExportExcel(item)}
                                        style={{ marginLeft: '5px', height: '23px', width: '25px', cursor: 'pointer', marginTop: '4px' }}
                                        src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg"
                                    />
                                </Tooltip>
                                : <>
                                    <CircularProgress
                                        size={20}
                                        style={{ color: "green", marginLeft: '5px', marginTop: '10px' }}
                                    />
                                </>)}
                            {item.report_status === "Generated" && item.is_report_generate ? (
                                !item.is_locked ? (
                                    item.regenerateLoading ? (
                                        <CircularProgress
                                            size={20}
                                            style={{ color: "green", marginLeft: '5px', marginTop: '10px' }}
                                        />
                                    ) : (
                                        <Tooltip title="Regenerate">
                                            <ReplayCircleFilledIcon
                                                onClick={() => regenerateGstComputationReport(item)}
                                                style={{ marginLeft: '5px', marginTop: '5px', cursor: 'pointer' }}
                                                color="success"
                                            />
                                        </Tooltip>
                                    )
                                ) : (
                                    <Tooltip title="Please unlock the GST Computation Report to regenerate">
                                        <ReplayCircleFilledIcon
                                            style={{ marginLeft: '5px', marginTop: '5px', cursor: 'pointer' }}
                                            color="disabled"
                                        />
                                    </Tooltip>
                                )
                            ) : null}

                        </td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
                        No Data Found!
                    </td>
                </tr>
            );
        }
    };


    const handleTDSREconciliationButtonEvent = () => {
        setTriggerModelFromToDate(true);
    };


    return (
        <React.Fragment>

            {triggerButton && (
                <div>
                    <button
                        className="button-outlined-primary"
                        onClick={handleTDSREconciliationButtonEvent}
                    >
                        New GST Computation Report
                    </button>
                </div>
            )}

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={triggerModelFromToDate}
                onHide={handleOpenAndCloseModel}
                dialogClassName="modal-50w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>GST Computation Report</Modal.Title>
                </Modal.Header>
                <ValidatorForm
                    useRef="form"
                    onSubmit={onSubmitHandlerForData}
                    onError={(errors) => console.log(errors)}
                >
                    <Modal.Body>
                        <Grid container spacing={3}>
                            <Grid item xs={6} lg={4}>
                                <label>
                                    Financial year<sup style={{ color: "red" }}>*</sup>:
                                </label>
                                <FormControl sx={{ minWidth: "100%" }}>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={year}
                                        onChange={(e) => {
                                            setYear(e.target.value);
                                            setQuarter("");
                                            setPeriod(null);
                                            setTrigarForErrorMessage(false)
                                        }}
                                        input={<BootstrapInput />}
                                    >
                                        {yearFromArr.map((v) => {
                                            return <MenuItem value={v.year}>{v.year}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} lg={4}>
                                <label>
                                    Quarter :
                                </label>
                                <FormControl sx={{ minWidth: "100%" }}>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={quarter}
                                        // disabled={getApiDetailsForRegionName === "" ? true : false}
                                        onChange={(e) => {
                                            setQuarter(e.target.value);
                                        }}
                                        input={<BootstrapInput />}
                                    >
                                        {Quter.map((v) => {
                                            return <MenuItem value={v.id}>{v.value}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} lg={4}>
                                <label>
                                    Period :
                                </label>
                                <FormControl sx={{ minWidth: "100%" }}>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={period}
                                        disabled={quarter === "" ? true : false}
                                        onChange={(e) => {
                                            setPeriod(e.target.value);
                                        }}
                                        input={<BootstrapInput />}
                                    >
                                        {month.map((v) => {
                                            if (quarter === "quarter1") {
                                                if (v.id === "04" || v.id === "05" || v.id === "06") {
                                                    return <MenuItem value={v.value}>{v.value}</MenuItem>;
                                                }
                                            } else if (quarter === "quarter2") {
                                                if (v.id === "07" || v.id === "08" || v.id === "09")
                                                    return <MenuItem value={v.value}>{v.value}</MenuItem>;
                                            } else if (quarter === "quarter3") {
                                                if (v.id === "10" || v.id === "11" || v.id === "12") {
                                                    return <MenuItem value={v.value}>{v.value}</MenuItem>;
                                                }
                                            } else {
                                                if (v.id === "01" || v.id === "02" || v.id === "03")
                                                    return <MenuItem value={v.value}>{v.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>


                        </Grid>


                        {trigarForErrorMessage && (
                            <div style={{ color: "red", fontWeight: "650", marginTop: '20px' }}>
                                {errorMessage}
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="button-outlined-secondary"
                            type="button"
                            onClick={handleOpenAndCloseModel}
                        >
                            Cancel
                        </button>
                        <LoadingButton variant="outlined" style={{ height: '30px' }} loading={reportGennerationLoading} className="button-primary" type="submit">
                            Submit
                        </LoadingButton>

                    </Modal.Footer>
                </ValidatorForm>
            </Modal>

            {tdsHistoryTable && (
                <BoxWrapper
                    boxTitle="GST COMPUTATION REPORT"

                >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    <th>FINANCIAL YEAR</th>
                                    <th>PERIOD</th>
                                    <th>RUN DATE TIME</th>
                                    {/* <th>REPORT TYPE</th> */}
                                    <th>REPORT STATUS</th>
                                    <th>Action</th>
                                </tr>
                                {tdsPayablesHistoryTableBody()}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={4500}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>

        </React.Fragment>
    );
}
