import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Grid,
  Tab,
  MenuItem,
  Select,
  
  FormControl,
  InputLabel,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useLocation } from "react-router-dom";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "../TDSReconciliationSale/TDSRecon.css";

function E_Invoice_Details() {
  const location = useLocation();
  const [value, setValue] = useState("1");
  const [eInvoiceReconId, setEInvoiceReconId] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [matchedData, SetMatchedData] = useState(null);
  const [mismatchData, SetMismatchData] = useState(null);
  const [probableMatchData, setProbableMatchData] = useState(null);
  const [cancelledData, setCancelledData] = useState(null);
  const [missingInBooks, setMissingInBooksData] = useState(null);
  const [einvoiceBookData, setEinvoiceBookData] = useState(null);
  const [missinggstrData, setMissinggstrData] = useState(null);
  const [invoiceLevelSummary, setInvoiceLevelSummary] = useState(null);
  const [not_generated_records,setNot_generated_records] = useState(null);
  const [reconType, setReconType] = useState(null);
  const [reconData, setReconData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (location.state && location.state.e_invoice_recon_id) {
      setEInvoiceReconId(location.state.e_invoice_recon_id);
    }
  }, [location.state]);

  useEffect(() => {
    if (eInvoiceReconId) {
      setLoading(true);
      setError(null);

      axios
        .get(`/e_invoice_recon_view_summary/${eInvoiceReconId}`)
        .then((response) => {
          console.log(response.data.records);
          setSummaryData(response.data.records.dashboard_summary);
          SetMatchedData(response.data.records.matched_records);
          SetMismatchData(response.data.records.mismatch_records);
          setProbableMatchData(response.data.records.probable_match_records);
          setCancelledData(response.data.records.cancelled_records)
          setNot_generated_records(response.data.records.not_generated_records)

          if (response.data.records.missing_in_books_records) {
            setMissingInBooksData(
              response.data.records.missing_in_books_records
            );
          } else {
            setMissingInBooksData(null);
          }

          if (response.data.records.missing_in_e_invoice_records) {
            setEinvoiceBookData(
              response.data.records.missing_in_e_invoice_records
            );
          } else {
            setEinvoiceBookData(null);
          }

          if (response.data.records.missing_in_gstr1_records) {
            setMissinggstrData(response.data.records.missing_in_gstr1_records);
          } else {
            setMissinggstrData(null);
          }

          setInvoiceLevelSummary(response.data.records.invoice_level_summary);
          setReconType(response.data.records.recon_type);
          setReconData(response.data.records); 
          setLoading(false);
        })
        .catch((err) => {
          setError("Error fetching data");
          console.error(err);
          setLoading(false);
        });
    }
  }, [eInvoiceReconId]);


  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `E_invoiceRecon.xlsx`;

    if (!eInvoiceReconId) {
      alert("eInvoiceReconId is missing or invalid.");
      return;
    }

    setLoading(true); // show loader when the request is made

    axios
      .get(`/e_invoice_recon_download/${eInvoiceReconId}`)
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          console.log("Unable To Download, Try After Some Time");
        }
      })
      .catch((error) => {
        console.error("Error during file download:", error);
      })
      .finally(() => {
        setLoading(false); // hide loader after the request is complete
      });
  };


  const getLoaderStyles = () => {
    return {
      loader: {
        width: "40px",
        aspectRatio: "1.154",
        clipPath: "polygon(50% 0,100% 100%,0 100%)",
        display: "grid",
        background: "#3A89C9",
      },
      loaderBeforeAfter: {
        content: '""',
        gridArea: "1/1",
        background: "#3A89C9",
        clipPath: "inherit",
        transformOrigin: "50% 66%",
        animation: "l7-1 2s infinite",
      },
      loaderBefore: {
        background: "#9CC4E4",
        animation: "l7-2 2s infinite 1s",
      },
      keyframesL71: `@keyframes l7-1 {
        0%, 99.9% {
          transform: scale(1);
        }
        40%, 99% {
          transform: scale(0);
        }
      }`,
      keyframesL72: `@keyframes l7-2 {
        0%, 51% {
          transform: scale(1);
        }
        40% {
          transform: scale(0);
        }
      }`,
    };
  };
  const loaderStyles = getLoaderStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const numberFormat = (value) => {
    const formattedParts = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).formatToParts(value);
  
    return formattedParts
      .map((part) => {
        if (part.type === "currency") {
          return part.value + " "; 
        }
        return part.value;
      })
      .join("");
  };
  


  const renderTablebookdvsgstr = (data, title) => {
    return (
      <BoxWrapper boxTitle={title}>
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Particular</th>
                <th>Invoice Count</th>
                <th>Taxable Value</th>
                <th>Tax Value</th>
                <th>Total Value</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td style={{
                      fontWeight: ["Cancelled Invoices", "Matched Invoice", "Active Invoice","Missing Invoice"].includes(item.Particular)
                        ? "bold"
                        : "normal",
                    }}>{item.Particular}</td>
                    <td>{item["Invoice Count"]}</td>
                    <td>{numberFormat(item["Taxable Value"])}</td>
                    <td>{numberFormat(item["Tax Value"])}</td>
                    <td>{numberFormat(item["Total Value"])}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };


  const renderTablebooksvseinvoice = (data, title) => {
    return (
      <BoxWrapper boxTitle={title}>
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Particular</th>
                <th>Invoice Count</th>
                <th>Total Value</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td style={{
                      fontWeight: ["Cancelled Invoices", "Matched Invoice", "Active Invoice","Missing Invoice"].includes(item.Particular)
                        ? "bold"
                        : "normal",
                    }}>{item.Particular}</td>
                    <td>{item["Invoice Count"]}</td>
                    <td>{numberFormat(item["Total Value"])}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const renderTableeinvoicevsgstr1 = (data, title) => {
    return (
      <BoxWrapper boxTitle={title}>
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Particular</th>
                <th>Invoice Count</th>
                <th>Total Value</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td  style={{
                      fontWeight: ["Cancelled Invoices", "Matched Invoice", "Active Invoice","Missing Invoice"].includes(item.Particular)
                        ? "bold"
                        : "normal",
                    }}>{item.Particular}</td>
                    <td>{item["Invoice Count"]}</td>
                    <td>{numberFormat(item["Total Value"])}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const renderMatchedRecordsTable = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Matched Invoice">
         <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
          <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >REMARKS</th>
                      </tr>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Difference
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };
  const renderbooksvseinvoice  = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Matched Invoice">
          <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                      {/* <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td> */}
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

  const renderProbableVsEInvoice  = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Probable Match">
           <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                  
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

   const renderMismatchvsEinvoice = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="MisMatch Invoice">
           <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                      
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
   }
  const   renderMissingInBooksVsEInvoice = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Missing in E-Invoice">
           <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                     
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

  const renderMissingnotGeneratedeinvoice = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Missing in E-Invoice">
         <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>

</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                 
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                    
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }
  const renderMissingInBooks  = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Missing in Books">
         <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
             
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  
</tr>

                        
                <tr>
       
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
             
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                     
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

  const rendermissingInBookseinvoice  =(data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Missing in E-Invoice">
         <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
          <th colSpan={22}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
          <th colSpan={18}  style={{position: 'sticky', top: 0, zIndex: 10}}>REMARKS</th>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
                
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      );
  }
  const renderBooksvsgstr1 = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
        <BoxWrapper  boxTitle="Matched Invoice">
        <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
          <th colSpan={22}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
          <th colSpan={18}  style={{position: 'sticky', top: 0, zIndex: 10}}>REMARKS</th>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
                
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

    );
  };

  const renderMissinggstr1 = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="Missing in GSTR 1">
           <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                   
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

  const renderNewReconTypeSummary  = (data,title) => {
    if (!data || data.length === 0)
        return <Typography>No matched records available</Typography>;
  
      return (
        <BoxWrapper boxTitle="All Invoice Recon">
          <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
                <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  <th colSpan={6} style={{position: 'sticky', top: 0, zIndex: 10}} >Remarks</th>
</tr>

                        
                <tr>
         
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Difference</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Remarks 1</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_books}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_books)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
             
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.difference)}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.remarks_1}</td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      );
  }

  const renderMismatchRecordsTable = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="MisMatch Invoice">
        <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
          <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >REMARKS</th>
                      </tr>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Difference
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };
  const renderMismatchvsGSTR1 = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="MisMatch Invoice">
      <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
          <th colSpan={22}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
          <th colSpan={18}  style={{position: 'sticky', top: 0, zIndex: 10}}>REMARKS</th>
              <tr>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </BoxWrapper>
    );
  };
  const renderProbableMatchRecordsTable = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Probable Match">
         <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
          <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >REMARKS</th>
                      </tr>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Difference
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const renderProbableMatch = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle={title}>
       <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
          <th colSpan={22}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
          <th colSpan={18}  style={{position: 'sticky', top: 0, zIndex: 10}}>REMARKS</th>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
                
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </BoxWrapper>

    );
  };
  const renderMissingInBooksRecordsTable = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Missing in Books">
       <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
          <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >REMARKS</th>
                      </tr>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                {/* <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th> */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Difference
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    {/* <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td> */}
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const renderMissingInBooksRecordseinvoice = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Missing in E-Invoice">
        <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
          
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
         
                      </tr>
              <tr>
               
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
               
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>


                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                  
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const rendermissingInBooks = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Missing in Books">
         <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
           
          <th colSpan={24}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
        
              <tr>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
               
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>


                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                  
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                   
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

    );
  };



  
  const renderMissingInGSTR1Table = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Missing in GSTR 1">
        <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
        
                      </tr>
              <tr>
            
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                  
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const rendermissingst1 = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Missing in GSTR 1">
     <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
       
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
              
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                  
                   
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </BoxWrapper>

    );
  };

  const renderInvoiceLevelSummary = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Invoice Level Summary">
        <div className="responsive-table">
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <tr>
            <th colSpan={12} style={{position: 'sticky', top: 0, zIndex: 10}} 
            >E-INVOICE DATA</th>
          <th colSpan={14} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >GSTR1 DATA</th>
          <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} 
          >REMARKS</th>
                      </tr>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  IRN Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  E-Invoice Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTR-1 Auto-Population/Deletion Status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Error in Auto-Population/Deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Total Value
                </th>
              
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Difference
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_e_inv)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_date_e_inv}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                  
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    );
  };

  const renderCancelledData  = (data,title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Cancelled Transaction">
        <div className="responsive-table">
            <table  className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
              <thead>
              
                <tr>
             
  <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10}} >E-INVOICE DATA</th>
  
</tr>

                        
                <tr>
         
                 
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>GSTIN</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Legal Name</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Date</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Document Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Invoice Type</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Reverse Charge</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Number</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>IRN Status</th>
                  <th style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}>Total Value</th>
            
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                   
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.gstin}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.customer_name_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_number}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_date_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.document_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.supply_type_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.reverse_charge_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_number_repo}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{item.irn_status}</td>
                      <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>{numberFormat(item.total_value_repo)}</td>
                   
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ padding: "5px", textAlign: "center" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
      </BoxWrapper>
    );
  }

  const renderInvocielevelSummary = (data, title) => {
    if (!data || data.length === 0)
      return <Typography>No matched records available</Typography>;

    return (
      <BoxWrapper boxTitle="Invoice Level Summary">
       <div className="responsive-table" >
          <table className="summary-tab-single-year-table-css"  style={{ tableLayout: 'auto',width:"100%" }}>
            <thead>
            <th colSpan={16} style={{position: 'sticky', top: 0, zIndex: 10}} >BOOKS DATA</th>
          <th colSpan={22}  style={{position: 'sticky', top: 0, zIndex: 10}} >GSTR1 DATA</th>
          <th colSpan={18}  style={{position: 'sticky', top: 0, zIndex: 10}}>REMARKS</th>
              <tr>
                {/* Table headers */}
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Invoice number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Invoice date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Invoice Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               IRN Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
          Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9 }}
                >
                  GSTIN
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Legal Name
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Number
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Invoice Date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     Invoice Type
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Document Type
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                     IRN Number
                
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                   Reverse Charge
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                    E-Invoice Status
                 
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  GSTR-1 auto-population/ deletion upon cancellation date
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 GSTR-1 auto-population/ deletion status
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Error in auto-population/ deletion
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
              Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                 Total value
                </th>
               
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
             Taxable Value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  IGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
       CGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               SGST
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
               Cess
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
            Total value
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks
                </th>
                <th
                 style={{ whiteSpace: 'nowrap',position: 'sticky', top: '40px', zIndex: 9  }}
                >
                  Remarks 1
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>

<td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.supply_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_books}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_books)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_books)}
                    </td>
                   
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstin}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.customer_name_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_number}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_date_gstr_one}
                    </td>
                    
                 
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.invoice_type}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.document_type_gstr_one}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.irn_number_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.reverse_charge_gstr_one}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.e_invoice_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_cancellation_date}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.gstr1_error_status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_value_gstr_one)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_value_gstr_one)}
                    </td>
                    
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.taxable_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.igst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.sgst_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.cess_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {numberFormat(item.total_difference)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' , padding: '8px'}}>
                      {item.remarks_1}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    style={{ padding: "5px", textAlign: "center" }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

    );
  };

  const renderTabContent = () => {
    if (!summaryData) return null;

    switch (value) {
      case "1":
        return (
          <TabPanel value={value}>
          
          {reconType === "E-Invoice Vs GSTR-1" ? (
    <>
      {renderTableeinvoicevsgstr1(summaryData.invoice_data, "Invoice Summary")}
      {renderTableeinvoicevsgstr1(summaryData.credit_data, "Credit Note Summary")}
      {renderTableeinvoicevsgstr1(summaryData.debit_data, "Debit Note Summary")}
    </>
  ) : reconType === "Books Vs GSTR-1" ? (
    <>
      {renderTablebookdvsgstr(summaryData.invoice_data, "Books Invoice Summary")}
      {renderTablebookdvsgstr(summaryData.credit_data, "Books Credit Note Summary")}
      {renderTablebookdvsgstr(summaryData.debit_data, "Books Debit Note Summary")}
    </>
  ) : reconType === "Books Vs E-Invoice" ? (
    <>
      {renderTablebooksvseinvoice(summaryData.invoice_data, "E-Invoice Summary")}
      {renderTablebooksvseinvoice(summaryData.credit_data, "E-Invoice Credit Note Summary")}
      {renderTablebooksvseinvoice(summaryData.debit_data, "E-Invoice Debit Note Summary")}
    </>
  ) : (
    <Typography>No matched records available</Typography>
  )}
          </TabPanel>
        );
      case "2":
        return (
          <TabPanel value={value}>
           
            {reconType === "E-Invoice Vs GSTR-1" ? (
  renderMatchedRecordsTable(matchedData)
) : reconType === "Books Vs GSTR-1" ? (
  renderBooksvsgstr1(matchedData)
) : reconType === "Books Vs E-Invoice" ? (
  renderbooksvseinvoice(matchedData)
  
) : (
  <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );
      case "3":
        return (
          <TabPanel value={value}>
            
            {reconType === "E-Invoice Vs GSTR-1" ? (
    renderMismatchRecordsTable(mismatchData)
  ) : reconType === "Books Vs GSTR-1" ? (
    renderMismatchvsGSTR1(mismatchData)
  ) : reconType === "Books Vs E-Invoice" ? (
    renderMismatchvsEinvoice(mismatchData)
  ) : (
    <Typography>No matched records available</Typography>
  )}
          </TabPanel>
        );
      case "4":
        return (
          <TabPanel value={value}>
            
            {reconType === "E-Invoice Vs GSTR-1" ? (
    renderProbableMatchRecordsTable(probableMatchData)
) : reconType === "Books Vs GSTR-1" ? (
    renderProbableMatch(probableMatchData)
) : reconType === "Books Vs E-Invoice" ? (
    renderProbableVsEInvoice(probableMatchData)
) : (
    <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );
      case "5":
        return (
          <TabPanel value={value}>
          
            {reconType === "E-Invoice Vs GSTR-1" ? (
    renderMissingInBooksRecordsTable(missingInBooks)
) : reconType === "Books Vs GSTR-1" ? (
    rendermissingInBooks(missingInBooks)
) : reconType === "Books Vs E-Invoice" ? (
    renderMissingInBooks(missingInBooks)
) : (
    <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );


       
        case "6":
          return (
            <TabPanel value={value}>
              {reconType === "Books Vs E-Invoice" ? (
              renderMissingnotGeneratedeinvoice(not_generated_records)
              ) : reconType === "E-Invoice Vs GSTR-1" }
            </TabPanel>
          );

      case "7":
        return (
          <TabPanel value={value}>
           
            {reconType === "E-Invoice Vs GSTR-1" ? (
    renderMissingInBooksRecordseinvoice(einvoiceBookData)
) : reconType === "Books Vs GSTR-1" ? (
    rendermissingInBookseinvoice(einvoiceBookData)
) : reconType === "Books Vs E-Invoice" ? (
    renderMissingInBooksVsEInvoice(einvoiceBookData)
) : (
    <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );



      case "8":
        return (
          <TabPanel value={value}>
            
            {reconType === "E-Invoice Vs GSTR-1" ? (
    renderMissingInGSTR1Table(missinggstrData)
) : reconType === "Books Vs GSTR-1" ? (
    rendermissingst1(missinggstrData)
) : reconType === "Books Vs E-Invoice" ? (
    renderMissinggstr1(missinggstrData)
) : (
    <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );

      case "9":
        return (
          <TabPanel value={value}>
           
            {reconType === "E-Invoice Vs GSTR-1" ? (
  renderInvoiceLevelSummary(invoiceLevelSummary)
) : reconType === "Books Vs GSTR-1" ? (
  renderInvocielevelSummary(invoiceLevelSummary)
) : reconType === "Books Vs E-Invoice" ? (
  renderNewReconTypeSummary(invoiceLevelSummary)
) : (
  <Typography>No matched records available</Typography>
)}

          </TabPanel>
        );
        case "10":
          return (
            <TabPanel value={value}>
             
              {reconType === "Books Vs E-Invoice" ? (
    renderCancelledData(cancelledData)
  ):(
    <Typography>No matched records available</Typography>
  )}
  
            </TabPanel>
          );
  

      default:
        return null;
    }
  };

  return (
    <TabContext value={value}>
{reconData && (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    gap: '15px', 
    marginBottom: 2, 
    backgroundColor: '#f0f0f0', 
    padding: '16px', 
    borderRadius: '8px', 
    border: '1px solid #ddd' 
  }}>
    {/* Left section: Recon Type and Recon Date */}
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: "16px", marginRight: "8px" }}>Report Period:</p>
        <p>{reconData.recon_period}</p>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: "16px", marginRight: "8px" }}>Recon Date:</p>
        <p>{(reconData.recon_date)}</p>
      </Box>
    </Box>

    {/* Centered Export button */}
    <Grid item sm={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <p style={{ alignSelf: 'flex-start', marginLeft: '8px',fontSize:'17px' }}>{reconData.recon_type}</p>
  
    {loading ? (
        <div style={loaderStyles.loader}>
          <style>
            {loaderStyles.keyframesL71}
            {loaderStyles.keyframesL72}
          </style>
          <div style={loaderStyles.loaderBeforeAfter}></div>
          <div style={{ ...loaderStyles.loaderBeforeAfter, ...loaderStyles.loaderBefore }}></div>
        </div>
      ) : (
        <button
          className="button-outlined-primary"
          type="submit"
          style={{ width: "100%", padding: "5px"}}
          onClick={export_to_excel}
        >
          Export To Excel
        </button>
      )}
</Grid>



    {/* Right section: Threshold and Financial Year */}
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: "16px", marginRight: "8px" }}>Threshold:</p>
        <p>{reconData.threshold}</p>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ fontSize: "16px", marginRight: "8px" }}>Financial Year:</p>
        <p>{reconData.financial_year}</p>
      </Box>
    </Box>
  </Box>
)}





<Box sx={{ display: "flex", justifyContent: "center", mt: 2}}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs"  variant="scrollable"    scrollButtons="auto">
          <Tab label="Summary" value="1" sx={{ fontWeight:'bold', borderRadius: "5px",  }} />
          <Tab label="Matched Invoice" value="2" sx={{ fontWeight:'bold', color:"white", backgroundColor: "#1fc240",borderRadius: "5px", "&.Mui-selected": { color: "white",}, }}  />
          <Tab label="MisMatch Invoice" value="3" sx={{fontWeight:'bold', color:"white",backgroundColor: "#ed1a1a",borderRadius: "5px", "&.Mui-selected": { color: "white",}, }}  />
          <Tab label="Probable Match" value="4" sx={{fontWeight:'bold', color:"white",backgroundColor: "#3370b5",borderRadius: "5px", "&.Mui-selected": { color: "white",}, }} />
       
          {reconData && reconData.recon_type !== 'E-Invoice Vs GSTR-1' && (
      <Tab label="Missing in Books" value="5" sx={{fontWeight:'bold',color:"white", backgroundColor: "#5da628", borderRadius: "5px", "&.Mui-selected": { color: "white",}, }} />
    )}
          { reconData && reconData.recon_type !== 'E-Invoice Vs GSTR-1'  && reconData.recon_type !== 'Books Vs GSTR-1' &&(
            <Tab label="E-Invoice-Not Generated" value="6" sx={{fontWeight:'bold', color:"white",backgroundColor: "#4899a3",borderRadius: "5px" , "&.Mui-selected": { color: "white",},}} />
            
            )}
      
          
          
          {reconData && (reconData.recon_type !== 'Books Vs GSTR-1' && reconData.recon_type !== 'Books Vs E-Invoice') && (
      <Tab label="Missing in E-Invoice" value="7" sx={{fontWeight:'bold',color:"white", backgroundColor: "#b8b25c", borderRadius: "5px" , "&.Mui-selected": { color: "white",},}} />
    )}
 {reconData && reconData.recon_type !== 'Books Vs E-Invoice' && (
          <Tab label="Missing in GSTR1" value="8"  sx={{fontWeight:'bold', color:"white",backgroundColor: "#736c18",borderRadius: "5px", "&.Mui-selected": { color: "white",}, }} />
        )}
              {reconData && reconData.recon_type === 'Books Vs E-Invoice' && (
  <Tab label="Cancelled Transaction" value="10" sx={{fontWeight:'bold', color: "white", backgroundColor: "#80579e", borderRadius: "5px", "&.Mui-selected": { color: "white" } }} />
)}
          <Tab label="All Invoice Recon" value="9"sx={{ fontWeight:'bold', color:"white",backgroundColor: "#edb007",borderRadius: "5px", "&.Mui-selected": { color: "white",}, }}  />
    

        </Tabs>
      </Box>
      </Box>

      {renderTabContent()}
    </TabContext>
  );
}

export default E_Invoice_Details;
