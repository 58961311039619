import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./registration.css";
import CloseIcon from '@mui/icons-material/Close';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { ThemeProvider, withStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectInput from "../ui/basicSelectInput";
import Checkbox from "@material-ui/core/Checkbox";
import constitution from "../../asserts/jsonData/constitution.json";
import business_sector from "../../asserts/jsonData/business_sector.json";
import Divider from "@material-ui/core/Divider";
import axios from "../../axios-order";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = (theme) => ({
  logo: {
    maxWidth: "80px",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  label: {
    fontFamily: "Dosis",
  },
});

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cappitall Want Solutions (CWS) offers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ textDecoration: "underline" }}>
          Invoice Discounting & Invoice Collection Services-
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We are a market place for{" "}
          <span style={{ fontWeight: 600 }}>Invoice Discounting</span> and{" "}
          <span style={{ fontWeight: 600 }}>Collection service</span>s and
          assist to reach multiple service providers efficiently to ensure you
          choose the one best suited for your organization. You get to choose
          multiple service providers to help you in optimizing debtors related
          working capital management
        </p>
        <h5 style={{ textDecoration: "underline" }}>
          ARC – Accounting Reconciliation and Compliance
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We provide assistance in ready to upload{" "}
          <span style={{ fontWeight: 600 }}>Accounting entries</span> relating
          to Invoice Discounting and Collection transactions ensuring accuracy
          and efficiency in handling them.
        </p>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We track the entire receivable cycle including discounting and
          collection services if availed within our platform or outside and
          enhances the visibility of debtors related working capital management
          with access to various dashboards and reporting.
        </p>
        <p>
          We also help in{" "}
          <span style={{ fontWeight: 600 }}>Reconciliation</span> and{" "}
          <span style={{ fontWeight: 600 }}>Compliance</span> of TDS and GST
          related transactions.
        </p>
        <h6
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Want to know how? Reach our customer support!! @
          <a style={{ color: "#000" }} href="mailto:support@cappitallwant.com">
            {" "}
            support@cappitallwant.com
          </a>
        </h6>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontWeight: 600 }}>Coming Soon... </span> Onboarding
          more solution partners for Invoice discounting and collections!!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

class Rgister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      user: {
        categories: [],
        cin: "",
      },
      is_cin: false,
      showARCData: false,
      categories: [],
      loading: false,
      showPassword: false,
      showBranchFields: false,
      gstRequired: false,
      entities: [],
      isChecked: false,
      isDialogOpen: false,
      isSubmitDisabled: true, 
      agree_to_terms: false,
  
      showScrollButton: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.buttonContainerRef = React.createRef();
    this.checkboxRef = React.createRef();
  }


  handleCheckboxChange = (event) => {

    const isChecked = event.target.checked;
    console.log(isChecked ? "Checkbox checked" : "Checkbox unchecked");
    this.setState({
      isChecked,
      agree_to_terms: isChecked,
      isDialogOpen: isChecked,
      showScrollButton: true,
    }, () => {
      setTimeout(() => {
        this.handleScroll();
        this.componentDidMount();
      }, 0);
      this.componentWillUnmount();
    });
    if (!isChecked) {
      this.componentWillUnmount(); // Cleanup logic when unchecked
      console.log("Checkbox unchecked, cleanup triggered");
    }

  };
  componentWillUnmount() {
    const dialog = document.querySelector(".MuiDialog-paper");
    if (dialog) {
      dialog.removeEventListener('scroll', this.checkButtonsVisibility);  // Clean up listener
    }
  }
  

  checkButtonsVisibility = () => {
    const buttonContainer = this.buttonContainerRef.current;
  
    if (buttonContainer) {
      const rect = buttonContainer.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
  
      if (isVisible) {
        this.setState({ showScrollButton: false });
      } else {
        this.setState({ showScrollButton: true });
      }
    }
  };

  componentDidMount() {
    const checkDialog = () => {
      const dialog = document.querySelector(".MuiDialog-paper");
      if (dialog) {
        dialog.addEventListener('scroll', this.checkButtonsVisibility);  
        this.checkButtonsVisibility(); 
      } else {
        setTimeout(checkDialog, 100);
      }
    };
  
    checkDialog();
  }
  


  openDialog = () => {
    this.setState({ isDialogOpen: true});
  };


 
  closeDialog = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked ? "Checkbox checked" : "Checkbox unchecked");
    this.setState({
      isDialogOpen: false,
      isChecked: false,
      agree_to_terms: false,  
      showScrollButton: true,  
    }, () => {
      this.componentWillUnmount(); 
    });
    if (this.checkboxRef.current) {
      this.checkboxRef.current.checked = false;
    }
  };

  handleSubmit = () => {
  const { isChecked } = this.state;
  if (isChecked) {
    this.setState({
      agree_to_terms: true,
      isDialogOpen: false, 
    }, () => {
      console.log("agree_to_terms:", this.state.agree_to_terms);
    });
  } else {
    console.log("Agreement Not Accepted");
  }
};

  


  handleClickShowPassword = () => {
    if (this.state.showPassword === false) {
      this.setState({
        showPassword: true,
      });
    } else {
      this.setState({ showPassword: false });
    }
  };

  getCategories() {
    axios
      .get(`/cws_services/get_categories/`)
      .then((res) =>
        this.setState({
          categories: res.data.categories,
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getCategories();
    // custom rule will have name 'isPasswordMatch' [password validation]
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.user.password) {
        return false;
      }
      return true;
    });

    const checkDialog = () => {
      const dialog = document.querySelector(".MuiDialog-paper");
      if (dialog) {
        dialog.addEventListener('scroll', this.handleScroll);
      } else {

        setTimeout(checkDialog, 100);
      }
    };

    checkDialog();
  }

  onLoginPageHandler = () => {
    this.props.history.replace("/");
  };

  // form input change function
  handleInputChange(event) {
    const { user } = this.state;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "entity_type") {
      user[name] = String(value);

      this.setState({
        user,
        showBranchFields: value === "Branch",
        gstRequired: value === "Branch" && !user.gst
      }, () => {
        const gstinPattern = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$/;
        if (value === "Branch" &&  this.state.user.gst && gstinPattern.test(this.state.user.gst)) {
          this.fetchMainEntities();
        }
      });


    }


    if (name !== "ConfirmPassword") {
      if (name === "constitution_id") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
          is_cin: value === 3 || value === 4 ? true : false,
        });
      } else if (name === "business_sector_id") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
        });
      } else if (name === "msme") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
        });
      } else if (name == "gst" || name == "pan") {
        user[name] = String(value.toUpperCase());
        this.setState({
          user,
        });

        const gstinPattern = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$/;
        if (this.state.user.entity_type === "Branch" && this.state.user.gst && gstinPattern.test(this.state.user.gst)) {
          this.fetchMainEntities();
        }

      } else {
        console.log(name, value)
        user[name] = String(value);
        this.setState({
          user,
        });
      }
    } else {
      console.log(name, value)
      user[name] = String(value);
      this.setState({
        user,
      });
    }
  }

  // ---Service Checkbox---
  handleChangeCheckbox = (e) => {
    let user = this.state.user;
    let category = e.target.value;

    if (e.target.checked) {
      user["categories"].push(category);
      this.setState({
        user,
      });
    } else {
      const index = user["categories"].indexOf(category);
      user["categories"].splice(index, 1);
      this.setState({
        user,
      });
    }
  };

  //---On submit form function---
  onSubmitHandler = (event) => {
    this.setState({ loading: true });
    toast.configure();
    event.preventDefault();
    let categories = this.state.user["categories"];
    if (!categories.length) {
      toast.info("Please select one service.", { autoClose: 2500 });
      this.setState({ loading: false });
      return false;
    }
    const payload = {
      ...this.state.user,
      agree_to_terms: this.state.agree_to_terms,
    }
    axios
      .post("/register", payload)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ loading: false });
          toast.success(res.data.message, { autoClose: 2500, progress: 0 });
          this.props.history.replace("/login");
        } else {
          this.setState({ loading: false });
          toast.error(res.data.message, { autoClose: 2500, progress: 0 });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        toast.error(error.response.data.message, { autoClose: 2500 });
      });
  };

  //----End---
  modalHandler = () => {
    this.setState({ modalShow: true });
  };
  modalClose = () => {
    this.setState({ modalShow: false });
  };

  fetchMainEntities = () => {
    toast.configure();
    axios.get(`fetch_main_entities_by_gst/${this.state.user.gst}`)
      .then(res => {
        if (res.data.status) {
          this.setState({ entities: res.data.records });
        } else {
          this.setState({ entities: [] });
          const { user } = this.state;
          user['main_branch'] = '';
          this.setState({
            user,
          });
          if (this.state.user.entity_type == "Branch") {
            toast.error(res.data.message, { autoClose: 2500, progress: 0 });
          }
        }

      })
      .catch(error => {
        console.error('Error fetching entities:', error);
      });
  }

  handleScroll = () => {
    const dialog = document.querySelector(".MuiDialog-paper");
    if (dialog) {
      const scrollPosition = dialog.scrollTop;
      const scrollHeight = dialog.scrollHeight;
      const clientHeight = dialog.clientHeight;
  
     
      if (scrollPosition + clientHeight >= scrollHeight - 5) {
        this.setState({ showScrollButton: false,  });
      }
    
      else if (scrollPosition === 0) {
        this.setState({ showScrollButton: true });
      }
     
      else {
        this.setState({ showScrollButton: true });
      }
    }
  };
  

  render() {

    return (
      <div className="Register-section">
        <ThemeProvider>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <div class="Register-form-div">
                  <div className="Register-form-div-heading">
                    <h2>Register</h2>
                  </div>
                  <Divider />
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <div className="Register-form-div-body">
                      <div className="form-header">
                        <p>Business Entity Details</p>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Entity Name<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="entity_name"
                            value={this.state.user.entity_name ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Entity Email Id
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="contact_email_id"
                            value={this.state.user.contact_email_id ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Contact Number
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            inputProps={{ min: 0 }}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_contact_number"
                            value={this.state.user.user_contact_number ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "matchRegexp:^[0-9]{10}$"]}
                            errorMessages={[
                              "This field is required",
                              "Must be a mobile number",
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            PAN<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            name="pan"
                            value={this.state.user.pan ?? ""}
                            onChange={this.handleInputChange}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            validators={[
                              "required",
                              "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
                            ]}
                            errorMessages={[
                              "This field is required",
                              "Must be a PAN number",
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                          <label>
                            Entity Address
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="entity_address"
                            value={this.state.user.entity_address ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <label>
                            GSTIN<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            id="outlined-basic"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="gst"
                            value={this.state.user.gst ?? ""}
                            onChange={this.handleInputChange}
                            validators={[
                              "required",
                              "matchRegexp:^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$",
                            ]}
                            errorMessages={[
                              this.state.user.entity_type === "Branch" && this.state.gstRequired ? "This field is required" : null,
                              "Must be a GSTIN number",
                            ].filter(Boolean)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <label>TAN:</label>
                          <TextValidator
                            id="outlined-basic"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="tan"
                            value={this.state.user.tan ?? ""}
                            onChange={this.handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="business_sector_name"
                            SelectLabel="Business Sector"
                            name="business_sector_id"
                            value={this.state.user.business_sector_id ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={business_sector}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="constitution_name"
                            SelectLabel="Constitution"
                            name="constitution_id"
                            value={this.state.user.constitution_id ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={constitution}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="msme_reference"
                            SelectLabel="MSME"
                            name="msme_flag"
                            value={this.state.user.msme_flag ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={[
                              {
                                id: "YES",
                                msme_reference: "Yes",
                              },
                              {
                                id: "NO",
                                msme_reference: "No",
                              },
                            ]}
                          />
                        </Grid>



                        {this.state.is_cin && (
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingTop: "0px" }}
                          >
                            <label>
                              CIN<span style={{ color: "red" }}>*</span>:
                            </label>
                            <TextValidator
                              name="cin"
                              value={this.state.user.cin ?? ""}
                              onChange={this.handleInputChange}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                              validators={[
                                "required",
                                "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
                              ]}
                              errorMessages={[
                                "This field is required",
                                "Must be a CIN number",
                              ]}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="entity_reference"
                            SelectLabel="ENTITY TYPE"
                            name="entity_type"
                            value={this.state.user.entity_type ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={[
                              {
                                id: "Head Office",
                                entity_reference: "Head Office",
                              },
                              {
                                id: "Branch",
                                entity_reference: "Branch",
                              },
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                              <label>
                                Main/Branch Name<span style={{ color: "red" }}>*</span>:
                              </label>

                              <TextValidator
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                name="branch_name"
                                value={this.state.user.branch_name ?? ""}
                                onChange={this.handleInputChange}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </Grid>

                        {this.state.showBranchFields && (
                          <>
                            

                            <Grid item xs={12} sm={3} style={{ paddingTop: "0px", marginTop: '8px' }}>
                              <SelectInput
                                choice_id="business_entity_id"
                                requiredField="*"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_name="entity_name"
                                SelectLabel="Main Branch"
                                name="main_branch"
                                value={this.state.user.main_branch ?? ""}
                                textOnchangeFunction={this.handleInputChange}
                                choices={this.state.entities}
                              />
                            </Grid>
                          </>
                        )}

                        {/* .......................SERVICES (CATEGORY)................. */}
                        <div className="form-header">
                          <Divider />
                          <p>
                            services -{" "}
                            <a
                              href="https://www.cappitallwant.com/"
                              target="_blank "
                              style={{
                                color: "green",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                            >
                              Know more
                            </a>
                          </p>
                          <MyVerticallyCenteredModal
                            show={this.state.modalShow}
                            onHide={this.modalClose}
                          />
                        </div>

                        <Grid item xs={12} sm={12}>
                          <Grid
                            container
                            spacing={2}
                            style={{ padding: "0px" }}
                          >
                            {this.state.categories.map((category, i) => {
                              return (
                                <Grid item sm={4} style={{ padding: "0px" }}>
                                  <FormControlLabel
                                    disableTypography={true}
                                    style={{
                                      marginRight: "0px",
                                    }}
                                    control={
                                      <Checkbox
                                        checked={
                                          this.state.user.categories.indexOf(
                                            category
                                          ) !== -1
                                        }
                                        onChange={this.handleChangeCheckbox}
                                        color="primary"
                                        value={category}
                                      />
                                    }
                                  />
                                  <label
                                    style={{
                                      fontWeight: "450",
                                      position: "relative",
                                      top: "-2px",
                                    }}
                                  >
                                    {category}
                                  </label>
                                </Grid>
                              );
                            })}
                            {/* <Grid item sm={4} style={{ padding: "0px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(3) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="3"
                                  />
                                }
                                label="ARC (Accounting, Reconciliation & Compliance)"
                              />
                            </Grid>

                            <Grid item sm={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(1) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="1"
                                  />
                                }
                                label="Reporting(Dashboard)"
                              />
                            </Grid>
                            <Grid item sm={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(2) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="2"
                                  />
                                }
                                label="Revenue Optimization and Financing"
                              />
                            </Grid> */}
                          </Grid>
                        </Grid>
                        {/* ...................END SERVICES................ */}
                        {/* user details */}
                        <div className="form-header">
                          <Divider />
                          <p>User Details</p>
                        </div>
                        <Grid item sm={3}>
                          <label>
                            User Name<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_name"
                            value={this.state.user.user_name ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            User Email<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_email_id"
                            value={this.state.user.user_email_id ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <label>
                            Password<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            type={this.state.showPassword ? "text" : "password"}
                            fullWidth={true}
                            size="small"
                            name="password"
                            variant="outlined"
                            placeholder="eg.Cwn@123"
                            value={this.state.user.password ?? ""}
                            onChange={this.handleInputChange}
                            validators={[
                              "required",
                              "matchRegexp:^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20})$",
                            ]}
                            errorMessages={[
                              "This field is required",
                              "Password must be at least 8 characters,must contain at least one capital character,One small character,One special character(!@#$%^&*) and One number.",
                            ]}
                            // icon for show password

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityOff size="small" />
                                    ) : (
                                      <Visibility size="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Confirm Password
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            type="password"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="ConfirmPassword"
                            value={this.state.user.ConfirmPassword ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isPasswordMatch"]}
                            errorMessages={[
                              "This field is required",
                              "password mismatch",
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                    <div style={{ padding: "10px", textAlign: "center" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <input
                          type="checkbox"
                          id="agreeCheckbox"
                          onChange={this.handleCheckboxChange}
                          disabled={this.state.loading}
                          ref={this.checkboxRef}
                        />
                        <label htmlFor="agreeCheckbox" style={{ marginLeft: "5px" }} onClick={this.openDialog}>
                          I agree  the terms and conditions
                        </label>
                      </div>
                      <Button
                        size="small"
                        in={true}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={this.state.loading || !this.state.isChecked}
                        style={{
                          fontFamily: "Dosis",
                          fontSize: "16px",
                          fontWeight: "550",
                        }}
                      >
                        {this.state.loading && (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={20}
                          />
                        )}
                        {!this.state.loading && "Submit"}
                      </Button>
                      <p
                        className="LoginLink"
                        onClick={this.onLoginPageHandler}
                      >
                        If you already have an account,{" "}
                        <span className="justLogin">just login.</span>
                      </p>
                    </div>

                    {this.state.isDialogOpen && (
                      <Dialog
                        open={this.state.isDialogOpen}
                        onClose={this.closeDialog}
                        onChange={this.handleCheckboxChange}
                        fullWidth={true}
                        maxWidth="lg"
                        PaperProps={{
                          style: {
                            padding: '25px',
                            borderRadius: "6px",
                            boxShadow: "0 4px 8px rgba(239, 232, 232, 0.1)",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "0",
                            color: "#464182",
                            width: "100%",
                          }}
                        >
                          <h4
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignSelf: "center",
                              whiteSpace: "nowrap",
                              margin: "0 auto",
                              fontSize: "24px",
                              fontWeight: "600",
                              color: "#464182",
                              letterSpacing: "1px",
                            }}
                          >
                            Standard Terms of Business - Cappitall Want Solutions Private Limited
                          </h4>

                          
                              
{this.state.isDialogOpen && this.state.showScrollButton && (
  <button
    onClick={() => {
      const dialog = document.querySelector(".MuiDialog-paper");
      dialog.scrollTo({ top: dialog.scrollHeight, behavior: "smooth" });
    }}
    style={{
      position: 'fixed', 
      bottom: '32px', 
      left: '50%', 
      transform: 'translateX(-50%)',
      width: '35px',
      height: '35px',
      backgroundColor:'#545c91',
      color: 'red',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      fontSize: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
      animation: 'bounce 1.1s infinite',
    }}
  >
    <KeyboardDoubleArrowDownIcon style={{ fontSize: '24px', color: 'white' }} />
  </button>
)}

                        </div>


                        <style>
                          {`
                            @keyframes bounce {
                              0%, 100% {
                                transform: translateY(0);
                              }
                              50% {
                                transform: translateY(-10px); /* Move up slightly */
                              }
                            }
                          `}
                        </style>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }} >
                          The following are the terms and conditions applicable to the delivery of services by Cappitall Want Solutions Private Limited (‘CWS’ or ‘we’) under the Letter of Engagement. The Letter of Engagement and these Terms (hereinafter referred to as ‘the Contract’) together comprise of the entire agreement for the provision of the Services by CWS to you.
                        </p>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }} >
                          The following are the terms and conditions applicable to the delivery of services by Cappitall Want Solutions Private Limited (‘Cappitall Want’ or ‘we’) under the Letter of Engagement. The Letter of Engagement and these Terms (hereinafter referred to as ‘the Contract’) together comprise the entire agreement for the provision of the Services by Cappitall Want to you and supersede all previous letters of engagement, undertakings, agreements, and correspondence between us regarding the specific Services to be provided by us under the Letter of Engagement.
                        </p>



                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>
                          Definition
                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          <strong><span style={{ color: "#73172f" }}>Services</span></strong> mean the services to be provided by us under the Letter of Engagement.
                          <br />
                          <strong><span style={{ color: "#73172f" }}>Firm</span></strong> or we (or its derivatives) means Cappitall Want Solutions Private Limited.
                          <br />
                          <strong><span style={{ color: "#73172f" }}>You</span></strong> (or its derivatives) means the addressee (or addressees) under the Letter of Engagement.
                        </p>



                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Confidentiality</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          During the course of performance of Services, we may acquire sensitive information
                          concerning your business or affairs (hereinafter referred to as ‘Confidential Information’). We
                          shall preserve the confidentiality of Confidential Information and shall not disclose it beyond
                          those of our partners or employees / consultants who are engaged in the delivery of services
                          unless permitted by you or by this clause.
                          Confidential Information may be shared by us with our partners and employees / consultants
                          on a need- to-know basis and may be accessed by other parties who facilitate the
                          administration of our business or support our infrastructure, provided that such partners or
                          employees or third parties shall also be bound by similar confidentiality obligations. We shall
                          remain responsible for preserving confidentiality of the Confidential Information shared with
                          our partners, employees and such other parties.
                          However, this clause shall not apply where the Confidential Information properly enters the
                          public domain. It will also not prohibit the disclosure of Confidential Information by us where
                          we are required by law or a competent authority (including revenue authorities) to disclose
                          the same, however, we will provide you with a reasonable notice wherever legally possible
                          before making such disclosure. We shall not incur any responsibility or liability to you for any
                          loss or damage or any other adverse consequences that may result from, arise from, or be
                          connected with such disclosure. We may also disclose Confidential Information to our
                          professional indemnity insurers or other advisers, in which event we shall do so in confidence
                          only and shall remain responsible for any breach of such confidentiality obligations.
                          We may disclose in our external communications the fact that we have performed work for
                          you, in which event we may identify you by your name / logo and may indicate the general
                          nature or category of such work and any details properly in the public domain, without
                          breaching our confidentiality obligations
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Performance of Services</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          The Services shall be performed by us with reasonable skill and care. We have the requisite
                          knowledge, skills, expertise, technical know-how, experience, personnel and resources, for
                          executing the Services and have all the required licenses and permits to conduct our advisory
                          functions and undertake the required activities mentioned in the Engagement Letter. We
                          shall use our judgment in resolving issues which are taking more time than we would like
                          but will also document the reasons for the same.
                          We may provide you with written advice or confirm verbal advice in writing or deliver a final
                          written report to you as requested under the terms of the Letter of Engagement. Prior to this,
                          we may provide you with verbal, draft or interim advice, reports or presentations. In such
                          circumstances, our written advice or our final written deliverable will take precedence, and
                          no reliance may be placed by you on any draft or interim advice or presentation. In cases
                          where you wish to rely on verbal or draft advice, you shall inform us, upon which we shall
                          supply written confirmation of the advice concerned.
                          We shall not be required to update any advice, report or other deliverable given you in
                          respect of the Services (whether verbal or written) in respect of events or developments
                          occurring after the advice, report or deliverable has been issued in final form, provided such
                          report or deliverable has been marked as a final version in writing.
                          Any advice, report or other deliverable provided by us in respect of the Services is for your
                          benefit only and, except as may be required in accordance with law or by a competent
                          regulatory authority (in which case you shall, unless prohibited by law, inform us in advance)
                          or for your own internal purposes, it shall not be copied, referred to or disclosed, in whole or
                          in part without our prior written consent. We disclaim all responsibility or liability for any
                          costs, damages, losses, liabilities, expenses incurred by anyone as a result of the circulation,
                          publication, reproduction or use of our advice, reports or deliverables contrary to the
                          provisions of this clause. You may disclose any advice, report or deliverable provided by us
                          in respect of the Services to your legal and other professional advisers for the purpose of
                          seeking advice in relation to the Services, provided that when doing so, you inform them that:
                          - Disclosure by them (save for their own internal purposes) is not permitted without our
                          prior written consent
                          - We accept no liability or responsibility to them in connection with the Services
                        </p>


                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Ownership</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          We shall retain ownership of the copyright and all other intellectual property rights in the
                          product of the Services. We shall also retain ownership of our working papers. You shall
                          acquire ownership of any product of the Services in its tangible form on payment of our fees
                          as agreed in the Letter of Engagement.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Information and Communications</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Unless otherwise agreed in the Letter of Engagement, we will not independently verify the
                          accuracy of information and documents provided by you to us for the purpose of providing
                          the Services. We shall not be liable for any loss or damage arising from any inaccuracy or
                          other defect in any information or documents supplied by you.
                          We may communicate with you by electronic mail on the basis that you accept the inherent
                          risks therein. We may also, at your request, send documents to an electronic storage facility
                          hosted or controlled by you or at your direction, in which event, you shall be responsible for
                          the security and confidentiality at such facility.
                        </p>


                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Conflicts of Interest</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          We will apply all necessary barriers designed to facilitate the protection of each client’s
                          interests which may include, separate teams, separate control over data, operational
                          separation etc. The effective operation of such barriers shall constitute sufficient steps to
                          avoid any real risk of a breach of our duty of confidence to you.
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Force Majeure</h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Neither we nor you shall be in breach of our contractual obligations or shall incur any liability
                          to the other if we or you are unable to comply with this contract as a result of any cause
                          beyond our or your reasonable control. In the event of any such occurrence, each party shall,
                          as soon as reasonably practicable, notify the other, who shall have the option of suspending
                          or terminating the operation of this contract immediately
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Third Party Rights
                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          This contract shall not create or give rise to, nor shall it be intended to create or give rise to,
                          any third party rights. No third party shall have any right to enforce or rely on any provision
                          of this contract. The application of any legislation giving or conferring on third parties
                          contractual or other rights in connection with the contract shall be excluded.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Exclusions and Limitations of Liability
                        </h4>
                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Our liability in connection with the Services shall be limited in accordance with this clause.
                          In the particular circumstances of the Services, our liability to you in contract, tort, statute or
                          otherwise for any consequential, special, indirect, incidental, punitive or exemplary damages,
                          costs, expenses or losses (including without limitation, lost profits and opportunity costs)
                          suffered by you arising from or in connection with the Services shall be excluded.
                          Our aggregate liability to you in contract or tort or under statute or otherwise for any direct
                          loss or damage suffered by you arising from or in connection with the Services, howsoever
                          the direct loss or damage is caused, including our negligence but not our fraud or other
                          deliberate breach of duty, shall be limited to the amount of fees for the assignment for item
                          1 or one-month fees paid to us for the Services mentioned in 2.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Third Parties
                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          You agree to indemnify and hold harmless us from time to time and at all times hereafter,
                          from and against (i) all loss, damage, harm or injury suffered or incurred by us or any of us
                          and (ii) all notices, claims, demands, action, suits or proceedings given, made or initiated
                          against us on account of or arising out of any default committed by you in the performance
                          of all or any of your obligations hereunder, as also against all costs, charges and expenses
                          suffered or incurred by us on account of the aforesaid.
                          This indemnity shall not, however, be applicable to the extent that any such notices, claims,
                          demands, action, suits or proceedings are found by a competent court in its final judgement
                          to have resulted primarily from our willful default in performing the Services set out in the
                          Letter of Engagement.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Capacity
                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          You confirm that you have the necessary powers and has obtained all necessary
                          authorizations, consents and approvals to validly and lawfully enter into the Contract.
                          You agree to and accept the provisions of the Contract on your own behalf and as agent for
                          other beneficiaries (e.g. group companies, affiliates etc.) in cases where services are to be
                          rendered to them under the Letter of Engagement. You shall procure in such circumstances
                          that any such other beneficiaries shall act on the basis that they are a party to this Contract,
                          as if they had each signed a copy of the Letter of Engagement and agreed to be bound by it.
                          However, you alone shall be responsible for payment of our fees.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Termination

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Each party can terminate the Contract or suspend its operation by giving 30 days prior notice
                          in writing to the other at any time, after completion of 3 months from commencement.
                          Clauses 2, 3, 9, 10 & 12 and such other clauses of these Terms which by their very nature
                          ought to survive the expiry or any termination of the Contract shall survive such expiry or
                          termination.
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Severability, Waiver and Assignment

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Each clause or term of the Contract constitutes a separate and independent provision. If any
                          provision of the Contract is judged by any court or authority of competent jurisdiction to be
                          void or unenforceable, the remaining provisions shall continue in full force and effect. Failure
                          by you or us to exercise or enforce any rights available to you or us shall not amount to a
                          waiver of any rights available to you or us. Neither of the parties shall have the right to assign
                          the benefit (or transfer the burden) of the Contract to another party without the written
                          consent of the other party.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Law and Jurisdiction

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          The Contract shall in all respects be subject to and governed by the laws of the Republic of
                          India without application of the conflict of laws principles and all disputes arising on any
                          basis from or under the Contract shall be subject to the exclusive jurisdiction of the Bangalore
                          courts.
                        </p>


                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Acceptance of Terms and Conditions

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Every effort has been made to ensure, that the information contained on the Site is correct,
                          however Cappitall Want makes no warranty as the accuracy, comprehensiveness, or
                          correctness of any Service(s) or a Product(s) on the Site/App, and provides all Service(s) and
                          Product(s) on an "as is" basis. By using this Site/App, you ("you" or the "End User") agree to
                          these terms and conditions ("Terms and Conditions" or "T&C" or "Terms") that we have
                          provided. If you do not wish to agree to these Terms and Conditions, please refrain from
                          using this Site/App.
                          The Service(s) and information displayed on the Site/App is an "invitation to offer" to the End
                          User. Your intention to obtain the Service(s) constitutes your "offer" which shall be subject to
                          the terms and conditions as listed in this terms and conditions and separate agreement to be
                          entered into with Cappitall Want for such purpose.
                          No act or omission of Cappitall Want prior to the actual engagement through entering into
                          separate agreement for such purpose and will constitute acceptance of your offer.
                          The Site/App is freely accessible to the End User however; the End User will have to register
                          with Cappitall Want and create their individual registration account and enter into separate
                          agreement for purchase of Service(s). All registration information must be correct, complete
                          and promptly updated by the End User each time it changes. Cappitall Want reserves the
                          right to make any changes to the Terms and Conditions and/or our Privacy Policy (which is
                          incorporated herein by reference) as we deem necessary or desirable without prior
                          notification to you. We suggest to you, therefore, that you read our T&C and Privacy Policy
                          from time to time in order that you stay informed as to any such changes. If we make changes
                          to our T&C and Privacy Policy and you continue to use our Site, you are impliedly agreeing to
                          the revised T&C and Privacy Policy expressed herein.
                          Your privacy is important to us and we will protect it. We will not share your personal
                          information with anyone other than those listed in our Privacy Policy.
                          As a condition of engagement Cappitall Want may require your consent to send you
                          administrative and promotional emails / SMS/ Phone Calls. We will send you information
                          regarding your account activity and purchases, as well as updates about our Services and
                          promotional offers. You can opt-out of our promotional emails anytime by clicking the
                          unsubscribe link at the bottom of any of our email correspondences or for such purpose by
                          sending an e-mail request.
                          As part of offering the services, Cappitall Want may collaborate with third party/ies. Where
                          any specific service is intended to be availed by you which involves your interaction,
                          arrangement and/or services of third party, then you hereby consent to engaging in such
                          arrangement with such third parties solely at your own risk and with prior knowledge of the
                          same. Further, as part of availing such third-party services/arrangement with third party, your
                          information/user content may be made available to such third parties and you hereby provide
                          your consent for sharing such user content/information to such third parties.
                          By using this Site/App, you agree to the Terms and Conditions that Cappitall Want has
                          provided. If you do not wish to agree to these Terms and Conditions, please refrain from
                          using the Site. In the Site/App, Cappitall Want provides End Users with access to database
                          of lenders, borrowers and services providers in relation to various services
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Acceptable use

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          You must not use this Site/App in any way that causes, or may cause, damage to the Site/App
                          or impairment of the availability or accessibility of the Site/App; or in any way which is
                          unlawful, illegal, fraudulent, misleading, deceptive, causes annoyance or is harmful, or in
                          connection with any unlawful, illegal, fraudulent, misleading, deceptive or harmful purpose.
                          You must not use this Site/App to copy, store, host, transmit, send, use, publish or distribute
                          any material which consists of or is linked to any spyware, computer virus, Trojan horse,
                          worm, keystroke logger, rootkit or other malicious computer software.
                          You must not conduct any systematic or automated data collection activities (including
                          without limitation scraping, data mining, data extraction and data harvesting) on or in relation
                          to this Site/App without Cappitall Want’s express written consent.
                          You must not use this Site/App to transmit or send unsolicited commercial communications.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Restricted access

                        </h4>
                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Access to certain areas of this Site/App is restricted. Cappitall Want reserves the right to
                          restrict access to areas of this Site/App, or indeed the entire Site/App, at Cappitall Want’s
                          sole discretion.
                          If Cappitall Want provides you with a user ID and password to enable you to access restricted
                          areas of this Site/App or other content or services, you must ensure that the user ID and
                          password are kept confidential. You are responsible for keeping your user ID and password
                          confidential. You are responsible for all activities that occur in your account.
                          Cappitall Want may disable your user ID and password in Cappitall Want’s sole discretion
                          without any notice or explanation.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Subscription

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          The access to the Site/App is based on and subject to maintaining active subscription to the
                          Services by paying the necessary fees to Cappitall Want as indicated in the agreement
                          entered into between You and Cappitall Want for such purpose. Any restriction in access due
                          to non-subscription shall be the sole responsibility of the End User.
                        </p>


                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>User Registration

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          a. The End User, must meet the following criteria, to register:
                          i. the End User must be a valid entity and not an individual and have the following:
                          1. valid charter documents;
                          2. registered office in India;
                          3. a valid bank account;
                          4. a valid pan card; and
                          5. due authorization and capacity to enter into a contract under the Indian
                          Contract Act, 1872
                          b. Cappitall Want in its sole discretion, reserves the right to refuse registration of, or cancel
                          or restrict the usage of the Site/App for any End User.
                          c. The End User shall upload copies of documents required by Cappitall Want for the
                          purpose of creating an user account and may seek for information and documents from
                          time to time for rendering the Services.
                          d. After Cappitall Want verifies the details, Cappitall Want may display the End User details
                          on the Site/App.
                          e.  End Users are solely responsible for any activity that occurs on their user account and for
                          maintaining the confidentiality of their user account and are prohibited from:
                          i. using another End User’s account;
                          ii. impersonate another End User;
                          iii. create a log in name for any account that is offensive, vulgar or obscene or otherwise
                          unlawful.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>User content/information

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          In this T&C “your user content” means material (including without limitation text, images,
                          audio material, video material and audio-visual material) that you submit to this Site/App, for
                          purpose of availing the services offered by Cappitall Want. The user content may also include
                          your personal information and/or sensitive personal data or information as defined under the
                          Information Technology Act, 2000.
                          You grant to Cappitall Want a non-exclusive, royalty-free license to use, reproduce, adapt,
                          publish, translate and distribute your user content in any existing or future media for purpose
                          of availing the services of Cappitall Want as per the services/collaboration agreement
                          entered into between you and Cappitall Want. You also grant to Cappitall Want the right to
                          sub-license these rights, and the right to bring an action for infringement of these rights.
                          Your user content must not be illegal, offensive (including but not limited to material that is
                          sexually explicit or which promotes racism, hatred or physical harm), deceptive, misleading,
                          abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic,
                          lascivious, ethnically objectionable, disparaging, menacing, threatens the unity, integrity,
                          security and sovereignty of India or foreign relations with foreign states or contains software
                          viruses or causes annoyance. The user content must not infringe any third party's legal rights,
                          and must not be capable of giving rise to legal action whether against you or Cappitall Want
                          or a third party under any applicable law.
                          Availing of services offered by Cappitall Want through its platform is solely dependent on
                          the veracity of user content submitted by you and hence you take full responsibility to any
                          such user content and information submitted and accordingly the extent of services that can
                          be provided by Cappitall Want.
                          Cappitall Want reserves the right to edit or remove any material submitted to this Site/App,
                          or stored on Cappitall Want servers, or hosted or published upon this Site/App.
                          For the purposes of this T&C, you agree that: (a) “reasonable security practices and
                          procedures" means the Privacy Policy of Cappitall Want; and (b) accordingly, the rules of the
                          Government of India notified under section 43A of the Information Technology Act, 2000 are
                          hereby excluded
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>General Disclaimer

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Nothing on this Site/App constitutes, or is meant to constitute, advice of any kind. It is created
                          to provide information about Cappitall Want and its services. If you require advice in relation
                          to any matter on Cappitall Want services please contact us on www.cappitallwant.com
                          If you require any of the other services outside the purview of Cappitall Want services you
                          should consult an appropriate professional.
                          In this regard, is amply clarified that Cappitall Want is only a platform that along with
                          providing various solutions as part of its bouquet of Services offered to start-ups and SMEs,
                          also offers opportunity for such startups and SMEs to be connected to corporate financial
                          lending institutions such as banks & non-banking financial companies, where it matches the
                          need of borrowers and lender.
                          Further, Cappitall Want does not operate a platform through which loans are disbursed or
                          collected and does not engage in any collaboration/tie-up with any third party financial
                          institution for operating any disbursal and collection escrow accounts and in this regard
                          clarifies that Cappitall Want is not to be deemed or construed or understood to be a peerto-peer lending platform as understood under Master Directions – Non Banking Financial
                          Company – Peer to Peer Lending Platform (Reserve Bank) Directions, 2017 (“P2P
                          Directions”) issued by Reserve Bank of India (‘RBI’). None of the activities engaged in or
                          Services provided by Cappitall Want is intended to fall within the purview of peer-to-peer
                          lending activity. Amongst the bouquet of services being provided, Cappitall want also offers
                          its customers the opportunity to connect with and engage directly with financial institutions.
                          For such engagement with financial institutions by its customers, Cappitall Want may offer
                          advisory services to position the customers in a better standing in order to be eligible to avail
                          the loan from the lender and as extension to such continued eligibility, Cappitall Want may
                          send timely reports of repayment of loans or such other information to the lenders on behalf
                          of its customers and earn certain commission from the lenders and borrowers for providing
                          such advisory services.
                          Cappitall Want relies on information from its customers for preparing such reports on timely
                          basis since it does not directly manage or promote the disbursal and repayment of loans and
                          such engagement is directly between the customer and prospective lender. Hence, Cappitall
                          Want does not provide any assurance either to you (as customer) or the lender (with whom
                          you as customer have engaged for availing loan facility) on the accuracy of any such reports
                          being submitted and disclaims any liability in that regard. Any reporting services shall be
                          ancillary and such as to promote the engagement between the you and the third-party lender
                          and may not be relied upon entirely in order for you as customer and third party lender to
                          enter into any lending arrangement.
                          Cappitall Want does not provide verification services for the customer or for the lender in
                          respect of KYC norms or such other requirements as mandated by RBI or otherwise. Cappitall
                          Want only provides services for compiling information provided by its customers in order to
                          facilitate and promote eligibility of its customer for availing loans and also an opportunity for
                          lender accessing its platform to identify lending opportunities. Cappitall Want only makes
                          reasonable efforts to carry background check and verify the quality of the customers/End
                          Users indicated on its Site/App as eligible borrowers to lenders and of lenders indicated to
                          borrowers as typically interested in extending loan facility to such eligible borrowers.
                          However, the same shall not be and may not be construed by the user of the Site/App as
                          accurate validation or representation by Cappitall Want about the quality or accountability
                          of such customer/End Users.
                          Cappitall Want does not conduct KYC check and verification of borrowers or lenders and any
                          such activity is required to be undertaken directly between the lender and borrower as part
                          of separate engagement required to be entered into between such parties. Any verification
                          on the veracity of information provided by Cappitall Want on behalf of its customers,
                          including KYC check etc. shall be the sole responsibility of the lender and borrower. Further
                          the lender shall be solely responsible for arriving at decision to offer any loan facility to any
                          such customers of Cappitall Want including eligibility, KYC and such other criteria.
                          Accordingly, Cappitall Want shall not stand liable and disclaims all responsibility with
                          respect to any damage or loss of money or efforts suffered by the End User i.e. customer or
                          lender that may be relying on information/reports from Cappitall Want for entering into
                          engagement amongst themselves.
                          If this T&C conflicts with any agreement entered into by you with Cappitall Want for availing
                          the Services, then such agreement shall prevail for the purposes of availing the Services
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Disclaimer of Warranties

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          All details of the End Users of this Site/App and their financial status, included in or available
                          through the Site/App are provided for use without warranties of any kind, either express or
                          implied. Cappitall Want does not warrant that the information or other material which is
                          placed on the Site/App is correct including financial status or capability or does not have any
                          motive or intention to benefit illegally benefit through misinformation or misrepresentation –
                          whether pertaining to its Services or information pertaining to its End Users. By uploading
                          any content, representation or statement of your financial status or your proof of residence
                          you represent and warrant that you have the lawful right to reproduce and distribute such
                          content and that the content complies with all laws and is true and authentic and is not in
                          any event placed on the website to defraud other users or create a false impression in their
                          minds, whether about eligibility to avail lending facility or services of any third party with
                          which Cappitall Want collaborates. You also represent that you have not ever been accused
                          to any fraud, misrepresentation or default in repayment of any claims or monies to any third
                          party whether a financial institution or not. You also represent that there are no outstanding
                          disputes in connection with your moral turpitude or financial status. You hereby represent
                          and warrant to Cappitall Want that you are legally competent to execute, be bound by and
                          adhere to the T&C. Your use of this Site and Services of Cappitall Want is at your risk and
                          you agree that Cappitall Want shall have no responsibility for any damages suffered by you
                          in connection with utilizing the Services of Cappitall Want whether offered through this
                          Site/App or otherwise.
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Acknowledgments

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          You acknowledge that the Cappitall Want is not engaged in either grant of loan or borrowing
                          any money from any member of the Site nor is Cappitall Want directly engaged in disbursal
                          and repayment of loans between lenders and borrowers on its platforms and that Cappitall
                          Want is solely engaged in providing advisory services for benefit of its customer/End Users
                          in order to be eligible for availing loans.
                          You acknowledge that neither Cappitall Want or any of its subsidiary or affiliate has any
                          interest in either grant of loan or in borrowing money by any of the End Users other than
                          solely connected with providing its Services.
                          You acknowledge that the Cappitall Want is not at all responsible for any claim or damage
                          in case either the lender offering to grant loan has not granted loan or the End User availing
                          the loan has not repaid the loan or does not repay the loan.
                          You acknowledge that the Cappitall Want in no manner warrants or guarantees that the
                          performance of any Service will comply with or satisfy all the requirements of the End User
                          and that the Services being provided is solely on a best effort basis by Cappitall Want.
                          You acknowledge that the Cappitall Want in no manner warrants and guarantees that the
                          grantor of loan or the recipient of loan has provided all the information to each other which
                          is true and correct nor does Cappitall Want offer any assurance or guarantee on any
                          information provided whether on behalf of the lender or borrower as part of Services it
                          renders.
                          You acknowledge that it is your responsibility to verify the information prior to entering into
                          arrangement with third party and Cappitall Want is in no manner liable if the information
                          provided on this website is untrue or incorrect.
                          You acknowledge that you have taken expert advice from a legal consultant or any other
                          expert so required about your status to lend or borrow through engagement directly outside
                          of Cappitall Want’s platform i.e. Site/App.
                          You acknowledge and grant us permission to use your logo in accordance with the terms
                          specified herein. This permission includes the use, reproduction, and display of your logo for
                          the purposes of marketing, promotional, and advertising materials as outlined herein:
                          - Grant of License: You hereby grant us a non-exclusive, royalty-free, worldwide license to
                          use, reproduce, and display your logo (the "Logo") solely in connection with CWS's
                          marketing, promotional, and advertising materials, including but not limited to, websites,
                          social media platforms, presentations, and brochures.
                          - Purpose and Scope: We agree to use the Logo solely for the purpose of promoting our
                          relationship with you, including showcasing you as a client on our website and in a broad
                          range of marketing materials, including but not limited to brochures, flyers,
                          advertisements, social media posts, presentations, newsletters, email campaigns,
                          product packaging, and promotional items. We will not use the Logo in any manner that
                          may be considered defamatory, misleading, or harmful to your reputation. Furthermore,
                          the Logo will not be used in any way that suggests endorsement or affiliation beyond
                          the scope of this agreement.
                          - Provision: You shall provide the Logo in a high-resolution format to CWS at
                          support@cappitallwant.com.
                          - Ownership and Protection: You shall retain all rights, title, and interest in and to the Logo,
                          and nothing in this agreement shall be construed as granting any ownership rights to
                          CWS. We shall use the Logo in accordance with your brand guidelines and shall ensure
                          that the Logo is not altered or modified without your prior written consent.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Limitations of liability

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Accordingly, Cappitall Want will not be liable to you (whether under the law of contact, the
                          law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection
                          with, this Site/App:
                          <br />
                          ● to the extent that the Site/App is provided free-of-charge, for any direct loss;
                          <br />
                          ● where the service is not provided free of charge, to the extent of the amount paid by
                          you;
                          <br />
                          ● for any indirect, special or consequential loss; or
                          <br />
                          ● for any business losses, loss of revenue, income, profits or anticipated savings, loss
                          <br />
                          of contracts or business relationships, loss of reputation or goodwill, or loss or
                          corruption of information or data.
                          By using this Site/App, you agree that the exclusions and limitations of liability set out in this
                          T&C are reasonable.
                          If you do not think they are reasonable, you must not use this Site/App.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Indemnity

                        </h4>


                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          You hereby agree and undertake to indemnify and keep indemnified Cappitall Want against
                          any losses, damages, costs, liabilities and expenses (including without limitation legal
                          expenses and any amounts paid by Cappitall Want to a third party in settlement of a claim
                          or dispute) incurred or suffered by Cappitall Want arising out of any breach by you, or arising
                          out of any claim occurred as a result of any breach by you of any provisions of this T&C.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Breach

                        </h4>
                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Without prejudice to Cappitall Want’ other rights, if you breach any provision under this
                          terms and conditions, Cappitall Want shall be entitled to take such action as it deems
                          appropriate, including suspending your access to the Site/App, prohibiting you from
                          accessing the Site/App, blocking computers using your IP address from accessing the
                          Site/App, contacting your internet service provider to request that they block your access to
                          the Site/App and/or pursuing a legal action against you.
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Ownership

                        </h4>
                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Except for the User Content submitted by the End Users, any material, content or logos,
                          marks, software on or part of the Service and all aspects thereof, including all copyrights and
                          other intellectual property or proprietary rights therein, is owned by Cappitall Want or its
                          licensors. You acknowledge that the Site/App and any underlying technology or software on
                          the Site/App or used in connection with rendering the Services are proprietary information
                          owned or duly licensed to Cappitall Want, except where it is indicated otherwise. You are
                          prohibited to modify, reproduce, distribute, create derivative works of, publicly display or in
                          any way exploit, any of the content, software, marks, logos, and/or materials available on the
                          Site/App in whole or in part except as expressly allowed under the T&C. You have no other
                          express or implied rights to use, in any manner whatsoever, the content, software, marks,
                          logos, and/or materials available on the Site/App.
                          All trademarks, copyrights and other intellectual property rights owned by You in respect of
                          material, information etc. provided to Cappitall Want, shall continue to be owned solely by
                          You and nothing in the T&C shall be deemed to confer any rights to any other third party.
                        </p>
                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Variation

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Cappitall Want may revise the terms of this T&C from time-to-time. Revised terms will apply
                          prospectively from the date of its publication on this Site/App. Please check this page
                          regularly to ensure you are familiar with the current version.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Assignment

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          Cappitall Want may transfer, assign, sub-contract or otherwise deal with its rights and/or
                          obligations under this T&C without notifying you or obtaining your consent.
                          Being Services intended to be offered specifically to You, being the End User with which
                          Cappitall Want intends to have specific engagement and for such purpose You have availed
                          subscription to access the Site/App and avail the Services, You may not transfer, assign, subcontract your rights and/or obligations under this T&C and with respect to Services intended
                          to be availed from Cappitall Want
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Severability

                        </h4>
                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          The invalidity of any provision of this T&C shall not affect the validity of any other provision
                          of this T&C
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Entire agreement

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          This T&C along with any other agreement being entered into between You and Cappitall
                          want shall collectively constitute the entire agreement between you and Cappitall Want.
                          And in particular, in relation to your use of this Site/App, this T&C shall govern the entire
                          relation and supersedes all other previous terms and agreements in that respect.
                        </p>

                        <h4 style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          whiteSpace: "nowrap",
                          margin: "0 auto",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#464182",
                          letterSpacing: "1px",
                        }}>Law and jurisdiction

                        </h4>

                        <p style={{
                          fontFamily: "'Times New Roman', Arial, 'Google Sans', Roboto, sans-serif",
                          fontSize: "16px",
                          lineHeight: "1.8",
                          color: "#333",
                          textAlign: "justify",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "15px 25px",
                          margin: "15px 0",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          fontWeight: "400",
                          letterSpacing: "0.5px"
                        }}>
                          This T&C will be governed and construed in accordance with the laws of Republic of India,
                          and any disputes arising therefrom will be subject to the exclusive jurisdiction of the courts
                          of Bangalore
                        </p>

                        <div  ref={this.buttonContainerRef} style={{ marginTop: "20px", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                          {/* Agree Button */}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            style={{
                              width: "150px",
                              marginRight: "10px", // Space between the buttons
                            }}
                          >
                            Agree
                          </Button>

                          {/* Cancel Button */}
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={this.closeDialog}
                            style={{
                              width: "150px",
                              marginLeft: "10px", // Space between the buttons
                            }}
                          >
                            Cancel
                          </Button>
                        </div>


                      </Dialog>
                    )}

                    {this.state.isDialogOpen && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          zIndex: 999,
                        }}
                        onClick={this.closeDialog}
                      />
                    )}
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </Grid>
            <Route path={this.props.match.path + "/app/organization"} />
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(useStyles)(Rgister);
