import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Button, Typography, Paper } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import SelectField from "../../../../components/ui/basicSelectInput";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Chip from '@mui/material/Chip';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August08", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
];

function HsnSummaryReport() {
  const [triggerButton, setTriggerButton] = useState(true);
  const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);
  const [selectedFromMonth, setSelectedFromMonth] = useState('');
  const [yearFromArr, setFromYearArr] = useState([]);
  const [selectedToMonth, setSelectedToMonth] = useState('');
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedFinancialYear, setSelectedFinancialYear] = useState('');
  const [tdsReconHistoryData, setTdsReconHistoryData] = useState([]);
  const [loaderForTable, setLoaderForTable] = useState(false);

let history = useHistory();

  const handleOpenAndCloseModel = () => {
    setTriggerButton(true);
    setTriggerModelFromToDate(false);
  };

  const handleTDSREconciliationButtonEvent = () => {
    setTriggerModelFromToDate(true);
  };
  
  useEffect(() => {
    setFromYearValues();
}, []);


useEffect(() => {
 
    const fetchData = async () => {
      setLoaderForTable(true);
      const business_entity_id = localStorage.getItem('business_entity_id')
      try {
        const response = await axios.get(`/hsn_summary_get_api/${business_entity_id}`);
        setTdsReconHistoryData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoaderForTable(false);
      }
    };
    
    fetchData();
  }, []);



  const setFromYearValues = () => {
    let current_year = new Date().getFullYear();
    let yearArr = [];
    for (let i = current_year - 10; i <= current_year + 10; i++) {
        let startYear = i;
        let endYear = i + 1;
        let endYearShort = endYear.toString().slice(-2);
        yearArr.push({ id: i, year: `${startYear}-${endYearShort}` });

    }
    setFromYearArr(yearArr);
};
const onSubmitHandler = (isReport) => {
    const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `${selectedReportType} Multi Month.xlsx`;

    const payLoadData = {
        org_gstin: localStorage.getItem("gst"),
        from_month: selectedFromMonth,
        to_month: selectedToMonth,
        financial_year: selectedFinancialYear,
        // report_type: selectedReportType,
        is_report: isReport, // Pass "yes" or "no"
        business_entity_id: localStorage.getItem("business_entity_id")
    };

    axios
        .post('/hsn_summary_report', payLoadData)
        .then((res) => {
            if (!res.data.status) {
                // handleSnackbarOpen(res.data.message, 'error');
            } else if (isReport === "yes") {
                // downloadBase64File(contentType, res.data.file, filename);
            } else {
                // handleSnackbarOpen('Report submitted successfully', 'success');
      handleOpenAndCloseModel();
      fetchData();
            }
        })
        .catch((error) => console.log(error));
};

const fetchData = async () => {
  setLoaderForTable(true);
  const business_entity_id = localStorage.getItem('business_entity_id')
  try {
    const response = await axios.get(`/hsn_summary_get_api/${business_entity_id}`);
    setTdsReconHistoryData(response.data.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoaderForTable(false);
  }
};

const updatedShowDetails = (item) => {
    console.log('item..................', item)
    history.push({
        pathname: `/HsnSummaryDetails/`,
        state: { items: item }
    })
}


const tdsPayablesHistoryTableBody = () => {
    if (loaderForTable) {
        return (
            <tr>
                <td
                    style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                    colSpan={6}
                >
                    Loading…Please wait
                </td>
            </tr>
        );
    } else if (Array.isArray(tdsReconHistoryData) && tdsReconHistoryData.length !== 0) {
        return tdsReconHistoryData.map((item, i) => (
            <tr key={i}>
                <td>{item.financial_year}</td>
                <td>{item.from_month}</td>
                <td>{item.to_month}</td>
                <td>{item.run_date_time}</td>
                <td>
                    <Chip
                        label={item.report_status}
                        color={
                            item.report_status.includes("Completed") || 
                            item.report_status.includes("Generated")
                                ? "success"
                                : "warning"
                        }
                        variant="outlined"
                    />
                </td>

                <td>
                    <VisibilityOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => updatedShowDetails(item.id)}
                            style={{ marginTop: '2px' }}
                            color="primary"
                        /></td>
             
            </tr>
        ));
    } else {
        return (
            <tr>
                <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
                    No Data Found!
                </td>
            </tr>
        );
    }
};

  return (
    <React.Fragment>
      {triggerButton && (
        <div>
          <button
            className="button-outlined-primary"
            onClick={handleTDSREconciliationButtonEvent}
          >
            New HSN Summary Report
          </button>
        </div>
      )}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={triggerModelFromToDate}
        onHide={handleOpenAndCloseModel}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title> New HSN Summary Report</Modal.Title>
        </Modal.Header>
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          <ValidatorForm onSubmit={onSubmitHandler} style={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              style={{ width: "100%" }}
              alignItems="center"
            >
              <Grid item xs={3} sm={3} md={3}>
                <SelectField
                  validator={["required"]}
                  SelectLabel="Financial Year"
                  errorMsg={["This field is required"]}
                  choice_id="year"
                  choice_name="year"
                  choices={yearFromArr}
                  name="financial_year"
                  value={selectedFinancialYear}
                  textOnchangeFunction={(e) =>
                    setSelectedFinancialYear(e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3}>
                <SelectField
                  validator={["required"]}
                  SelectLabel="From Month"
                  errorMsg={["This field is required"]}
                  choice_id="label"
                  choice_name="label"
                  choices={months}
                  name="period"
                  value={selectedFromMonth}
                  textOnchangeFunction={(e) =>
                    setSelectedFromMonth(e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3}>
                <SelectField
                  SelectLabel="To Month"
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  choice_id="label"
                  choice_name="label"
                  choices={months}
                  name="period"
                  value={selectedToMonth}
                  textOnchangeFunction={(e) =>
                    setSelectedToMonth(e.target.value)
                  }
                />
              </Grid>

              {/* <Grid item xs={2} sm={2} md={2}>
                    <SelectField
                        SelectLabel="Report Type"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        choice_id="value"
                        choice_name="label"
                        choices={reportTypes}
                        name="report_type"
                        value={selectedReportType}
                        textOnchangeFunction={(e) => setSelectedReportType(e.target.value)}
                    />
                </Grid> */}

              {/* <Grid item xs={2} sm={2} md={2} mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  fullWidth
                  onClick={() => onSubmitHandler("yes")}
                  endIcon={<DownloadIcon />}
                >
                  Generate
                </Button>
              </Grid> */}

              <Grid item xs={2} sm={2} md={2} mt={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  fullWidth
                  onClick={() => onSubmitHandler("no")}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Paper>
      </Modal>
      <BoxWrapper
                    boxTitle="HSN SUMMARY REPORT"

                >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                <th>FINANCIAL YEAR</th>
                                <th> FROM PERIOD</th>
                                <th> TO PERIOD</th>
                                <th>RUN DATE TIME</th>
                                 <th>REPORT STATUS</th>
                                  <th>REPORT</th>
                                </tr>
                                {tdsPayablesHistoryTableBody()}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
    </React.Fragment>
  );
}

export default HsnSummaryReport;
