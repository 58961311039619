import React, { useState } from 'react';
import BoxWrapper from './boxWrapper';
import axios  from  '../../axios-order';
import Date from './Date';
import moment from "moment";
import ValidatedDatePicker from '../../components/ui/DatePicker';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
function EinvoicePreValidation() {
  const [fromDateAndMonthAndYear, setFromDateAndMonthAndYear] = useState("");
  const [toDateAndMonthAndYear, setToDateAndMonthAndYear] = useState("");
  const [fromDateMonthYearDisplay, setFromDateMonthYearDisplay] =
    useState(null);
    const [searchTerm, setSearchTerm] = useState('');
  const [toDateMonthYearDisplay, setToDateMonthYearDisplay] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [response,setResponse] = useState([])
  const [isSuccess, setIsSuccess] = useState(false);
  const onSubmitHandler = () => {
    if (
      new Date(fromDateMonthYearDisplay) == "Invalid Date" ||
      new Date(toDateMonthYearDisplay) == "Invalid Date"
    ) {
  
    } else if (
      new Date(fromDateMonthYearDisplay) > new Date(toDateMonthYearDisplay)
    ) {
  
    } else {

      const FromMonth = moment(fromDateAndMonthAndYear).format("YYYY-MM-DD");
      const ToMonth = moment(toDateAndMonthAndYear).format("YYYY-MM-DD");
      axios
        .post(`/einvoice/prevalidation`, {
          fromDate: FromMonth,
          toDate: ToMonth,
          business_entity_id: localStorage.getItem("business_entity_id"),
         userId: localStorage.getItem("user_id"),
        })
        .then((res) => {
          if (res.status === 201) { 
            setResponseData(res.data);
            setResponse(res.data.records.details || []);
            setIsSuccess(true); 
          }
       

        })
        .catch((err) => {
          setIsSuccess(false); 
       
        });
    }
  };

   const inputHandlerFromDatePicker = (v) => {
    setIsSuccess(false);
    setResponseData({});
    setFromDateMonthYearDisplay(v);
    setFromDateAndMonthAndYear(v);
  };

  //............function for To(DatePicker)...............
  const inputHandlerToDatePicker = (v) => {
    
    setToDateMonthYearDisplay(v);
    setToDateAndMonthAndYear(v);
    setIsSuccess(false);
  setResponseData({});
  };
   const handleOpenAndCloseModel = () =>{
  
      setFromDateMonthYearDisplay("");
      setToDateMonthYearDisplay("");
      setFromDateAndMonthAndYear("");
      setToDateAndMonthAndYear("");
      setIsSuccess(false);
      setResponseData({});
   
 
    
  }

  const cardStyle1 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#3b3b6b',
    textAlign: 'left', 
    width: '350px',
    height: '70px',
    color: 'white',
    fontSize: '20px',
    padding: '10px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', 
    alignItems: 'flex-start'
  
  };


  const cardStyle2 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#4b5d67',
    textAlign: 'left', 
    width: '350px',
    height: '70px',
    color: 'white',
    fontSize: '20px',
    padding: '10px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', 
    alignItems: 'flex-start' 
  };
  
  
  const cardStyle3 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#d04646',
    textAlign: 'left', 
    width: '350px',
    height: '70px', 
    color: 'white',
    fontSize: '20px',
    padding: '10px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', 
    alignItems: 'flex-start' 
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

   
   const filteredRecords = response.filter(record => {
    const recipientName = record.record?.recipient_name || '';
    const gstin = record.record?.gstin || '';
    const invoiceNumber = record.record?.invoice_number || '';

    return (
      recipientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      gstin.toLowerCase().includes(searchTerm.toLowerCase()) ||
      invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const handleExcel = async () => {
    const businessEntityIdRaw = localStorage.getItem('business_entity_id');
    
    if (!businessEntityIdRaw) {
      console.error('Business entity ID is missing');
      return;
    }
 
    const FromMonth = moment(fromDateAndMonthAndYear).format("YYYY-MM-DD");
    const ToMonth = moment(toDateAndMonthAndYear).format("YYYY-MM-DD");
    try {
    
      if (!responseData) {
        console.error('Response data is missing');
        return;
      }
  
      const payloadData = {
        'business_entity_id': businessEntityIdRaw,
        'data': responseData,
        'fromDate': FromMonth,  
        'toDate': ToMonth   
      };
  
     
  
   
      const response = await axios.post(`/einvoice/download_prevalidated_data`, payloadData);
  
  
  
      const base64Data = response.data.file; 
      if (base64Data) {
        const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'EinvoicePrevalidationUploadErrorLogs.xlsx';
        downloadBase64File(contentType, base64Data, filename);
      } else {
        console.error('Base64 data not found in the response');
      }
  
    } catch (error) {
     
      console.error('Error fetching Excel file:', error);
    }
  };
  
  
  
  
  
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
   <div>
    <BoxWrapper
 boxTitle="E-Invoice Pre-Validation"
    >
       <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={3}>
            <Grid item sm={5}>
              <label>
                From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <Date
                // maxDate={toDateMonthYearDisplay ?? ''}
                placeholder="From Date"
                errorMessages={["This field is required"]}
                validators={["required"]}
                value={fromDateMonthYearDisplay ?? ""}
                onChange={(v) => inputHandlerFromDatePicker(v)}
              />
            </Grid>
            <Grid item sm={5}>
              <label>
                To Date<span style={{ color: "red" }}>*</span>:
              </label>
              <Date
                minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
                placeholder="To Date"
                errorMessages={["This field is required"]}
                validators={["required"]}
                value={toDateMonthYearDisplay ?? ""}
                onChange={(v) => inputHandlerToDatePicker(v)}
              />
            </Grid>
            <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              <button
                className="button-primary"
                type="submit"
                style={{ width: "100%", padding: "8px" }}
              >
                Submit
              </button>
            </Grid>
            <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              <button
                type="button"
                className="button-outlined-secondary"
                style={{ width: "100%", padding: "8px" }}
                onClick={handleOpenAndCloseModel}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </ValidatorForm>
    
    </BoxWrapper>
     {/* Display summary in cards */}

     {isSuccess && responseData?.records?.summary && (
     <BoxWrapper
 boxTitle="Uploads"
 exportToExcelEnable={true}
 exportToExcelFn={handleExcel}
 // search input
 enableSearchInput={true}
 searchInputPlaceholder="Search..."
 searchInputOnchange={handleSearch}
    >
     {isSuccess && responseData?.records?.summary  && (
        <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
          <div style={cardStyle1}>
            <div>Total Records</div>
            <div>{responseData.records.summary.total_records}</div>
          </div>
          <div style={cardStyle2}>
            <div>Valid Records</div>
            <div>{responseData.records.summary.valid_records}</div>
          </div>
          <div style={cardStyle3}>
            <div>Invalid Records</div>
            <div>{responseData.records.summary.invalid_records}</div>
          </div>
        </div>
      )}
       
     <div className="responsive-table" style={{ marginTop: "20px", overflowX: 'auto' }}>
  <table className="custome-table" style={{ minWidth: '600px', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ width: "5%" }}>Recipient Name</th>
        <th style={{ width: "5%" }}>GSTIN</th>
        <th style={{ width: "9%" }}>Invoice Number</th>
        <th style={{ width: "20%" }}>Error</th>
      </tr>
    </thead>
    <tbody>
      {filteredRecords.map((record, index) => (
        <tr key={index}>
          <td>{record.record.legal_name}</td> 
          <td>{record.record.gstin}</td>
          <td>{record.record.invoice_no}</td> 
          <td>
            <ul style={{ paddingLeft: '0', margin: '0', listStyleType: 'none' }}>
              {record.error.map((err, idx) => (
                <li key={idx} style={{ color: 'red', display: 'block' }}>{err}</li>
              ))}
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </BoxWrapper>
      )}
   </div>
  );
}

export default EinvoicePreValidation;
