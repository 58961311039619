import React, { useState, useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Configuration from './Configuration';

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
// import './App.css'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SettingsIcon from "@mui/icons-material/Settings";
import KarzaModal from "../ui/basicModal";
import Datepicker from "../ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "../../components/ui/DatePicker";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import axios from "../../axios-order";
import Button from '@mui/material/Button';
import Groups2Icon from '@mui/icons-material/Groups2';

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;








const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(0,123,255)",
    },
  },
}));

const forHomePage = {
  background: "#ba4242",
  color: "#fff",
  position: "fixed",
  left: "32%",
  top: "0",
  right: "0",
  zIndex: "2",
  width: "68%",
};

const global = {
  background: "#ba4242",
  color: "#fff",
  position: "fixed",
  top: "0",
  right: "0",
  left: "0",
  zIndex: "2px",
};

const Appbar = (props) => {
  const { data } = props;
  const {isGroupEntityClicked, onGroupEntityClick} = props
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isKarzaModalOpen, setIsKarzaModalOpen] = useState(false);
  const [configure, setConfigure] = useState(false)
  const [subjectArea, setSubjectArea] = useState(false);
  const [KarzaUsagesData, setKarzaUsagesData] = useState({});
  const [karzaLoader, setKarzaLoader] = useState(false);

  const jsonkeyName = {
    report_type: "",
    entity_name: "",
    financial_year: "",
    as_of_date: "",
    from_date: "",
    to_date: "",
    businessEntityCode: "",
    report_typeForApi: "",
    process_typeForApi: ""
  }

  //SubjectArea 
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [triggerButtonForAsOfDate, setTriggerButtonForAsOfDate] = useState(false);
  const [subjectAreaJson, setsubjectAreaJson] = useState(jsonkeyName);
  const [reportTypeDropDown, setReportTypeDropDown] = useState({});



 
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);

  // const [selectedEntities, setSelectedEntities] = useState([]);
  // const [entityData, setEntityData] = useState([]);
  const openTrialModal = () => {
    setIsTrialModalOpen(true);
  };

  const closeTrialModal = () => {
    setIsTrialModalOpen(false);
  };


  const dateForFun = new Date();
  const date = dateForFun.getFullYear();

  const Financial = [
    `${date}-${parseInt(date) + 1}`,

    `${date - 1}-${parseInt(date - 1) + 1}`,

    `${date - 2}-${parseInt(date - 2) + 1}`,

    `${date - 3}-${parseInt(date - 3) + 1}`,

    `${date - 4}-${parseInt(date - 4) + 1}`,
  ];

  const handleFromDate = (v) => {
    setFromDate(v);
    const FromDate = new Date(v);
    const pad = "00";
    const yy = FromDate.getFullYear().toString();
    const mm = (FromDate.getMonth() + 1).toString();
    const dd = FromDate.getDate().toString();
    setsubjectAreaJson({
      ...subjectAreaJson,
      from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
    })
  }

  const handleToDate = (v) => {
    settoDate(v)
    const toDate = new Date(v);
    const pad = "00";
    const yy = toDate.getFullYear().toString();
    const mm = (toDate.getMonth() + 1).toString();
    const dd = toDate.getDate().toString();
    setsubjectAreaJson({
      ...subjectAreaJson,
      to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
    })
  }

  const handleAsofDate = () => {
    setTriggerButtonForAsOfDate(!triggerButtonForAsOfDate);
    const pad = "00";
    const yy = dateForFun.getFullYear().toString();
    const mm = (dateForFun.getMonth() + 1).toString();
    const dd = dateForFun.getDate().toString();
    setsubjectAreaJson({
      ...subjectAreaJson,
      as_of_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
    })
  };

  const handleClear = () => {
    setTriggerButtonForAsOfDate(false);
    setFromDate(null);
    settoDate(null);
    setsubjectAreaJson(jsonkeyName)
  }

  const handleChangeForReportingType = (values) => {
    let splitValue = [];
    for (let x in reportTypeDropDown) {
      if (reportTypeDropDown[x] === values) splitValue = x.split("_");
    }

    setsubjectAreaJson({
      ...subjectAreaJson,
      report_type: values,
      report_typeForApi: splitValue[1],
      process_typeForApi: splitValue[0]
    })

    splitValue = [];
  }

  const handleDownload = () => {
    let FromDateForApi = "";
    let ToDateForApi = "";

    if (subjectAreaJson.entity_name === "" || subjectAreaJson.report_type === "") {
      alert("Entity Name and Report Type can't be empty");
    } else if (subjectAreaJson.as_of_date === "" && subjectAreaJson.financial_year === "" && subjectAreaJson.from_date === "" && subjectAreaJson.to_date === "") {
      alert("Please select any one date filter");
    } else {
      if (subjectAreaJson.as_of_date !== "") {
        FromDateForApi = `2017-04-01`;
        ToDateForApi = subjectAreaJson.as_of_date;
      } else if (subjectAreaJson.financial_year !== "") {
        const splitDate = subjectAreaJson.financial_year.split("-");
        FromDateForApi = `${splitDate[0]}-04-01`;
        ToDateForApi = `${splitDate[1]}-03-31`;
      } else {
        FromDateForApi = subjectAreaJson.from_date;
        ToDateForApi = subjectAreaJson.to_date;
      }

      
      const download = document.createElement("a");
      download.target = "_blank";
      download.href = `${apiUrl}/cwsapims/api/v1/report/download?businessEntityCode=${subjectAreaJson?.businessEntityCode}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}&reportType=${subjectAreaJson?.report_typeForApi}&processType=${subjectAreaJson?.process_typeForApi}`;
      download.click();
      handleClear();
    }

    FromDateForApi = "";
    ToDateForApi = "";
  }

  useEffect(() => {
    axios.get(`${apiUrl}/cwsapims/api/v1/report/drop`)
      .then(res => setReportTypeDropDown(res.data))
      .catch(() => setReportTypeDropDown([]))

    if (triggerButtonForAsOfDate === false) {
      setsubjectAreaJson({
        ...subjectAreaJson,
        as_of_date: ""
      })
    }
  }, [triggerButtonForAsOfDate]);

  const handlelogout = () => {
    toast.configure();
    axios
      .get("/logout")
      .then((res) => {
        localStorage.clear();
        history.push("/login");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  const handleProfile = (props) => {
    history.push("/personalProfile");
  };

  const handleFaq = (props) => {
    history.push("/faq");
  };

  const menuId = "primary-search-account-menu";
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleProfile}>My Profile</MenuItem>
  //   </Menu>
  // );

  const handleHomeButton = () => {
    localStorage.removeItem("PanNumber");
    localStorage.removeItem("business_entity_id");
    localStorage.removeItem("entity_name");
    localStorage.removeItem("gst");
    localStorage.setItem("TriggerPanDashBoard", "false");
    localStorage.removeItem("Client_ID");
    localStorage.setItem("UploadTallyStatus", "false");
    localStorage.setItem("urlForTally", "");
    localStorage.removeItem("Trigger_Effitrac/Tally_Button");
    localStorage.removeItem("EmailId");
    history.push("/home");
  };

  // ......setting open menu handle......
  const handleOpenSettingMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEnviromentCreditEvalution = () => {
    history.push("/enviromentCreditEvalution");
  };

  const handleEnviromentTdsPayable = () => {
    history.push("/enviromentTdsPayable");
  };
  const gstinvoicetype = () => {
    history.push("/GSTInvoicetype");
  };
  const handleCloseSettingMenu = () => {
    setAnchorEl(null);
  };

  // karza usages event handlers
  const karzaUsagesModalOpenHandler = () => {
    setIsKarzaModalOpen(true);
    setAnchorEl(null);
  };

  //Sunject Area event handlers 
  const sunjectAreaModalOpenHandler = () => {
    setAnchorEl(null);
    setSubjectArea(true);
  }

  const subjectAreaModalCloseHandler = () => {
    setSubjectArea(false);
    setsubjectAreaJson(jsonkeyName);
    setFromDate(null);
    settoDate(null);
    setTriggerButtonForAsOfDate(false);
  }


  const karzaUsagesFormInputHandler = (date, name) => {
    if (date != "Invalid Date") {
      setKarzaUsagesData((prev) => ({
        ...prev,
        [name]: date,
      }));
    }
  };

  //excel download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const karzaUsagesFormSubmitHandler = () => {
    if (KarzaUsagesData.from > KarzaUsagesData.to) {
      toast.error(`To Date is less than From Date `, {
        autoClose: 3000,
        progress: 0,
      });
      return false;
    }
    setKarzaLoader(true);
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Karza report.xlsx`;
    axios
      .post(`karza_tan/report`, KarzaUsagesData)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
        setKarzaLoader(false);
        setIsKarzaModalOpen(false);
        setKarzaUsagesData({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const karzaUsagesModalCloseHandler = () => {
    setIsKarzaModalOpen(false);
    setKarzaUsagesData({});
  };

  return (
    <>
      <Menu
        style={{ marginTop: "35px" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseSettingMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEnviromentTdsPayable}>TDS Payable</MenuItem>
        <MenuItem onClick={handleEnviromentCreditEvalution}>
          Credit Evaluation
        </MenuItem>
        <MenuItem onClick={karzaUsagesModalOpenHandler}>Karza Usage</MenuItem>
        <MenuItem onClick={sunjectAreaModalOpenHandler}>Subject Area</MenuItem>

        <MenuItem onClick={openTrialModal}>Report Configuration</MenuItem>
        <MenuItem onClick={gstinvoicetype}>GST Invoice Type</MenuItem>
    
      </Menu>
      {/* karza modal */}

      <KarzaModal
        title="Karza Usages"
        open={isKarzaModalOpen}
        modalSize={"lg"}
        onClose={karzaUsagesModalCloseHandler}
        modalBody={
          <>
            <ValidatorForm
              useRef="form"
              onSubmit={karzaUsagesFormSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <label>
                    From Date<span style={{ color: "red" }}>*</span>:
                  </label>
                  <Datepicker
                    value={KarzaUsagesData.from ?? null}
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    onChange={(v) => karzaUsagesFormInputHandler(v, "from")}
                    maxDateForDatepicker={KarzaUsagesData.to}
                  />
                </Grid>
                <Grid item sm={6}>
                  <label>
                    To Date<span style={{ color: "red" }}>*</span>:
                  </label>
                  <Datepicker
                    value={KarzaUsagesData.to ?? null}
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    onChange={(v) => karzaUsagesFormInputHandler(v, "to")}
                    minDateForDatepicker={KarzaUsagesData.from}
                  />
                </Grid>
              </Grid>
              <hr></hr>
              <div style={{ textAlign: "right", marginTop: "1rem" }}>
                <button
                  type="submit"
                  // onClick={karzaUsagesFormSubmitHandler}
                  className="button-primary"
                  disabled={karzaLoader ? true : null}
                >
                  {karzaLoader ? "Downloading report..." : "Submit"}
                </button>
                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={karzaUsagesModalCloseHandler}
                >
                  Close
                </button>
              </div>
            </ValidatorForm>
          </>
        }
        enableFooter={false}
      />

      <KarzaModal
        title="Subject Area"
        open={subjectArea}
        modalSize={"xl"}
        onClose={subjectAreaModalCloseHandler}
        modalBody={
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <p>Entity Name and Report Type both are mandatory field:</p>
            </Grid>
            <Grid item xs={6} lg={3}>
              <label>Entity Name<sup className="redcolor">*</sup>:</label> <br />
              <Autocomplete
                id="combo-box-demo"
                value={subjectAreaJson.entity_name}
                onChange={(e, value) => {
                  if (value === null) {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      entity_name: "",
                      businessEntityCode: ""
                    })
                  } else {
                    const Dates = data?.filter(v => v.entity_name === value);
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      entity_name: Dates[0]?.entity_name,
                      businessEntityCode: Dates[0]?.business_entity_id
                    })
                  }
                }}
                options={data?.map(v => v.entity_name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    name="placeOfSupply"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <label>Report Type<sup className="redcolor">*</sup>:</label> <br />
              <Autocomplete
                id="combo-box-demo"
                value={subjectAreaJson.report_type}
                onChange={(e, value) => {
                  if (value === null) {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      report_type: "",
                      report_typeForApi: "",
                      process_typeForApi: ""
                    })
                  } else {
                    handleChangeForReportingType(value)
                  }
                }}
                options={Object.values(reportTypeDropDown)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    name="placeOfSupply"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}></Grid>

            <Grid item xs={12} lg={12}>
              <p>Please Select Any One field:</p>
            </Grid>

            <Grid item xs={6} lg={3}>
              <label>Financial Year:</label> <br />
              <Autocomplete
                id="combo-box-demo"
                value={subjectAreaJson.financial_year}
                disabled={subjectAreaJson.as_of_date === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? false : true}
                onChange={(e, value) => {
                  if (value === null) {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      financial_year: "",
                      period: "",
                      from_date: "",
                      to_date: ""
                    })
                  } else {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      financial_year: value
                    });
                  }
                }}
                options={Financial}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" placeholder={subjectAreaJson.as_of_date === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? "Select" : "Disable"} />
                )}
              />
            </Grid>
            <Grid item lg={0.5}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "35px",
                  marginRight: "2px",
                  textAlign: "center",
                }}
              >
                or
              </div>
            </Grid>
            <Grid item lg={2}>
              <label>As of Date:</label> <br />
              <button
                style={
                  triggerButtonForAsOfDate === false
                    ? {
                      width: "100%",
                      padding: "7px 15px",
                    }
                    : {
                      width: "90%",
                      background: "rgb(208, 70, 70)",
                      padding: "7px 15px",
                      color: "#fff",
                    }
                }
                className="button-outlined-primary"
                disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? false : true}
                onClick={handleAsofDate}
              >
                {subjectAreaJson.financial_year === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? triggerButtonForAsOfDate ? "selected" : "As of Date" : "disabled"}
              </button>
            </Grid>
            <Grid item lg={0.5}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "35px",
                  marginRight: "2px",
                  textAlign: "center",
                }}
              >
                or
              </div>
            </Grid>
            <Grid item lg={6}>
              <ValidatorForm
                useRef="form"
                // onSubmit={onSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                    <label>
                      From Date:
                    </label>
                    <DatePicker
                      // maxDate={toDateMonthYearDisplay ?? ''}
                      placeholder={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? "From Date" : "Disabled"}
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? false : true}
                      value={fromDate ?? ""}
                      onChange={(v) => handleFromDate(v)}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <label>
                      To Date:
                    </label>
                    <DatePicker
                      // minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
                      placeholder={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? "To Date" : "Disabled"}
                      errorMessages={["This field is required"]}
                      disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? false : true}
                      validators={["required"]}
                      value={toDate ?? ""}
                      onChange={(v) => handleToDate(v)}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
            <Grid item xs={9} lg={9}></Grid>
            <Grid item xs={2} lg={2} style={{ marginTop: "28px" }}>
              <button
                type="button"
                className="button-primary"
                style={{ width: "100%", padding: "8px" }}
                onClick={handleDownload}
              >
                Download
              </button>
            </Grid>
            <Grid item xs={1} lg={1} style={{ marginTop: "30px" }} >
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={handleClear}
              >
                Clear
              </button>
            </Grid>

          </Grid>
        }
        enableFooter={false}
      />

      {/* ---App bar--- */}
      <AppBar
        style={window.location.pathname === "/home" ? forHomePage : global}
      >
        <Toolbar>
          {/* left */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Profile">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfile}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <h5
              style={{
                margin: "0px",
                paddingLeft: "10px",
                fontFamily: "Dosis",
                fontWeight: "600",
              }}
            >
              {" "}
              {localStorage.getItem("user_name")}
            </h5>
          </div>
          {/* right */}
          <div style={{ marginLeft: "auto" }}>
            {localStorage.getItem("role") &&
              localStorage.getItem("role") === "ADMIN" && window.location.pathname === "/home" ? (
              <Tooltip title="CREATE GROUP ENTITY">
              <IconButton
                edge="end"
                color="success"
                onClick={onGroupEntityClick}
                style={{
                  marginRight: '20px',
                  borderRadius: '50%', 
                  backgroundColor: '#003366', 
                  padding: '10px', 
                  borderColor:'white'
                }}
              >
                <Groups2Icon style={{ color: 'white' }}/>
              </IconButton>
            </Tooltip>
              // Groups2Icon
            ) : (
              ""
            )}
            {localStorage.getItem("role") &&
              localStorage.getItem("role") === "ADMIN" && (
                <Tooltip title="FAQ">
                  <IconButton
                    edge="end"
                    aria-label="FAQ"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleFaq}
                    color="inherit"
                  >
                    <ContactSupportIcon />
                  </IconButton>
                </Tooltip>
              )}

            {window.location.pathname !== "/home" ? (
              <Tooltip title="Home">
                <IconButton
                  edge="end"
                  aria-label="home"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleHomeButton}
                  color="inherit"
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}

            {localStorage.getItem("role") === "ADMIN" && (
              <Tooltip title="Settings">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleOpenSettingMenu}
                  color="inherit"
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Logout">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handlelogout}
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>

      <Configuration open={isTrialModalOpen} onClose={closeTrialModal} />
      {isTrialModalOpen && <Configuration configure={isTrialModalOpen} onClose={closeTrialModal} />}
    </>
  );
};

export default Appbar;
