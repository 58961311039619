import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

const ValidatedDatePicker = ({
  error,
  helperText,
  minDateForDatepicker,
  value,
  onChange,

  ...rest
}) => {
 
  const handleDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      // Format date to YYYY-MM-DD format
      const formattedDate = date.toISOString().split('T')[0];
      onChange(formattedDate);
    } else {
      onChange(null);
    }
  };
  

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...rest}
          size="small"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          error={error}
          value={value ? new Date(value) : null}
          minDate={minDateForDatepicker}
          helperText={helperText}
          style={{ 
            background: '#fff', 
            borderRadius: '5px',
            width: '250px', // Adjust the width as needed
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

ValidatedDatePicker.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  minDateForDatepicker: PropTypes.instanceOf(Date),
  value: PropTypes.string, // Change to string to accommodate formatted date
  onChange: PropTypes.func.isRequired,
};

export default ValidatedDatePicker;
