import React, { useState, useEffect } from 'react';
import BoxWrapper from '../../../../components/ui/boxWrapper';
import axios from "../../../../axios-order"; 
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid , FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function GSThold() {
  const [thresholdData, setThresholdData] = useState(null);
  const [thresholdDataForSearch, setThresholdDataForSearch] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [dropdownValue, setDropdownValue] = useState('');
  const [invoiceType, setInvoiceType] = useState('');
  const [error, setError] = useState('');
  const [invoiceTypeError, setInvoiceTypeError] = useState('');

  // Fetch threshold data from API
  const getThresholdData = () => {
    axios
      .get("/get_invocie_type_details")
      .then((res) => {
        setThresholdData(res.data.records);
        setThresholdDataForSearch(res.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getThresholdData();
  }, []);


  const tableBodyData = () => {
    if (thresholdData) {
      return thresholdData.map((item, i) => (
        <tr key={i}>
          <td>{item.erp_name}</td>
          <td>{item.cws_name}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={9}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  
  const handleDialogOpen = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };


  const handleClear= () => {

    setInvoiceType('');  
    setDropdownValue(''); 
    setError('');  
    setInvoiceTypeError('');
  }
  const handleDialogClose = () => {
    handleClear();
    setOpenDialog(false);
  
  };


  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const handleInvoiceTypeChange = (event) => {
    setInvoiceType(event.target.value);
  };


  const handleSubmit = () => {
    setError('');
    setInvoiceTypeError('');

    // Validate the fields
    if (!invoiceType) {
      setInvoiceTypeError('Please enter Invoice Type');
    }
    if (!dropdownValue) {
      setError('Please select a Converted Invoice Type');
    }

    // If there are errors, stop the submission
    if (!invoiceType || !dropdownValue) {
      return;
    }

    const payload = {
      erp_name: invoiceType,
      cws_name: dropdownValue
    };

    const finaldata = {
      records:[payload]
  }


    axios
      .post("/save_invoice_type", finaldata)  
      .then((response) => {
        console.log("Response:", response.data);
        getThresholdData();
        handleDialogClose();
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div style={{ margin: "30px" }}>
        <BoxWrapper
          boxTitle="GST Invoice Type"
          // enableSearchInput={true}
          // searchInputPlaceholder="Search..."
          enableAddButton={true}
          addButtonLabel="Add"
          addButtonHandlerFn={() => handleDialogOpen(true)}
        >
          <div className="responsive-table-tds-threshold">
            <table className="custome-table">
              <tbody>
                <tr>
                  <th>ERP Invoice Type</th>
                  <th>GST Invoice Type</th>
                </tr>
                {tableBodyData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      </div>
      
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '50%',  
            maxWidth: 'none',  
          },
        }}
      >
        <DialogTitle sx={{ padding: '8px 16px' }}>Add GST Invoice Type</DialogTitle>
        <DialogContent sx={{ padding: '8px 16px', minHeight: '100px' }}>
          <Grid container spacing={2}>
            <Grid item lg={6}  sx={{ marginBottom: '16px' }}>
              <TextField
                label="ERP Invoice Type"
                name="erp_name"
                value={invoiceType}
                onChange={handleInvoiceTypeChange}
                fullWidth
                margin="normal"
                sx={{ width: '100%' }}
             
                helperText={invoiceTypeError} 
                FormHelperTextProps={{
                    sx: {
                      color: 'red',  
                    },
                  }}
              />
            </Grid>
            <Grid item lg={6}> 
              <FormControl fullWidth margin="normal" sx={{ width: '100%' }}>
                <InputLabel id="dropdown-label">GST Invoice Type</InputLabel>
                <Select
                  labelId="dropdown-label"
                  value={dropdownValue}
                  onChange={handleDropdownChange}
                  label="Converted Invoice Type"
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="Regular B2B">Regular B2B</MenuItem>
                  <MenuItem value="SEZ">SEZ</MenuItem>
                  <MenuItem value="Deemed Exp">Deemed Exp</MenuItem>
                  <MenuItem value="Intra-State supplies attracting">Intra-State supplies attracting</MenuItem>
                  <MenuItem value="Export">Export</MenuItem>
                  <MenuItem value="Unregistered">Unregistered</MenuItem>
                </Select>
                {error && (
                <FormHelperText sx={{ color: 'red' }}>
                  {error}
                </FormHelperText>
              )}
                
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '8px 16px' }}>
         
          <div className="mt-20">
        <button className="button-primary"   onClick={handleSubmit} type="submit">
          Submit
        </button>
        <button
          type="button"
          className="button-outlined-secondary"
  
        onClick={handleDialogClose} 
        >
          cancel
        </button>
      </div>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
}

export default GSThold;
