import React, { useState, useEffect } from "react";
import BoxWrapper from "../../components/ui/boxWrapper";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import viewImage from "./view.png";
import ToggleSwitch from "../../components/ui/ToggleSwitch/ToggleSwitch";
import { Snackbar, Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import ValidatedDatePicker from "./DatePicker";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import axios from "../../axios-order";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Visibility } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@material-ui/core";
import NewProduct from "./NewProduct";
import { Input } from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TextField,
  Dialog,
  TablePagination,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Tax from "./excel/TaxCreditDebit.xlsx";


function TaxInvoice() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCombinationFields, setShowCombinationFields] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [openDialogs, setOpenDialogs] = useState(false);
  const [openDialogbill, setopenDialogbill] = useState(false);
  const [openDialogcomb, setopenDialogcomb] = useState(false);
  const [showBillToShipToFields, setShowBillToShipToFields] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState({ open: false, record: null });
  const [dialogOpens, setDialogOpens] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [additionalValue, setAdditionalValue] = React.useState("");
  const businessEntityId = localStorage.getItem("business_entity_id");
  const [products, setProducts] = useState([]);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [file, setFile] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);
    const [freeze,setFreeze] = useState(false)
  const [gstinOptions, setGstinOptions] = useState([]);
  const [selectedGstin, setSelectedGstin] = useState("");
  const [recipientData, setRecipientData] = useState([]);
  const [filteredGSTINs, setFilteredGSTINs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); 
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [responseData, setResponseData] = useState(null);
  const [page, setPage] = useState(0);
  const [pendingDeleteId, setPendingDeleteId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [editingIndex, setEditingIndex] = useState(null);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [Taxinvoice, setTaxinvoice] = useState(false);
  const [ReciepentMaster, setReciepentMaster] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [formValues, setFormValues] = useState({
    category: "",
    reverSeCharger: "",
    gstin: "",
    legalName: "",
    pan: "",
    documentType: "",
    documentNumber: "",
    documentDate: "",
    igstOnIntra: "",
    transactionType: "",
    address1: "",
    address2: "",
    location: "",
    pincode: "",
    phoneNo: "",
    state: "",
    email: "",
    placeOfSupply: "",
    dispatchlegalName: "",
    dispatchlocation: "",
    dispatchpincode: "",
    dispatchaddress1: "",
    dispatchaddress2: "",
    dispatchstate: "",
    shipgstin: "",
    shiplegalName: "",
    shipaddress1: "",
    shipaddress2: "",
    shiplocation: "",
    shippincode: "",
    shipstate: "",
  });

  

  const [editedRowId, setEditedRowId] = useState(null);
  const [highlightedCell, setHighlightedCell] = useState({
    rowId: null,
    cellKey: null,
  });
  const [filteredLegalNames, setFilteredLegalNames] = useState([]);

  const [errors, setErrors] = useState({
    category: "",
    legalName: "",
    gstin: "",
    address1: "",
    documentType: "",
    location: "",
    state: "",
    documentNumber: "",
    pincode: "",
    documentDate: "",
    placeOfSupply: "",
  });


  useEffect(() => {
    const isFormValid =
      formValues.category &&
      formValues.legalName &&
      formValues.gstin &&
      formValues.address1 &&
      formValues.documentType &&
      formValues.location &&
      formValues.state &&
      formValues.documentNumber &&
      /^\d{6}$/.test(formValues.pincode) &&
      formValues.documentDate &&
      formValues.placeOfSupply;

    setIsButtonEnabled(isFormValid);
  }, [formValues]);
  useEffect(() => {
  
  }, [products]);

  useEffect(() => {

    localStorage.setItem('reverSeCharger', formValues.reverSeCharger);
    localStorage.setItem('igstOnIntra', formValues.igstOnIntra);
  }, [formValues.reverSeCharger, formValues.igstOnIntra]);



  const handleInputChange = (event) => {
    const { name, value } = event.target;


    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === "gstin") {
      setShowDropdown(true);
    }
    if (name === "placeOfSupply") {
      localStorage.setItem("placeOfSupply", value); 
    }
    if (name === "transactionType") {
      setShowAdditionalFields(value === "Bill From - Dispatch From");
      setShowBillToShipToFields(value === "Bill To - Ship To");
      setShowCombinationFields(value === "Combination 2 & 3");

      setOpenDialogs(value === "Bill From - Dispatch From");
      setopenDialogbill(value === "Bill To - Ship To");
      setopenDialogcomb(value === "Combination 2 & 3");
    }

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleEditCli = (product, index) => {
    setAdditionalValue("");
    setSelectedProduct(product); 
    setEditingIndex(product.id); 
    setIsEditing(true); 
    setOpenDialog(true); 
  };
  const handleViewCli = (product, index) => {
    setAdditionalValue("Some value");
    setSelectedProduct(product); 
    setEditingIndex(product.id); 
    setIsEditing(false); 
    setOpenDialog(true); 
  };


  const handleEditClick = async (record) => {
    handleToggle({ target: { checked: true } });
    window.scrollTo({
      top: window.pageYOffset - 890,
      behavior: "smooth",
    });
    setEditedRowId(record.id);
    try {
      const apiUrl = `einvoice/get_tax_cn_dn_master/${record.id}`;
      const response = await axios.get(apiUrl);

      if (
        response.data &&
        response.data.records &&
        response.data.records.length > 0
      ) {
        const recordData = response.data.records; // Access the first record
     
        // Check if tax_line_items exists and set it in products
        setProducts(recordData[0].tax_line_items);
      }
      //     // Set other form values based on the selected record's details
      setFormValues((prevValues) => ({
        ...prevValues,
        gstin: record.inv_det_gstin || "",
        legalName: record.inv_det_legal_name || "",
        address1: record.inv_det_address_one || "",
        address2: record.inv_det_address_two || "",
        location: record.inv_det_location || "",
        pincode: record.inv_det_pincode || "",
        phoneNo: record.inv_det_phone_number || "",
        state: record.inv_det_state || "",
        documentType: record.inv_det_document_type || "",
        documentNumber: record.inv_det_document_number || "",
        documentDate: record.inv_det_document_date || "",
        category: record.inv_det_category || "",
        reverSeCharger: record.inv_det_reverse_charge || "",
        pan: record.inv_det_pan || "",
        igstOnIntra: record.inv_det_igst_on_intra || "",
        transactionType: record.inv_det_transaction_type || "",
        email: record.inv_det_email || "",
        placeOfSupply: record.inv_det_place_of_supply || "",
        dispatchlegalName: record.dispatch_det_legal_name || "",
        dispatchlocation: record.dispatch_det_location || "",
        dispatchpincode: record.dispatch_det_pin_code || "",
        dispatchaddress1: record.dispatch_det_address_one || "",
        dispatchaddress2: record.dispatch_det_address_two || "",
        dispatchstate: record.dispatch_det_state || "",
        shipgstin: record.shipping_det_gstin || "",
        shiplegalName: record.shipping_det_legal_name || "",
        shipaddress1: record.shipping_det_address_one || "",
        shipaddress2: record.shipping_det_address_two || "",
        shiplocation: record.shipping_det_location || "",
        shippincode: record.shipping_det_pin_code || "",
        shipstate: record.shipping_det_state || "",
      }));

      localStorage.setItem("placeOfSupply", record.inv_det_place_of_supply )

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteClick = (record) => {
    setDialog({ open: true, record }); 
  };

  const handlCl = () => {
    setDialog({ open: false, record: null }); 
  };

 

  const confirmDelete = () => {
    const { record } = dialog;
    if (!record) return;

    const apiUrl = `/einvoice/delete_tax_cn_dn_master/${record.business_entity_id}/${record.id}/`;

    axios
      .delete(apiUrl)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
        }

        refresh(); 
        setDialog({ open: false, record: null }); 
      })
      .catch((error) => {
        console.error("Error response:", error.response);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred while deleting the record";
        toast.error(errorMessage);
      });
  };

  const handledownload = (record) => {
    const apiUrl = `/einvoice/export_normal_invoice/${record.id}/${record.business_entity_id}`;
    const fileName = "Invoice";
    axios
      .get(apiUrl)
      .then((response) => {
       
        const contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const base64Data = response.data.file; 
        downloadBase64File(contentType, base64Data, `${fileName}.pdf`);
      })
      .catch((error) => {
        console.error("Error exporting file:", error);
        const errorMessage =
        error.response?.data?.message;
      toast.error(errorMessage); 
  
      });
  };

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(index)) {
        newSelected.delete(index);
      } else {
        newSelected.add(index);
      }
      return newSelected;
    });
  };

  const handleDiscountInputChange = (e, id) => {
    setFreeze(parseFloat(e.target.value) !== 0 && Boolean(e.target.value));
    const discountValue = parseFloat(e.target.value) || 0;

    const new_products = products.map((item) => {
      if (item.id === id) {
        const grossAmount = parseFloat(item.amount_det_gross_amount) || 0;
        const taxableValue = grossAmount - discountValue;

        const igstValue = parseFloat(item.amount_det_igst_value) || 0;
        const cgstValue = parseFloat(item.amount_det_cgst_value) || 0;
        const sgstValue = parseFloat(item.amount_det_sgst_value) || 0;
        const stateCessValue =
          parseFloat(item.amount_det_state_cess_value) || 0;
        const stateCessNonAdValue =
          parseFloat(item.amount_det_state_cess_non_ad_value) || 0;
        const cessAdValue = parseFloat(item.amount_det_cess_ad_value) || 0;
        const cessNonAdValue =
          parseFloat(item.amount_det_cess_non_ad_value) || 0;
        const otherCharges = parseFloat(item.amount_det_other_charges) || 0;

        const totalItemAmount = (
          taxableValue +
          igstValue +
          cgstValue +
          sgstValue +
          stateCessValue +
          stateCessNonAdValue +
          cessAdValue +
          cessNonAdValue +
          otherCharges
        ).toFixed(2);

        return {
          ...item,
          amount_det_discount: parseFloat(e.target.value) || null,
          amount_det_taxable_value: taxableValue.toFixed(2),
          amount_det_igst_value: igstValue.toFixed(2),
          amount_det_state_cess_value: stateCessValue.toFixed(2),
          amount_det_total_item_amount: totalItemAmount,
          amount_det_cess_ad_value: cessAdValue.toFixed(2),
        };
      }
      return item;
    });

    setProducts(new_products);
  };

 
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(new Set(products.map((_, index) => index)));
    } else {
      setSelectedRows(new Set());
    }
    setSelectAllChecked(event.target.checked);
  };

  const deleteSelectedRows = () => {
 
    const selectedIds = Array.from(selectedRows).map(
      (index) => products[index].id
    );

    setProducts((prevProducts) =>
      prevProducts.filter((_, index) => !selectedRows.has(index))
    );
    setSelectedRows(new Set()); 
    setSelectAllChecked(false);
  };

  
  const handleClearAll = () => {
    setFormValues({
      category: "",
      reverSeCharger: "",
      gstin: "",
      legalName: "",
      pan: "",
      documentType: "",
      documentNumber: "",
      documentDate: "",
      igstOnIntra: "",
      transactionType: "",
      address1: "",
      address2: "",
      location: "",
      pincode: "",
      phoneNo: "",
      state: "",
      email: "",
      placeOfSupply: "",
      dispatchlegalName: "",
      dispatchlocation: "",
      dispatchpincode: "",
      dispatchaddress1: "",
      dispatchaddress2: "",
      dispatchstate: "",
      shipgstin: "",
      shiplegalName: "",
      shipaddress1: "",
      shipaddress2: "",
      shiplocation: "",
      shippincode: "",
      shipstate: "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasErrors = false;
    const newErrors = {};
    if (products.length === 0) {
     
      toast.success("Please add at least one line item");
      
      return;
    }
    if (!formValues.category) {
      newErrors.category = "Category is required";
      hasErrors = true;
    }

    if (!formValues.legalName) {
      newErrors.legalName = "Legal Name is required";
      hasErrors = true;
    }

    if (!formValues.gstin) {
      newErrors.gstin = "GSTIN is required";
      hasErrors = true;
    }

    if (!formValues.address1) {
      newErrors.address1 = "Address 1 is required";
      hasErrors = true;
    }

    if (!formValues.documentType) {
      newErrors.documentType = "Document Type is required";
      hasErrors = true;
    }

    if (!formValues.location) {
      newErrors.location = "Location is required";
      hasErrors = true;
    }

    if (!formValues.state) {
      newErrors.state = "State is required";
      hasErrors = true;
    }
    if (!formValues.documentNumber) {
      newErrors.documentNumber = "Document Number is required";
      hasErrors = true;
    }

    if (!formValues.pincode) {
      newErrors.pincode = "Pincode is required";
      hasErrors = true;
    } else if (!/^\d{6}$/.test(formValues.pincode)) {
      newErrors.pincode = "Pincode must be 6 numeric characters";
      hasErrors = true;
    }

    if (!formValues.documentDate) {
      newErrors.documentDate = "Document Date is required";
      hasErrors = true;
    }

    if (!formValues.placeOfSupply) {
      newErrors.placeOfSupply = "Place of Supply is required";
      hasErrors = true;
    }

    setErrors(newErrors);

    if (hasErrors) {
      return; 
    }
    if (products.length === 0) {
      setSnackbarMessage("Please add atleast one item");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      return;
    }


    const business_entity_id = localStorage.getItem("business_entity_id");
 
    const dataToSend = {
      ...formValues,
      products,
      business_entity_id,
      discount,
      roundOff,
      grandTotal: grandTotal.toFixed(2),
      editedRowId: editedRowId,
    };

    try {
      const response = await axios.post(
        `/einvoice/post_tax_cn_dn_master`,
        dataToSend
      );


      if (response.data.status) {
        toast.success(response.data.message);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");

        setFormValues({
          category: "",
          reverSeCharger: "",
          gstin: "",
          legalName: "",
          pan: "",
          documentType: "",
          documentNumber: "",
          documentDate: "",
          igstOnIntra: "",
          transactionType: "",
          address1: "",
          address2: "",
          location: "",
          pincode: "",
          phoneNo: "",
          state: "",
          email: "",
          placeOfSupply: "",
          dispatchlegalName: "",
          dispatchlocation: "",
          dispatchpincode: "",
          dispatchaddress1: "",
          dispatchaddress2: "",
          dispatchstate: "",
          shipgstin: "",
          shiplegalName: "",
          shipaddress1: "",
          shipaddress2: "",
          shiplocation: "",
          shippincode: "",
          shipstate: "",
        });
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");

        setProducts([]);
        setHighlightedCell({ rowId: formValues.id, cellKey: "trade_name" }); 
        setTimeout(() => {
          setHighlightedCell({ rowId: null, cellKey: null });
        }, 7000);

        await refresh();
        handleToggle({ target: { checked: false } });
        setEditedRowId(null)
      }
    } catch (error) {
      toast.error(error.response.data.message);
   

      console.error("Error submitting records:", error);
      if (error.response.data.message) {
        setSnackbarMessage(error.response.data.message); 
      } else {
        setSnackbarMessage("An unexpected error occurred"); 
      }

    
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    
  };

  useEffect(() => {
    if (openDialogs) {
      const entityName = localStorage.getItem('entity_name');
      if (entityName) {
        setFormValues((prevValues) => ({
          ...prevValues,
          dispatchlegalName: entityName, 
        }));
      }
    }
  }, [openDialogs]);

  const handleDisabledClick = () => {
  
    alert("Please Fill Mandatory Fields");
  };

  const resetForm = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      dispatchlegalName: "",
      dispatchlocation: "",
      dispatchpincode: "",
      dispatchaddress1: "",
      dispatchaddress2: "",
      dispatchstate: "",
    }));
  };
  
  const   ResetBillToShipTo = () => {
  
    setFormValues((prevState)=>({
      ...prevState,
   shipgstin:"",
   shiplegalName:"",
   shipaddress1:"",
   shipaddress2:"",
   shiplocation:"",
   shippincode:"",
   shipstate:"",


    }))
  }
  const  ResettheCombination = () => {
    setFormValues((prevState)=>({
      ...prevState,
      shipgstin:"",
      shiplegalName:"",
      shipaddress1:"",
      shipaddress2:"",
      shiplocation:"",
      shippincode:"",
      shipstate:"",
      dispatchlegalName: "",
      dispatchlocation: "",
      dispatchpincode: "",
      dispatchaddress1: "",
      dispatchaddress2: "",
      dispatchstate: "",
     
    }));
  }
  const handleOpenDialog = () => {
    if (!isButtonEnabled) {
      handleDisabledClick();
      return;
    }
    setAdditionalValue("");
    setSelectedProduct(null);
    setEditingIndex(null);
    setIsEditing(false); 
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

 
  const handleSaveProduct = (product) => {
    if (isEditing) {
 
      setProducts((prevProducts) =>
        prevProducts.map((p, idx) => (p.id === editingIndex ? product : p))
      );
      setIsEditing(false); 
      setEditingIndex(null); 
    } else {

      setProducts((prevProducts) => [...prevProducts, product]);
    }

    setOpenDialog(false); 
  };

  const handleViewClick = (record) => {
    setSelectedRecord(record);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRecord(null);
  };

  products.forEach((product, index) => {
   
    const currentValue = parseFloat(product.amount_det_taxable_value);
    const previousValue =
      index > 0 ? parseFloat(products[index - 1].amount_det_taxable_value) : 0;
    const nextValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_taxable_value)
        : 0;

    
    const sum = previousValue + currentValue + nextValue;


  });


  const totalTaxableValue = products.reduce((total, product) => {
    return total + parseFloat(product.amount_det_taxable_value);
  }, 0);

 
  products.forEach((product, index) => {
    const currentIgstValue = parseFloat(product.amount_det_igst_value) || 0;
    const previousIgstValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_igst_value) || 0
        : 0;
    const nextIgstValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_igst_value) || 0
        : 0;

    const sumIgst = previousIgstValue + currentIgstValue + nextIgstValue;

  });


  const totalIgstValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_igst_value) || 0);
  }, 0);


  products.forEach((product, index) => {
    const currentCgstValue = parseFloat(product.amount_det_cgst_value) || 0;
    const previousCgstValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_cgst_value) || 0
        : 0;
    const nextCgstValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_cgst_value) || 0
        : 0;

    const sumCgst = previousCgstValue + currentCgstValue + nextCgstValue;

  });


  const totalCgstValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_cgst_value) || 0);
  }, 0);

 
  products.forEach((product, index) => {
    const currentSgstValue = parseFloat(product.amount_det_sgst_value) || 0;
    const previousSgstValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_sgst_value) || 0
        : 0;
    const nextSgstValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_sgst_value) || 0
        : 0;

    const sumSgst = previousSgstValue + currentSgstValue + nextSgstValue;

    
  });

 
  const totalSgstValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_sgst_value) || 0);
  }, 0);

  products.forEach((product, index) => {
    const currentCessAdValue =
      parseFloat(product.amount_det_cess_ad_value) || 0;
    const previousCessAdValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_cess_ad_value) || 0
        : 0;
    const nextCessAdValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_cess_ad_value) || 0
        : 0;

    const sumCessAd =
      previousCessAdValue + currentCessAdValue + nextCessAdValue;


  });

  // Calculate the total Cess AD value
  const totalCessAdValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_cess_ad_value) || 0);
  }, 0);

  // Process amount_det_cess_non_ad_value
  products.forEach((product, index) => {
    const currentCessNonAdValue =
      parseFloat(product.amount_det_cess_non_ad_value) || 0;
    const previousCessNonAdValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_cess_non_ad_value) || 0
        : 0;
    const nextCessNonAdValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_cess_non_ad_value) || 0
        : 0;

    const sumCessNonAd =
      previousCessNonAdValue + currentCessNonAdValue + nextCessNonAdValue;


  });

 
  const totalCessNonAdValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_cess_non_ad_value) || 0);
  }, 0);

 
  products.forEach((product, index) => {
    const currentStateCessNonAdValue =
      parseFloat(product.amount_det_state_cess_non_ad_value) || 0;
    const previousStateCessNonAdValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_state_cess_non_ad_value) ||
          0
        : 0;
    const nextStateCessNonAdValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_state_cess_non_ad_value) ||
          0
        : 0;

    const sumStateCessNonAd =
      previousStateCessNonAdValue +
      currentStateCessNonAdValue +
      nextStateCessNonAdValue;

  });

  
  const totalStateCessNonAdValue = products.reduce((total, product) => {
    return (
      total + (parseFloat(product.amount_det_state_cess_non_ad_value) || 0)
    );
  }, 0);


  products.forEach((product, index) => {
    const currentStateCessValue =
      parseFloat(product.amount_det_state_cess_value) || 0;
    const previousStateCessValue =
      index > 0
        ? parseFloat(products[index - 1].amount_det_state_cess_value) || 0
        : 0;
    const nextStateCessValue =
      index < products.length - 1
        ? parseFloat(products[index + 1].amount_det_state_cess_value) || 0
        : 0;

    const sumStateCess =
      previousStateCessValue + currentStateCessValue + nextStateCessValue;


  });

  // Calculate the total State Cess value
  const totalStateCessValue = products.reduce((total, product) => {
    return total + (parseFloat(product.amount_det_state_cess_value) || 0);
  }, 0);

  const totalStateCessAmount =
    totalCessAdValue +
    totalCessNonAdValue +
    totalStateCessNonAdValue +
    totalStateCessValue;



  useEffect(() => {
    const total = products.reduce((sum, product) => {
      return sum + parseFloat(product.amount_det_total_item_amount) || 0;
    }, 0);
    setTotalAmount(total);
  }, [products]);

  useEffect(() => {
    const parsedDiscount = parseFloat(discount) || 0;
    const parsedRoundOff = parseFloat(roundOff) || 0;
    setGrandTotal(totalAmount - parsedDiscount + parsedRoundOff);
  }, [totalAmount, discount, roundOff]); 

  const handleDiscountChange = (e) => {
    const value = e.target.value;


    const filteredValue = value
      .replace(/[^0-9.]/g, "") 
      .replace(/(\..*?)\..*/g, "$1"); 

    setDiscount(filteredValue);
  };

  const handleRoundOffChange = (e) => {
    setRoundOff(e.target.value);
  };

  const handleLegalNameSelect = (legalName) => {
    const selectedRecipient = recipientData.find(
      (rec) => rec.recipient_legal_name === legalName
    );
    if (selectedRecipient) {
      localStorage.setItem(
        "RECIPIENTLEGALNAME",
        selectedRecipient.recipient_legal_name
      );
      setFormValues((prevValues) => ({
        ...prevValues,
        gstin: selectedRecipient.recipient_gstin,
        legalName: selectedRecipient.recipient_legal_name,
        address1: selectedRecipient.recipient_address_one,
        address2: selectedRecipient.recipient_address_two,
        location: selectedRecipient.recipient_location,
        pincode: selectedRecipient.recipient_pincode,
        phoneNo: selectedRecipient.recipient_phone,
        state: selectedRecipient.recipient_state,
      }));
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    const fetchRecipientData = async () => {
      try {
        const businessEntityId = localStorage.getItem("business_entity_id");
        const response = await axios.get(
          `/einvoice/get_recipient_by_gstin/${businessEntityId}/${formValues.legalName}`
        );
        if (response?.data?.status && response?.data?.records?.length > 0) {
          setRecipientData(response.data.records);
          setFilteredLegalNames(
            response.data.records.map((record) => record.recipient_legal_name)
          );
        } else {
          setRecipientData([]);
          setFilteredLegalNames([]);
        }
      } catch (error) {
        console.error("Error fetching recipient data:", error);
      }
    };

    if (formValues?.legalName?.length > 0) {
      // Fetch data when user types at least 3 characters
      fetchRecipientData();
    } else {
      setFilteredLegalNames([]);
    }
  }, [formValues.legalName]);

  useEffect(() => {
    const fetchRecipientData = async () => {
      try {
        const businessEntityId = localStorage.getItem("business_entity_id");
        const response = await axios.get(
          `/einvoice/get_recipient_by_gstin/${businessEntityId}/${formValues.gstin}`
        );
        if (response?.data?.status && response?.data?.records.length > 0) {
          setRecipientData(response.data.records);
          setFilteredGSTINs(
            response.data.records.map((record) => record.recipient_gstin)
          );
        } else {
          setRecipientData([]);
          setFilteredGSTINs([]);
        }
      } catch (error) {
        console.error("Error fetching recipient data:", error);
      }
    };

    if (formValues?.gstin?.length > 0) {
      // Fetch data when user types at least 3 characters
      fetchRecipientData();
    } else {
      setFilteredGSTINs([]);
    }
  }, [formValues.gstin]);

 

  const handleGSTINSelect = (gstin) => {
    const selectedRecipient = recipientData.find(
      (rec) => rec.recipient_gstin === gstin
    );
    if (selectedRecipient) {
      localStorage.setItem("RECIPIENTGSTIN", selectedRecipient.recipient_gstin);
      setFormValues((prevValues) => ({
        ...prevValues,
        gstin: selectedRecipient.recipient_gstin,
        legalName: selectedRecipient.recipient_legal_name,
        address1: selectedRecipient.recipient_address_one,
        address2: selectedRecipient.recipient_address_two,
        location: selectedRecipient.recipient_location,
        pincode: selectedRecipient.recipient_pincode,
        phoneNo: selectedRecipient.recipient_phone,
        state: selectedRecipient.recipient_state,
      }));
      setShowDropdown(false);
    }
  };

  // Your input field for Legal Name
  <div style={{ flex: "1 1 30%" }}>
    <label>
      Legal Name
      <input
        type="text"
        name="legalName"
        value={formValues.legalName || ""} // Ensure the input shows the value from formValues
        onChange={handleInputChange}
        placeholder="Legal Name"
        style={{
          width: "100%",
          padding: "8px",
          margin: "8px 0",
          borderColor: errors.legalName ? "red" : "initial",
        }}
      />
      {errors.legalName && (
        <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
          {errors.legalName}
        </div>
      )}
    </label>
  </div>;

  const handleCloseBox = () => {
    setBoxVisible(false); // Hide the box when close button is clicked
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Set the file state
      setLoading(true); // Start loading state

      try {
        // Convert file to Base64
        const base64File = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = `data:${selectedFile.type};base64,${btoa(
              reader.result
            )}`;
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsBinaryString(selectedFile);
        });

        const businessEntityId = localStorage.getItem("business_entity_id");
        const user = localStorage.getItem("user_id");

        if (!businessEntityId) {
          console.error("Business entity ID is missing.");
          setLoading(false); 
          return;
        }

        await axios.post("einvoice/import_tax_cn_dn", {
          business_entity_id: businessEntityId,
          user: user,
          file: base64File,
        });

    
        const formNumber = "102"; 
        const response = await axios.get(
          `/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`
        );

        setUploadHistory(response.data.record);

    
        setTimeout(() => {
          refresh();
        }, 1000);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false); // Stop loading state
        // Clear file input value to allow re-uploading the same file
        event.target.value = null;
      }
    }
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = Tax;
    link.download = "TaxCreditDebit.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const refresh = async (pageNumber = 0, rowsPerPage = 25, searchTxt = "") => {
    const businessEntityId = localStorage.getItem("business_entity_id");
    const formNumber = "102"; 

    try {
      const postResponse = await axios.post("/einvoice/upload_tax", {
        number_of_rows_per_page: rowsPerPage,
        page_number: pageNumber,
        business_entity_id: businessEntityId,
        search_txt: searchTerm,
      });

    

      setRowCount(postResponse.data.row_count);
      if (postResponse.data.records) {
        setRows(postResponse.data.records); 
      }
      // After successful POST, make the second URL call to retrieve upload history
      const response = await axios.get(
        `/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`
      );

      setUploadHistory(response.data.record);
      // Update rowCount state
      //  setRowCount(rowCount);

      setRefreshTrigger((prev) => !prev);
    } catch (error) {
      console.error("Error refreshing data:", error);
      // Handle error if necessary
    }
  };
  // useEffect(() => {
  //   fetchData(); // Call fetchData when refreshTrigger changes
  // }, [refreshTrigger]);

  useEffect(() => {
    refresh(page, rowsPerPage, searchTerm); // Call the refresh function when the component mounts
  }, [page, rowsPerPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(
    (record) =>
      (record.inv_det_gstin
        ? String(record.inv_det_gstin).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase()) ||
      (record.inv_det_legal_name
        ? String(record.inv_det_legal_name).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase()) ||
      (record.inv_det_document_type
        ? String(record.inv_det_document_type).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase()) ||
      (record.inv_det_document_number
        ? String(record.inv_det_document_number).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase()) ||
      (record.inv_det_document_date
        ? String(record.inv_det_document_date).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase()) ||
      (record.amount_det_total_item_amount
        ? String(record.amount_det_total_item_amount).toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase())
  );

  const tableStyles = {
    fontFamily: "Arial, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  };

  const tdThStyles = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  };

  const trEvenStyles = {
    backgroundColor: "#f2f2f2",
  };

  const states = [
    "Jammu & Kashmir",
    "Himachal Pradesh",
    "Punjab",
    "Chandigarh",
    "Uttarakhand",
    "Haryana",
    "Delhi",
    "Rajasthan",
    "Uttar Pradesh",
    "Bihar",
    "Sikkim",
    "Arunachal Pradesh",
    "Nagaland",
    "Manipur",
    "Mizoram",
    "Tripura",
    "Meghalaya",
    "Assam",
    "WestBengal",
    "Jharkhand",
    "Odisha",
    "Chhattisgarh",
    "Madhya Pradesh",
    "Gujarat",
    "Daman & Diu",
    "Dadra & NagarHaveli ",
    "Maharashtra",
    "Karnataka",
    "Goa",
    "Lakshdweep",
    "Kerala",
    "Tamil Nadu",
    "Puducherry",
    "Andaman & NicobarIslands",
    "Telangana",
    "Andhra Pradesh",
    "Ladakh",
    "Other Territory",
    "Other Country",
  ];

  const handleEyeIconClick = async (id) => {
    try {
      const response = await axios.get(`/einvoice/get_data_upload_json/${id}`);
      const { data } = response;
      setResponseData(data);
      if (
        data &&
        data.records &&
        data.records.records &&
        data.records.records.summary
      ) {
        setBoxVisible(true);
        setSummaryData(data.records.records.summary);
      }
      // Check if the response contains a message
      if (data && data.records && data.records.message) {
        // Display success or informational message based on the response
        if (!data.records.status) {
          setErrorMessage(data.records.message);
          setSnackbar({
            open: true,
            message: data.records.message,
            severity: "error",
          });
          handleCloseBox();
        } else {
          setErrorMessage("");
          setSnackbar({
            open: true,
            message: data.records.message,
            severity: "success",
          });
        }
      } else {
        setSnackbar({ open: false, message: "", severity: "success" }); 
      }
      setDialogData(data.records.records);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch data",
        severity: "error",
      });
    }
  };
  const cardStyle1 = {
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#6f9eaf",
    textAlign: "left", 
    width: "300px",
    height: "70px",
    color: "white",
    fontSize: "20px",
    padding: "10px", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  };

  const cardStyle2 = {
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#007965",
    textAlign: "left", 
    width: "300px",
    height: "70px",
    color: "white",
    fontSize: "20px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start", 
    alignItems: "flex-start", 
  };

  const cardStyle3 = {
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#eb596e",
    textAlign: "left",
    width: "300px",
    height: "70px",
    color: "white",
    fontSize: "20px",
    padding: "10px", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start", 
  };

  const handleCloses = () => {
    setDialogOpens(false);
    setDialogData(null);
  };

  const style1 = {
    overflowX: "auto",
    whiteSpace: "nowrap",

   
    scrollbarWidth: "thin", 
    scrollbarColor: "#888 #f1f1f1", 
  };

  

  const handleExportExcel = () => {
    const bid = localStorage.getItem("business_entity_id");
    const fileName = "Taxinvoice"; 

    if (bid) {
      const url = `/einvoice/export_tax_cn_dn_master/${fileName}/${bid}`;

      axios
        .get(url)
        .then((response) => {
         
          const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const base64Data = response.data.file; 
          downloadBase64File(contentType, base64Data, `${fileName}.xlsx`);
        })
        .catch((error) => {
          console.error("Error exporting file:", error);
        });
    } else {
      console.error("Business entity ID not found in localStorage");
    }
  };

  const handleExcel = async () => {
    const businessEntityIdRaw = localStorage.getItem("business_entity_id");
    // const str = businessEntityIdRaw ? String(businessEntityIdRaw) : null;

    // Check if business entity ID is available
    if (!businessEntityIdRaw) {
      console.error("Business entity ID is missing");
      return;
    }

    try {
   
      if (!responseData) {
        console.error("Response data is missing");
        return;
      }

      const payloadData = {
        business_entity_id: businessEntityIdRaw,
        data: responseData,
      };

      
      const response = await axios.post(
        `/einvoice/download_error_data`,
        payloadData
      );

      
      const base64Data = response.data.file; 
      if (base64Data) {
        const contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = "TaxInvoiceUploadErrorLogs.xlsx";
        downloadBase64File(contentType, base64Data, filename);
      } else {
        console.error("Base64 data not found in the response");
      }
    } catch (error) {
     
      console.error("Error fetching Excel file:", error);
    }
  };

 
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const handleToggleChange = () => {
    setShowUploadHistoryTable(!showUploadHistoryTable);
  };
  const handleToggle = (event) => {
    setReciepentMaster(event.target.checked);
    setEditedRowId(null)
    if (!event.target.checked) {
      setFormValues({
        category: "",
        reverSeCharger: "",
        gstin: "",
        legalName: "",
        pan: "",
        documentType: "",
        documentNumber: "",
        documentDate: "",
        igstOnIntra: "",
        transactionType: "",
        address1: "",
        address2: "",
        location: "",
        pincode: "",
        phoneNo: "",
        state: "",
        email: "",
        placeOfSupply: "",
        dispatchlegalName: "",
        dispatchlocation: "",
        dispatchpincode: "",
        dispatchaddress1: "",
        dispatchaddress2: "",
        dispatchstate: "",
        shipgstin: "",
        shiplegalName: "",
        shipaddress1: "",
        shipaddress2: "",
        shiplocation: "",
        shippincode: "",
        shipstate: "",
      });
      setProducts([]);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <ToggleSwitch
            onChange={handleToggleChange}
            value={showUploadHistoryTable}
            label="Upload Tax Invoice"
          />
          <div className="container">
            <div>
              <input
                type="checkbox"
                className="toggle-switch"
                id="default1"
                onChange={handleToggle}
                checked={ReciepentMaster}
              />
              <label htmlFor="default1"></label>
            </div>
            <p> Add Tax Invoice</p>
          </div>
        </div>
      </div>
      {showUploadHistoryTable && (
        <BoxWrapper
          boxTitle="Tax Invoice Uploads"
         
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Button onClick={handleDownloadTemplate}>
                <GetAppIcon style={{ fontSize: "20px" }} />
                Download Template
              </Button>
              <Button component="label" size="small" color="primary">
                <PublishIcon style={{ fontSize: "20px" }} />
                UPLOAD TEMPLATE
                <input
                  accept=".xlsx"
                  name="file"
                  type="file"
                  hidden
                  onChange={handleFileChange} 
                />
              </Button>
            </div>
            <Button
              variant="contained"
              onClick={() => refresh()}
              style={{
                marginTop: "10px",
                marginLeft: "440px",
                backgroundColor: "white",
                border: "none",
                color: "#3e71c2", 
                boxShadow: "none", 
              }}
              startIcon={<RefreshIcon />}
            >
              REFRESH
            </Button>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              variant="filled"
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              variant="filled"
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "15px",
                    }}
                  >
                    Upload Date
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "15px",
                    }}
                  >
                    Upload Type
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingLeft: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadHistory.map((record) => (
                  <TableRow key={record.id}>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.upload_date}
                    </TableCell>

                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.status}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.upload_type}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      <IconButton onClick={() => handleEyeIconClick(record.id)}>
                        {/* <VisibilityIcon /> */}
                        <img
                          src={viewImage}
                          alt="View"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </BoxWrapper>
      )}
      {isBoxVisible && (
        <BoxWrapper
          boxTitle="Uploads Details"
          exportToExcelEnable={true}
          exportToExcelFn={handleExcel}
          cutIcon={true} // This enables the close icon
          closeBoxWapper={handleCloseBox}
        >
          {summaryData ? (
            <div>
              {/* Cards Section */}
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={cardStyle1}>
                  <div>Total Records</div>
                  <div>{summaryData.total_records}</div>
                </div>
                <div style={cardStyle2}>
                  <div>Valid Records</div>
                  <div>{summaryData.valid_records}</div>
                </div>
                <div style={cardStyle3}>
                  <div>Invalid Records</div>
                  <div>{summaryData.invalid_records}</div>
                </div>
              </div>

              {/* Table Section */}
              <div style={{ marginTop: "20px" }}>
                {" "}
                {/* This wrapper creates a new line for the table */}
                {summaryData.invalid_records !== 0 && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>GSTIN</TableCell>
                          <TableCell>INVOICE NUMBER</TableCell>
                          <TableCell>ROW#</TableCell>
                          <TableCell>ERRORS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dialogData && Array.isArray(dialogData.details) ? (
                          dialogData.details.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{}</TableCell>{" "}
                              {/* Replace with actual field */}
                              <TableCell>{}</TableCell>{" "}
                              {/* Replace with actual field */}
                              <TableCell>{item.row}</TableCell>
                              <TableCell>
                                {item.errors_in
                                  ? Object.entries(item.errors_in).map(
                                      ([field, errors], i) => (
                                        <div key={i}>
                                          {field}: {errors.join(", ")}
                                        </div>
                                      )
                                    )
                                  : "No errors"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>No data available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </BoxWrapper>
      )}
      {ReciepentMaster && (
        <BoxWrapper boxTitle="Tax Invoice Entry">
          {/* <form onSubmit={handleSubmit}> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#3e5b9e",
              borderRadius: "3px",
            }}
          >
            <h1
              style={{
                fontSize: "17px",
                color: "white",
                paddingTop: "2px",
                paddingLeft: "70px",
                marginRight: "20px",
              }}
            >
              Document Details
            </h1>
            <h1
              style={{
                fontSize: "17px",
                color: "white",
                paddingLeft: "360px",
                paddingTop: "2px",
              }}
            >
              Bill To Details
            </h1>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Category{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <select
                  name="category"
                  value={formValues.category}
                  onChange={handleInputChange}
                  style={{
                    width: "175%",
                    height: "45px",
                    padding: "12px",
                    margin: "8px 0",
                    borderColor: errors.category ? "red" : "initial",
                  }}
                >
                  <option value="" disabled hidden></option>
                  <option value="B2B">B2B</option>
                  <option value="SEZWP">SEZWP</option>
                  <option value="SEZWOP">SEZWOP</option>
                  <option value="EXPWP">EXPWP</option>
                  <option value="EXPWOP">EXPWOP</option>
                  <option value="DEXP">DEXP</option>
                </select>
                {errors.category && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.category}
                  </div>
                )}
              </label>
            </div>

            <div style={{ position: "relative", flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Legal Name{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  name="legalName"
                  autocomplete="off"
                  value={formValues.legalName || ""}
                  onChange={handleInputChange}
                  // placeholder="Legal Name"
                  style={{
                    width: "120%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.legalName ? "red" : "initial",
                    borderWidth: errors.legalName ? "2px" : "initial",
                  }}
                  onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                  onBlur={() => setTimeout(() => setShowDropdown(false), 300)} // Hide dropdown after a short delay
                />
                {errors.legalName && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.legalName}
                  </div>
                )}
              </label>
              {showDropdown && filteredLegalNames.length > 0 && (
                <div
                  style={{
                    border: "1px solid #ddd",
                    maxHeight: "140px",
                    overflowY: "auto",
                    position: "absolute",
                    backgroundColor: "#fff",
                    width: "80%",
                    top: "100%", // Position directly below the input
                    left: "0",
                    zIndex: 1000, // Ensure dropdown is on top
                  }}
                >
                  {filteredLegalNames.map((legalName) => (
                    <div
                      key={legalName}
                      onClick={() => handleLegalNameSelect(legalName)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                        fontWeight: "bold",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#4CAF50")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      <span
                        style={{ color: "#000", transition: "color 0.3s ease" }}
                      >
                        {legalName}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{ position: "relative", flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                GSTIN <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  name="gstin"
                  value={formValues.gstin}
                  autocomplete="off"
                  onChange={handleInputChange}
                  // placeholder="GSTIN"
                  style={{
                    width: "145%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.gstin ? "red" : "initial",
                  }}
                  onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                  onBlur={() => setTimeout(() => setShowDropdown(false), 9000)} // Hide dropdown after a short delay
                />
                {errors.gstin && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.gstin}
                  </div>
                )}
              </label>
              {showDropdown && filteredGSTINs.length > 0 && (
                <div
                  style={{
                    border: "1px solid #381414",
                    maxHeight: "140px",
                    overflowY: "auto",
                    position: "absolute",
                    backgroundColor: "#fff",
                    width: "80%",
                    top: "100%", // Position directly below the input
                    left: "0",
                    zIndex: 1000, // Ensure dropdown is on top
                  }}
                >
                  {filteredGSTINs.map((gstin) => (
                    <div
                      key={gstin}
                      onClick={() => handleGSTINSelect(gstin)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#4CAF50")
                      } // Green background on hover
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      <span
                        style={{ color: "#000", transition: "color 0.3s ease" }}
                      >
                        {gstin}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Reverse Charge
                <select
                  name="reverSeCharger"
                  value={formValues.reverSeCharger}
                  onChange={handleInputChange}
                  style={{
                    width: "180%",
                    height: "45px",
                    padding: "12px",
                    margin: "8px 0",
                    borderColor: errors.reverSeCharger ? "red" : "initial",
                  }}
                >
                  <option value="" disabled hidden></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {errors.reverSeCharger && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.reverSeCharger}
                  </div>
                )}
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Address 1{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  disabled
                  name="address1"
                  value={formValues.address1}
                  onChange={handleInputChange}
                  style={{
                    width: "130%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.address1 ? "red" : "initial",
                  }}
                />
                {errors.address1 && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.address1}
                  </div>
                )}
              </label>
            </div>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Address 2
                <input
                  type="text"
                  name="address2"
                  value={formValues.address2}
                  onChange={handleInputChange}
                  style={{ width: "140%", padding: "8px", margin: "8px 0" }}
                />
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Document Type{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <select
                  name="documentType"
                  value={formValues.documentType}
                  onChange={handleInputChange}
                  style={{
                    width: "137%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.documentType ? "red" : "initial",
                  }}
                >
                  <option value="" disabled hidden></option>
                  <option value="Tax Invoice">Tax Invoice</option>
                  <option value="Credit Note">Credit Note</option>
                  <option value="Debit Note">Debit Note</option>
                </select>
                {errors.documentType && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.documentType}
                  </div>
                )}
              </label>
            </div>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Location{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  name="location"
                  disabled
                  value={formValues.location}
                  onChange={handleInputChange}
                  style={{
                    width: "132%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.location ? "red" : "initial",
                  }}
                />
                {errors.location && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.location}
                  </div>
                )}
              </label>
            </div>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                State <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="state"
                  disabled
                  value={formValues.state}
                  onChange={handleInputChange}
                  style={{
                    width: "142%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.location ? "red" : "initial",
                  }}
                />
              </label>

              {errors.state && (
                <div
                  style={{ color: "red", fontSize: "12px", marginTop: "4px",fontWeight:'bold' }}
                >
                  {errors.state}
                </div>
              )}
            </div>


            <Dialog open={openDialogs} onClose={() => setOpenDialogs(false)}  fullWidth>
              
  <DialogContent>
    {showAdditionalFields && (
      <>
        <h4 style={{ marginBottom: "16px", color: "black" }}>Dispatch From</h4>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Legal Name
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                disabled
                name="dispatchlegalName"
                value={formValues.dispatchlegalName}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: errors.dispatchlegalName ? "red" : "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />
              {errors.dispatchlegalName && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {errors.dispatchlegalName}
                </div>
              )}
            </label>
          </div>

          <div style={{ flex: "1 1 45%" }}>
            <label>
              Location
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="dispatchlocation"
                value={formValues.dispatchlocation}
                onChange={handleInputChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^a-zA-Z\s]/g,
                    ""
                  ); // Allow only alphabets and spaces
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: errors.dispatchlocation ? "red" : "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />
              {errors.dispatchlocation && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {errors.dispatchlocation}
                </div>
              )}
            </label>
          </div>

          <div style={{ flex: "1 1 45%" }}>
            <label>
              Pincode
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="number"
                name="dispatchpincode"
                value={formValues.dispatchpincode}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 6); // Limit to 6 digits
                  handleInputChange({
                    target: { name: "dispatchpincode", value },
                  });
                }}
                style={{
                  width: "104%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: errors.dispatchpincode ? "red" : "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />
              {errors.dispatchpincode && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {errors.dispatchpincode}
                </div>
              )}
            </label>
          </div>

          <div style={{ flex: "1 1 45%" }}>
            <label>
              Address 1
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="dispatchaddress1"
                value={formValues.dispatchaddress1}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: errors.dispatchaddress1 ? "red" : "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />
              {errors.dispatchaddress1 && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {errors.dispatchaddress1}
                </div>
              )}
            </label>
          </div>

          <div style={{ flex: "1 1 45%" }}>
            <label>
              Address 2
              <input
                type="text"
                name="dispatchaddress2"
                value={formValues.dispatchaddress2}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />
            </label>
          </div>

          <div style={{ flex: "1 1 45%" }}>
            <label>
              State
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <select
                name="dispatchstate"
                value={formValues.dispatchstate}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderColor: errors.dispatchstate ? "red" : "",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              >
                <option value="">Select State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option value="Andaman and Nicobar Islands">
                              Andaman and Nicobar Islands
                            </option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Dadra and Nagar Haveli and Daman and Diu">
                              Dadra and Nagar Haveli and Daman and Diu
                            </option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Other Territory">
                              Other Territory
                            </option>
                            <option value="Other Country">
                              Other Country
                            </option>
              </select>
              {errors.dispatchstate && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {errors.dispatchstate}
                </div>
              )}
            </label>
          </div>
        </div>
      </>
    )}
  </DialogContent>
  <div style={{ display: "flex", padding: "16px" }}>
    <Button
      onClick={() => {
        const newErrors = {};

        // Validate the required dispatch fields
        if (!formValues.dispatchlegalName) {
          newErrors.dispatchlegalName = "Legal Name is required";
        }
        if (!formValues.dispatchlocation) {
          newErrors.dispatchlocation = "Location is required";
        }
        if (!formValues.dispatchpincode) {
          newErrors.dispatchpincode = "Pincode is required";
        } else if (!/^\d{6}$/.test(formValues.dispatchpincode)) {
          newErrors.dispatchpincode = "Pincode must be 6 numeric characters";
        }
        if (!formValues.dispatchaddress1) {
          newErrors.dispatchaddress1 = "Address 1 is required";
        }
        if (!formValues.dispatchstate) {
          newErrors.dispatchstate = "State is required";
        }

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setSnackbarMessage("Please fill out all mandatory fields.");
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
        } else {
          // If no errors, proceed with saving and closing the dialog
          setOpenDialogs(false);
          // Your save logic here
        }
      }}
      variant="outlined"
      color="secondary"
    >
      Save
    </Button>

    {/* <Button 
    onClick={() => setOpenDialogs(false) } variant="outlined" color="primary">
      Close
    </Button> */}
    <Button
  onClick={() => {
    setOpenDialogs(false);
    resetForm();
    // setFormValues({
    //   dispatchlegalName:""
    // })
    setErrors({});
  }}
  variant="outlined"
  color="primary"
>
  Close
</Button>



  </div>
</Dialog>


            {/* Conditionally rendered fields for "Bill To - Ship To" */}
          <Dialog
  open={openDialogbill}
  onClose={() => setopenDialogbill(false)}
  fullWidth
>
  <DialogContent>
    {showBillToShipToFields && (
      <>
        <h4 style={{ marginBottom: "16px", color: "black" }}>
          Ship To Details
        </h4>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          {/* GSTIN Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              GSTIN
              <input
                type="text"
                name="shipgstin"
                value={formValues.shipgstin}
                onChange={handleInputChange}
                maxLength="15"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                }}
                style={{
                  width: "110%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shipgstin ? "red" : "black",
                }}
              />
            </label>
            {errors.shipgstin && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shipgstin}
              </span>
            )}
          </div>

          {/* Legal Name Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Legal Name{" "}
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="shiplegalName"
                value={formValues.shiplegalName}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shiplegalName ? "red" : "black",
                }}
              />
            </label>
            {errors.shiplegalName && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shiplegalName}
              </span>
            )}
          </div>

          {/* Address 1 Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Address 1{" "}
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="shipaddress1"
                value={formValues.shipaddress1}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shipaddress1 ? "red" : "black",
                }}
              />
            </label>
            {errors.shipaddress1 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shipaddress1}
              </span>
            )}
          </div>

          {/* Address 2 Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Address 2
              <input
                type="text"
                name="shipaddress2"
                value={formValues.shipaddress2}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shipaddress2 ? "red" : "black",
                }}
              />
            </label>
          </div>

          {/* Location Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Location{" "}
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="shiplocation"
                value={formValues.shiplocation}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shiplocation ? "red" : "black",
                }}
              />
            </label>
            {errors.shiplocation && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shiplocation}
              </span>
            )}
          </div>

          {/* Pincode Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              Pincode{" "}
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <input
                type="text"
                name="shippincode"
                value={formValues.shippincode}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "").slice(0, 6);
                  handleInputChange({ target: { name: "shippincode", value } });
                }}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shippincode ? "red" : "black",
                }}
              />
            </label>
            {errors.shippincode && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shippincode}
              </span>
            )}
          </div>

          {/* State Field */}
          <div style={{ flex: "1 1 45%" }}>
            <label>
              State{" "}
              <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              <select
                name="shipstate"
                value={formValues.shipstate}
                onChange={handleInputChange}
                style={{
                  width: "92%",
                  padding: "8px",
                  margin: "8px 0",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor: errors.shipstate ? "red" : "black",
                }}
              >
                <option value="">Select State</option>
                <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option value="Other Territory">
                              Other Territory
                            </option>
                            <option value="Other Country">
                              Other Country
                            </option>
              </select>
              {errors.shipstate && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shipstate}
              </span>
            )}
            </label>
          
          </div>
        </div>
      </>
    )}
  </DialogContent>
  <div style={{ display: "flex", padding: "16px" }}>
    <Button
      onClick={() => {
        const newErrors = {};

        // Validation for Ship To fields
        if (!formValues.shipgstin) {
          newErrors.shipgstin = "GSTIN is required";
        } else if (formValues.shipgstin.length !== 15) {
          newErrors.shipgstin = "GSTIN must be 15 characters";
        }
        if (!formValues.shiplegalName) {
          newErrors.shiplegalName = "Legal Name is required";
        }
        if (!formValues.shipaddress1) {
          newErrors.shipaddress1 = "Address 1 is required";
        }
        if (!formValues.shiplocation) {
          newErrors.shiplocation = "Location is required";
        }
        if (!formValues.shippincode) {
          newErrors.shippincode = "Pincode is required";
        } else if (!/^\d{6}$/.test(formValues.shippincode)) {
          newErrors.shippincode = "Pincode must be 6 numeric characters";
        }
        if (!formValues.shipstate) {
          newErrors.shipstate = "State is required";
        }

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setSnackbarMessage("Please fill out all mandatory fields.");
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
        } else {
          // If no errors, proceed with saving and closing the dialog
          setopenDialogbill(false);
          // Your save logic here
        }
      }}
      variant="outlined"
      color="secondary"
    >
      Save
    </Button>
    <Button
      onClick={() => {
        setopenDialogbill(false)
        setErrors({});
        ResetBillToShipTo();
      }}
      variant="outlined"
      color="primary"
    >
      Close
    </Button>
  </div>
</Dialog>


            {/* Conditionally rendered fields for "Combination " */}
            <Dialog
              open={openDialogcomb}
              onClose={() => setopenDialogcomb(false)}
              fullWidth
            >
              <DialogContent>
                {showCombinationFields && (
                  <>
                    <h4 style={{ marginBottom: "16px", color: "black" }}>
                      Dispatch From
                    </h4>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
                    >
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          Legal Name{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                          <input
                            type="text"
                            name="dispatchlegalName"
                            value={formValues.dispatchlegalName}
                            onChange={handleInputChange}
                            // placeholder="Legal Name"
                            style={{
                              width: "100%",
                              padding: "8px",
                              margin: "8px 0",
                              borderColor: errors.dispatchlegalName ? "red" : "black",
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          />
                        </label> 
                        {errors.dispatchlegalName && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.dispatchlegalName}
              </span>
            )}
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          Location{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                          <input
                            type="text"
                            name="dispatchlocation"
                            value={formValues.dispatchlocation}
                            onChange={handleInputChange}
                            // placeholder="Location"
                            style={{
                              width: "105%",
                              padding: "8px",
                              margin: "8px 0",
                              borderColor: errors.dispatchlocation ? "red" : "black",
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          />
                        </label>
                        {errors.dispatchlocation && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.dispatchlocation}
              </span>
            )}
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          Pincode{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                          <input
                            type="text"
                            name="dispatchpincode"
                            value={formValues.dispatchpincode}
                            // onChange={handleInputChange}
                            onChange={(e) => {
                              const value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 6); // Limit to 6 digits
                              handleInputChange({
                                target: { name: "dispatchpincode", value },
                              });
                            }}
                            // placeholder="Pincode"
                            style={{
                              width: "98%",
                              padding: "8px",
                              margin: "8px 0",
                                borderColor: errors.dispatchpincode ? "red" : "black",
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          />
                        </label>  
                        {errors.dispatchpincode && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.dispatchpincode}
              </span>
            )}
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          Address 1{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                          <input
                            type="text"
                            name="dispatchaddress1"
                            value={formValues.dispatchaddress1}
                            onChange={handleInputChange}
                            // placeholder="Address 1"
                            style={{
                              width: "100%",
                              padding: "8px",
                              margin: "8px 0",
                              borderColor: errors.dispatchaddress1 ? "red" : "black",
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          />
                        </label>
                        {errors.dispatchaddress1 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.dispatchaddress1}
              </span>
            )}
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          Address 2
                          <input
                            type="text"
                            name="dispatchaddress2"
                            value={formValues.dispatchaddress2}
                            onChange={handleInputChange}
                            // placeholder="Address 2"
                            style={{
                              width: "100%",
                              padding: "8px",
                              margin: "8px 0",

                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          />
                        </label>
                      </div>
                      <div style={{ flex: "1 1 45%" }}>
                        <label>
                          State{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                          <select
                            name="dispatchstate"
                            value={formValues.dispatchstate}
                            onChange={handleInputChange}
                            style={{
                              width: "98%", // Adjusted to fit within the container
                              padding: "8px",
                              margin: "8px 0",
                              borderColor: errors.dispatchstate ? "red" : "black",
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}
                          >
                            <option value="">Select State</option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option  value="Other Territory"
            >
                              Other Territory
                            </option>
                            <option  value="Other Country"
            >
                              Other Country
                            </option>
                          </select>
                          {errors.dispatchstate && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.dispatchstate}
              </span>
            )}
                        </label>
                 
                      </div>

                      <h4 style={{ marginBottom: "16px", color: "black" }}>
                        Ship To Details
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "16px",
                        }}
                      >
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            GSTIN
                            <input
                              type="text"
                              name="shipgstin"
                              value={formValues.shipgstin}
                              onChange={handleInputChange}
                              // placeholder="GSTIN"
                              style={{
                                width: "110%",
                                padding: "8px",
                                margin: "8px 0",

                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            Legal Name{" "}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                            <input
                              type="text"
                              name="shiplegalName"
                              value={formValues.shiplegalName}
                              onChange={handleInputChange}
                              // placeholder="Legal Name"
                              style={{
                                width: "100%",
                                padding: "8px",
                                margin: "8px 0",
                                borderColor: errors.shiplegalName ? "red" : "black",
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                          {errors.shiplegalName && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shiplegalName}
              </span>
            )}
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            Address 1{" "}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                            <input
                              type="text"
                              name="shipaddress1"
                              value={formValues.shipaddress1}
                              onChange={handleInputChange}
                              // placeholder="Address 1"
                              style={{
                                width: "100%",
                                padding: "8px",
                                margin: "8px 0",
                                borderColor: errors.shipaddress1 ? "red" : "black",
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                          {errors.shipaddress1 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shipaddress1}
              </span>
            )}
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            Address 2
                            <input
                              type="text"
                              name="shipaddress2"
                              value={formValues.shipaddress2}
                              onChange={handleInputChange}
                              placeholder="Address 2"
                              style={{
                                width: "105%",
                                padding: "8px",
                                margin: "8px 0",

                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            Location{" "}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                            <input
                              type="text"
                              name="shiplocation"
                              value={formValues.shiplocation}
                              onChange={handleInputChange}
                              // placeholder="Location"
                              style={{
                                width: "105%",
                                padding: "8px",
                                margin: "8px 0",
                                borderColor: errors.shiplocation ? "red" : "black",
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                           {errors.shiplocation && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shiplocation}
              </span>
            )}
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            Pincode{" "}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                            <input
                              type="text"
                              name="shippincode"
                              value={formValues.shippincode}
                              // onChange={handleInputChange}
                              onChange={(e) => {
                                const value = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 6); // Limit to 6 digits
                                handleInputChange({
                                  target: { name: "shippincode", value },
                                });
                              }}
                              // placeholder="Pincode"
                              maxLength="6"
                              style={{
                                width: "105%",
                                padding: "8px",
                                margin: "8px 0",
                                borderColor: errors.shippincode ? "red" : "black",
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            />
                          </label>
                          {errors.shippincode && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shippincode}
              </span>
            )}
                        </div>
                        <div style={{ flex: "1 1 45%" }}>
                          <label>
                            State{" "}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                            <select
                              name="shipstate"
                              value={formValues.shipstate}
                              onChange={handleInputChange}
                              style={{
                                width: "93%",
                                padding: "8px",
                                margin: "8px 0",
                                borderColor: errors.shipstate ? "red" : "black",
                                borderWidth: "2px",
                                borderStyle: "solid",
                              }}
                            >
                              <option value="">Select State</option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                              <option value="Other Territory">
                                Other Country
                              </option>
                            </select>
                            {errors.shipstate && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.shipstate}
              </span>
            )}
                          </label>
               
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </DialogContent>
              <div style={{ display: "flex", padding: "16px" }}>
                <Button
                  onClick={() => {
                    const newErrors = {};

                    // Validate the required dispatch fields
                    if (!formValues.dispatchlegalName) {
                      newErrors.dispatchlegalName = "Legal Name is required";
                    }
                    if (!formValues.dispatchlocation) {
                      newErrors.dispatchlocation = "Location is required";
                    }
                    if (!formValues.dispatchpincode) {
                      newErrors.dispatchpincode = "Pincode is required";
                    } else if (!/^\d{6}$/.test(formValues.dispatchpincode)) {
                      newErrors.dispatchpincode =
                        "Pincode must be 6 numeric characters";
                    }
                    if (!formValues.dispatchaddress1) {
                      newErrors.dispatchaddress1 = "Address 1 is required";
                    }
                    if (!formValues.dispatchstate) {
                      newErrors.dispatchstate = "State is required";
                    }

                    // Validate the required ship fields
                    if (!formValues.shipgstin) {
                      newErrors.shipgstin = "GSTIN is required";
                    }
                    if (!formValues.shiplegalName) {
                      newErrors.shiplegalName = "Legal Name is required";
                    }
                    if (!formValues.shipaddress1) {
                      newErrors.shipaddress1 = "Address 1 is required";
                    }
                    if (!formValues.shiplocation) {
                      newErrors.shiplocation = "Location is required";
                    }
                    if (!formValues.shippincode) {
                      newErrors.shippincode = "Pincode is required";
                    } else if (!/^\d{6}$/.test(formValues.shippincode)) {
                      newErrors.shippincode =
                        "Pincode must be 6 numeric characters";
                    }
                    if (!formValues.shipstate) {
                      newErrors.shipstate = "State is required";
                    }

                    if (Object.keys(newErrors).length > 0) {
                      setErrors(newErrors);
                      setSnackbarMessage(
                        "Please fill out all mandatory fields."
                      );
                      setSnackbarOpen(true);
                      setSnackbarSeverity("error");
                    } else {
                      // If no errors, proceed with saving and closing the dialog
                      setopenDialogcomb(false);
                      // Your save logic here
                    }
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Save
                </Button>

                <Button
                  onClick={() =>{
                    setErrors({});
                    ResettheCombination()
                     setopenDialogcomb(false)}}

                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
                {/* <Button
          onClick={() => setopenDialogcomb(false)}
          variant="outlined"
          color="secondary"
        >
          Save
        </Button> */}
              </div>
            </Dialog>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Document Number{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  name="documentNumber"
                  value={formValues.documentNumber}
                  onChange={handleInputChange}
                  style={{
                    width: "102%",
                    padding: "8px",
                    margin: "5px 0",
                    borderColor: errors.documentNumber ? "red" : "initial",
                  }}
                />
                {errors.documentNumber && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.documentNumber}
                  </div>
                )}
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Pincode{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  type="text"
                  disabled
                  name="pincode"
                  value={formValues.pincode}
                  onChange={handleInputChange}
                  style={{
                    width: "135%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.pincode ? "red" : "initial",
                  }}
                />
                {errors.pincode && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.pincode}
                  </div>
                )}
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Email
                <input
                  type="email"
                  disabled
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  style={{ width: "155%", padding: "8px", margin: "8px 0" }}
                />
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Document Date{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <ValidatedDatePicker
                  name="documentDate"
                  value={formValues.documentDate}
                  onChange={(date) =>
                    handleInputChange({
                      target: { name: "documentDate", value: date },
                    })
                  }
                  minDateForDatepicker={new Date(1900, 0, 1)}
                  // error={!!errors.documentDate}
                  // helperText={errors.documentDate}
                />
              </label>
              {errors.documentDate && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px",fontWeight:"bold" }}
                  >
                    {errors.documentDate}
                  </div>
                )}
            </div>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Phone No
                <input
                  type="text"
                  disabled
                  name="phoneNo"
                  value={formValues.phoneNo}
                  onChange={handleInputChange}
                  style={{ width: "140%", padding: "8px", margin: "8px 0" }}
                />
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Place of Supply{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <select
                  name="placeOfSupply"
                  value={formValues.placeOfSupply}
                  onChange={handleInputChange}
                  style={{
                    width: "95%",
                    padding: "8px",
                    margin: "8px 0",
                    borderColor: errors.placeOfSupply ? "red" : "initial",
                  }}
                >
                  <option value="" disabled hidden></option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.placeOfSupply && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  >
                    {errors.placeOfSupply}
                  </div>
                )}
              </label>
            </div>
            <div style={{ flex: "1 1 30%" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#3e5b9e",
              borderRadius: "3px",
            }}
          >
            <p
              style={{ fontSize: "17px", color: "white", paddingLeft: "30px" }}
            >
              Transaction Details
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                IGST on Intra
                <select
                  name="igstOnIntra"
                  value={formValues.igstOnIntra}
                  onChange={handleInputChange}
                  style={{ width: "190%", padding: "8px", margin: "8px 0" }}
                >
                  <option value="" disabled hidden></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
            </div>

            <div style={{ flex: "1 1 30%" }}>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Transaction Type
                <select
                  name="transactionType"
                  value={formValues.transactionType}
                  onChange={handleInputChange}
                  style={{ width: "105%", padding: "8px", margin: "8px 0" }}
                >
                  <option value="" disabled hidden></option>
                  <option value="Regular">Regular</option>
                  <option value="Bill From - Dispatch From">
                  Bill From - Dispatch From
                  </option>
                  <option value="Bill To - Ship To">Bill To - Ship To</option>
                  <option value="Combination 2 & 3">Combination 2 & 3</option>
                </select>
              </label>
            </div>
            <div style={{ flex: "1 1 30%" }}></div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
            }}
          >
            <Tooltip
              title={
                !isButtonEnabled ? "Please fill in all mandatory fields." : ""
              }
              arrow
            >
              <span>
                <button
                  style={{
                    fontWeight: "bold",
                    height: "30px",
                    backgroundColor: "#1976d2",
                    width: "130px",
                    border: "none",
                    color: "white",
                    cursor: isButtonEnabled ? "pointer" : "not-allowed",
                    opacity: isButtonEnabled ? 1 : 0.5,
                    pointerEvents: isButtonEnabled ? "auto" : "none",
                    marginRight: "18px",
                  }}
                  onClick={handleOpenDialog}
                  disabled={!isButtonEnabled}
                >
                  ADD NEW ITEM
                </button>
              </span>
            </Tooltip>

            <button
              style={{
                fontWeight: "bold",
                height: "30px",
                backgroundColor: "#d04646",
                width: "100px",
                border: "none",
                color: "white",
                marginRight: "10px",
              }}
              onClick={deleteSelectedRows}
            >
              DELETE
            </button>
            <button
              style={{
                fontWeight: "bold",
                height: "30px",
                backgroundColor: "green",
                width: "90px",
                border: "none",
                color: "white",
              }}
              onClick={handleClearAll}
            >
              CLEAR ALL
            </button>
          </div>

          <Grid item xs={12} md={3}>
            {/* <button  onClick={deleteSelectedRows}>Delete Selected</button> */}
            <div style={style1}>
              <table style={tableStyles}>
                <thead>
                  <tr>
                    <th style={tdThStyles}>
                      {" "}
                      <input
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={handleSelectAllChange}
                      />
                      Select
                    </th>
                    <th style={tdThStyles}>Sl</th>
                    <th style={tdThStyles}>Product Description</th>
                    <th style={tdThStyles}>HSN/SAC CODE</th>
                    <th style={tdThStyles}>Quantity</th>
                    <th style={tdThStyles}>Unit Price</th>
                    <th style={tdThStyles}>Discount</th>
                    <th style={tdThStyles}>Taxable Value</th>
                    <th style={tdThStyles}>GST Rate</th>
                    <th style={tdThStyles}>IGST</th>
                    <th style={tdThStyles}>CGST</th>
                    <th style={tdThStyles}>SGST</th>
                    <th style={tdThStyles}>TOTAL Cess</th>
                    <th style={tdThStyles}>Other Charges</th>
                    <th style={tdThStyles}>Total Items</th>
                    <th style={tdThStyles}>Action</th>
                    <th style={tdThStyles}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <TableRow
                      key={index}
                      style={index % 2 === 0 ? {} : trEvenStyles}
                    >
                      <TableCell style={tdThStyles}>
                        <input
                          type="checkbox"
                          checked={selectedRows.has(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </TableCell>
                      <TableCell style={tdThStyles}>{product.slNo}</TableCell>
                      <TableCell style={tdThStyles}>
                        {product.product_det_product_description}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.product_det_hsn_sac}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.inv_det_quantity}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.product_det_unit_price}
                      </TableCell>
                     
                      <TableCell style={tdThStyles}>
                       
                        <input
                          type="number"
                          value={product.amount_det_discount}
                          onChange={(e) =>
                            handleDiscountInputChange(e, product.id)
                          }
                          style={{
                            width: "100%",
                            border: "1px solid #ccc",
                            padding: "5px",
                          }}
                        />
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_taxable_value}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_gst_rate}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_igst_value}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_cgst_value}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_sgst_value}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_cess_rate}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_other_charges}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        {product.amount_det_total_item_amount}
                      </TableCell>
                      <TableCell style={tdThStyles}>
                        <IconButton onClick={() => handleEditCli(product)}>
                          <EditIcon
                            style={{ cursor: "pointer" }} // Inline style for cursor
                            sx={{
                              "&:hover": {
                                color: "blue", // Change to your desired hover color
                              },
                            }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        style={{ padding: "3px", paddingLeft: "15px" }}
                      >
                        <IconButton onClick={() => handleViewCli(product)}>
                          <Visibility />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <form onSubmit={handleSubmit}>
              <div style={style1}>
                <table
                  style={{
                    fontFamily: "Arial, sans-serif",
                    borderCollapse: "collapse",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Taxable Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total IGST Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total CGST Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total SGST Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Cess AD Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total Cess Non-AD Value
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total State Cess Amount
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Total of Items
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalTaxableValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalIgstValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalCgstValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalSgstValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalCessAdValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalCessNonAdValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalStateCessAmount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {totalAmount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="9" style={{ padding: "8px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            marginTop: "10px",
                            paddingLeft: "25px",
                          }}
                        >
                          <label>
                            Discount:
                            <input
                              type="text"
                              value={discount}
                              disabled={freeze}
                              onChange={handleDiscountChange}
                              style={{
                                paddingLeft: "5px",
                                width: "120px",
                                marginLeft: "5px",
                              }}
                            />
                          </label>
                          <label>
                            Round off:
                            <input
                              type="number"
                              value={roundOff}
                              onChange={handleRoundOffChange}
                              style={{
                                padding: "5px",
                                width: "120px",
                                marginLeft: "5px",
                              }}
                            />
                          </label>
                          <label>
                            Grand Total:
                            <input
                              value={grandTotal.toFixed(2)}
                              readOnly
                              style={{
                                padding: "5px",
                                width: "120px",
                                marginLeft: "5px",
                              }}
                            />
                          </label>
                          <button
                            type="submit"
                            style={{
                              fontWeight: "bold",
                              height: "30px",
                              backgroundColor: "#1976d2",
                              width: "120px",
                              border: "none",
                              color: "white",
                              marginRight: "1px",
                            }}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </Grid>

          
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>New Product</DialogTitle>
            <DialogContent>
              <NewProduct
                open={openDialog}
                onClose={handleCloseDialog}
                onSave={(product) => handleSaveProduct(product)}
                selectedProduct={selectedProduct}
                additionalValue={additionalValue}
              />
            </DialogContent>
          </Dialog>
        </BoxWrapper>
      )}
      <BoxWrapper
        boxTitle="Tax Invoice Details"
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchChange}
        exportToExcelEnable={true}
        exportToExcelFn={handleExportExcel}
      >
        <div
          style={{
            maxHeight: "400px",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <Table
            sx={{
              tableLayout: "auto",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  GSTIN
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  LEGAL NAME
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  DOCUMENT TYPE
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  DOCUMENT NUMBER
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  DOCUMENT DATE
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  TOTAL AMOUNT
                </TableCell>

                <TableCell
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0 ? (
                rows.map((record, index) => (
                  <TableRow
                    key={index}
                  
                  >
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.inv_det_gstin}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.inv_det_legal_name}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.inv_det_document_type}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.inv_det_document_number}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.inv_det_document_date}
                    </TableCell>
                    <TableCell style={{ padding: "3px", paddingLeft: "15px" }}>
                      {record.amount_det_total_item_amount}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "3px",
                        paddingLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        title={
                          record.disable !== null
                            ? `Disabled: ${record.disable}`
                            : ""
                        }
                        arrow 
                      >
                        <span>
                          <IconButton
                            onClick={() => handleEditClick(record)}
                            disabled={record.disable !== null} 
                          >
                            <EditIcon
                              style={{
                                cursor:
                                  record.disable !== null
                                    ? "not-allowed"
                                    : "pointer",
                              }} 
                              sx={{
                                "&:hover": {
                                  color:
                                    record.disable === null
                                      ? "blue"
                                      : "inherit", 
                                },
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                        onClick={() => handleDeleteClick(record)}
                        disabled={record.disable !== null}
                      >
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          sx={{ "&:hover": { color: "red" } }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handledownload(record)}>
                        <DownloadIcon
                          style={{ cursor: "pointer" }}
                          sx={{ "&:hover": { color: "blue" } }}
                        />
                      </IconButton>
                    </TableCell>
                 
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </BoxWrapper>
      {/* Confirmation Dialog */}
      <Dialog open={dialog.open} onClose={handlCl}>
        <DialogTitle> Are you sure you want to delete this record?</DialogTitle>

        <DialogActions>
          <Button onClick={handlCl} color="primary">
            No
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100,1000,3000]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
          refresh(newPage, rowsPerPage, searchTerm); 
        }}
        onRowsPerPageChange={(event) => {
          const newRowsPerPage = parseInt(event.target.value);
          setRowsPerPage(newRowsPerPage);
          setPage(0); 
          refresh(0, newRowsPerPage, searchTerm); 
        }}
      />
    </div>
  );
}

export default TaxInvoice;
