import React, { useState, useRef } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // Import green arrow
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Import red arrow
import { Payment, AccountBalance, TrendingUp, Assessment, CreditCard, Savings, ListAlt, AccountBalanceWallet, MoneyOff } from '@mui/icons-material';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const financeIcons = [
    <CurrencyRupeeIcon style={{ color: '#4caf50' }} />,
    <CurrencyRupeeIcon style={{ color: '#ff9800' }} />,
    <Payment style={{ color: '#2196f3' }} />,
    <AccountBalance style={{ color: '#673ab7' }} />,
    <TrendingUp style={{ color: '#ff5722' }} />,
    <Assessment style={{ color: '#00bcd4' }} />,
    <CreditCard style={{ color: '#9c27b0' }} />,
    <Savings style={{ color: '#8bc34a' }} />,
    <ListAlt style={{ color: '#ffc107' }} />,
    <AccountBalanceWallet style={{ color: '#3f51b5' }} />,
    <MoneyOff style={{ color: '#e91e63' }} />,
    <FeaturedVideoIcon style={{ color: '#ff5722' }} />,
];

const formatCurrency = (value) => {
    return `₹ ${value.toLocaleString('en-IN')}`;
};
const DashboardCards = ({ dashboardData, report_type, onCardClick }) => {
    const [clickedCard, setClickedCard] = useState(null);
    const endRef = useRef(null);

    const handleCardClick = (cardIndex) => {
        setClickedCard(cardIndex);
        onCardClick();
        
    };
    return (
        <>
            {Object.keys(dashboardData).map((key, index) => {
                // Parse the value from string to number (remove currency symbols and commas)
                const rawValue = dashboardData[key];
                const value = typeof rawValue === 'string'
                    ? formatCurrency(rawValue) // Convert to number
                    : formatCurrency(rawValue);
                // Determine if the value is positive or negative for the arrow
                const isPositive = !isNaN(value) && value > 0; // Change to > 0 to avoid showing arrow for 0

                const cardStyle = clickedCard === index
                    ? {
                        boxShadow: '0 4px 20px rgba(173, 216, 230, 0.6)', // Light blue shadow
                        transition: 'box-shadow 0.3s ease-in-out', // Smooth animation
                    }
                    : {};

                return (    
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: '#ffffff',
                                borderRadius: '15px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                height: '100%',
                                width: '100%',
                                maxWidth: '300px', // Limit maximum width to avoid overflow
                                textAlign: 'left',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                transform: clickedCard === index ? 'scale(1.05)' : 'scale(1)',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                                },
                                ...cardStyle,
                            }}
                            onClick={() => handleCardClick(index)}
                        >
                            {/* Icon section */}
                            <div style={{
                                backgroundColor: '#E3F2FD',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                minWidth: '40px', // Ensure icon section has minimum width
                                minHeight: '40px', // Ensure icon section has minimum height
                            }}>
                                {financeIcons[index % financeIcons.length]}
                            </div>

                            {/* Text section */}
                            <div style={{ flexGrow: 1 }}>
                                {/* Card Title */}
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 700,
                                        color: '#3B5998',
                                        marginBottom: '5px',
                                        textTransform: 'capitalize',
                                        fontFamily: `'Poppins', sans-serif`,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        ...(report_type === "C2C" && {
                                            fontWeight: 700,
                                            color: "#795548",
                                            // textAlign: 'center',
                                            fontFamily: "'Courier New', monospace"
                                        }),

                                    }}
                                >
                                    {key.replace(/_/g, ' ')}
                                </Typography>

                                {/* Card Value and Arrow */}
                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 550,
                                            color: '#1A202C',
                                            fontFamily: `'Nunito', sans-serif`,
                                            marginRight: '8px', // Space between value and arrow
                                            whiteSpace: 'nowrap', // Prevent wrapping
                                            ...(report_type === "C2C" && {
                                                fontWeight: 700,
                                                // color: "#795548",
                                                // textAlign: 'center',
                                                fontFamily: "'Courier New', monospace"
                                            }),

                                        }}
                                    >
                                        {value !== undefined ? (key.startsWith('#') ? `${rawValue}` : value) : rawValue}
                                    </Typography>
                                    {/* Only display arrow if value is greater than 0 */}
                                    {isPositive && value > 0 && !key.startsWith('#') ? (
                                        <ArrowUpwardIcon style={{ color: 'green', fontSize: '16px' }} /> // Green arrow for positive, smaller size
                                    ) : value < 0 && !key.startsWith('#') ? (
                                        <ArrowDownwardIcon style={{ color: 'red', fontSize: '16px' }} /> // Red arrow for negative, smaller size
                                    ) : null}
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                );
            })}
        </>
    );
};

export default DashboardCards;
