import React from 'react';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ResponsiveContainer } from 'recharts';

// Function to format the values in Indian Rupees
const formatCurrency = (value) => {
    return `₹${value.toLocaleString('en-IN')}`;
};

// Function to truncate long names for the X-axis
const formatLabel = (label) => {
    if (label.length > 20) {
        return `${label.substring(0, 20)}...`;
    }
    return label;
};

const Top5CustomerChart = ({ top5CustomerData, selectedReportTypeForTop5, onChngeTop5CustomersChart, singleReport }) => {
    const chartData = top5CustomerData.map(item => ({
        name: selectedReportTypeForTop5 === "ageing_recivable" ? item.Name : item.Name,
        y: item.Amount,
        erp_cust_code: item.erp_cust_code
    }));

    const handleBarClick = (event) => {
        const clickedCustomer = event.point.erp_cust_code;
        const clickedAmount = event.point.y;
        console.log("handleBarClick...........", event.point, event.point.erp_cust_code)
        // Trigger the passed in function to handle the click, passing the relevant data
        onChngeTop5CustomersChart(clickedCustomer, clickedAmount);
    };

    const options = {
        chart: {
            type: 'column',

            borderRadius: 15,
            spacingBottom: 30, // Extra space below the chart for readability
        },
        title: {
            text: 'Top 5 Customers by Outstanding Amount',
            style: {
                fontSize: '18px',
                fontWeight: '700',
                color: '#2e3b5d', // Darker blue for contrast
            },
        },
        subtitle: {
            text: 'Visualizing the Top 5 Customers with the Highest Outstanding Amounts',
            style: {
                fontSize: '14px',
                color: '#6c757d', // Lighter subtitle color
            },
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.y}</b>',
            formatter: function () {
                return `${this.point.name}: <b>${formatCurrency(this.point.y)}</b>`;
            },
        },
        xAxis: {
            categories: chartData.map((item) => item.name),
            title: {
                text: 'Customer',
            },
            labels: {
                formatter: function () {
                    return formatLabel(this.value);
                },
                style: {
                    color: '#4a4a4a', // Dark gray for x-axis labels for better readability
                },
            },
            lineColor: '#dcdfe6',
            tickColor: '#dcdfe6',
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Amount (INR)',
            },
            labels: {
                formatter: function () {
                    return formatCurrency(this.value);
                },
                style: {
                    color: '#4a4a4a', // Dark gray for y-axis labels
                },
            },
            gridLineColor: '#dcdfe6',
        },
        plotOptions: {
            column: {
                cursor: 'pointer',
                pointWidth: 45, // Wider bars for better visibility
                borderRadius: 4,
                borderWidth: 1,
                color: '#007bff', // Modern blue color for the bars
                shadow: true,
                groupPadding: 0.1, // Add padding between groups of bars
                pointPadding: 0.2,
                events: {
                    click: handleBarClick,
                },
            },
        },
        series: [
            {
                name: 'Outstanding Amount',
                data: chartData.map((item) => ({
                    name: item.name, // Add 'name' here to ensure the tooltip works
                    y: item.y,
                    erp_cust_code: item.erp_cust_code
                })),
                color: '#007bff', // Matching the bar color to the series
                borderRadius: 8,
                borderWidth: 1,
            },
        ],
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        legend: {
            itemStyle: {
                color: '#2e3b5d', // Dark color for legend text
            },
        },
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                padding: '16px',
                borderRadius: '8px'
            }}>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <HighchartsReact highcharts={Highcharts} options={options} />
            {/* </ResponsiveContainer> */}
        </div>

    );
};

export default Top5CustomerChart;
