import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsAccessibility(Highcharts);
}

const overdueGraphData = [
  {
    name: '0-30 Days',
    y: 25,
    due_amount: 5000
  },
  {
    name: '31-60 Days',
    y: 30,
    due_amount: 6000
  },
  {
    name: '61-90 Days',
    y: 20,
    due_amount: 4000
  },
  {
    name: '91+ Days',
    y: 25,
    due_amount: 5000
  }
];


const PieChart = ({ graphData, id, title, onClick }) => {

  const formatCurrency = (amount) => {
    return '₹' + amount.toLocaleString('en-IN'); // Format as INR currency
  };

  useEffect(() => {



    (function (H) {
      H.seriesTypes.pie.prototype.animate = function (init) {
        const series = this;
        const points = series.points;
        const { animation } = series.options;
        const { startAngleRad } = series;

        function fanAnimate(point, startAngleRad) {
          const graphic = point.graphic;
          const args = point.shapeArgs;

          // Proceed only if the graphic and shapeArgs exist
          if (graphic && args) {
            graphic
              .attr({
                start: startAngleRad,
                end: startAngleRad,
                opacity: 1,
              })
              .animate({
                start: args.start,
                end: args.end,
              }, {
                duration: animation.duration / points.length,
              }, function () {
                // Continue animation for subsequent points if they exist
                if (points[point.index + 1]) {
                  fanAnimate(points[point.index + 1], args.end);
                }
                // After the last point, make the data labels visible
                if (point.index === points.length - 1) {
                  series.dataLabelsGroup.animate({
                    opacity: 1,
                  }, void 0, function () {
                    points.forEach(p => {
                      p.opacity = 1;
                    });
                    series.update({
                      enableMouseTracking: true,
                    }, false);
                    series.chart.update({
                      plotOptions: {
                        pie: {
                          innerSize: '40%',
                          borderRadius: 8,
                        },
                      },
                    });
                  });
                }
              });
          }
        }

        // Delay animation start until chart is initialized
        if (init) {
          points.forEach(point => {
            point.opacity = 0;
          });
        } else {
          // Check if points have graphic before animating
          if (points[0].graphic) {
            fanAnimate(points[0], startAngleRad);
          } else {
            // If graphic not yet available, skip animation
            points.forEach(point => {
              point.opacity = 1;
            });
          }
        }
      };
    })(Highcharts);

    // Initialize the chart after the DOM is ready
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        borderRadius: 15,
        height: '100%', // This will make the chart responsive
      },
      title: {
        text: title,
        align: 'center',
        style: {
          fontWeight: 'bold',
        },
      },
      subtitle: {
        text: title === 'Overdue Ageing Summary'
          ? 'Visualizing overdue amounts distributed across customers'
          : 'Visualizing amounts that are not yet due across customers',
        style: {
          fontWeight: 'normal',
          fontSize: '14px',
          color: '#6c757d',
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">\u25cf</span> ' +
          '{point.name}: <b>{point.percentage:.1f}%</b><br>' +
          `${title === 'Overdue Ageing Summary' ? 'Overdue Amount' : 'Not Yet Due Amount'}: <b>{point.due_amount}</b>`,
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          borderWidth: 2,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.2f}%',
            distance: 20,
          },
          showInLegend: true,
          point: {
            events: {
              click: function () {
                const { name, percentage, due_amount } = this;
                if (onClick) {
                  onClick({ name, percentage, due_amount, "filter_type": title });
                }
              }
            }
          }
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        maxWidth: '100%',
        itemWidth: 120,
        itemMarginTop: 7,
        itemMarginBottom: 5,
        useHTML: true,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [{
        enableMouseTracking: false,
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        data: graphData.map(item => ({
          ...item,
          due_amount: formatCurrency(item.due_amount),
        })),
      }],
    });
  }, [graphData, id]);

  return (
    <div id={id} style={{ borderRadius: '16px' }}></div>
  );
};

export default PieChart;
