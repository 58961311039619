import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import { useLocation } from "react-router-dom";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';


function HsnSummaryDetails() {
  const location = useLocation();
  const [selectedReportBy, setSelectedReportBy] = useState("hsn/sac_wise");
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpand = (month) => {
    setExpandedRows((prev) => ({
      ...prev,
      [month]: !prev[month],
    }));
  };

  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.month]) {
        acc[item.month] = [];
      }
      acc[item.month].push(item);
      return acc;
    }, {});
  };

  const groupedData = summaryDetails?.data?.hsn_comparison ? groupByMonth(summaryDetails.data.hsn_comparison) : {};


  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const handleExportExcel = () => {
    axios.post(`/hsn_summary_excel`,{report_id:reportData})
    .then((response)=>{
      const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const base64Data = response.data.file;
    if (base64Data) {
      downloadBase64File(contentType, base64Data, "HSNSummaryReport.xlsx");
    } else {
      console.error("Base64 data is missing in the response");
    }
    })
    .catch((error)=>{
      console.log(error)
    })
  };

  useEffect(() => {
    const item = location.state?.items || {};
    setReportData(item);
    console.log("Passed item:", item);
  }, [location]);

  useEffect(() => {
    if (reportData) {
      setLoading(true);
      axios
        .get(`/summary_fetched_from_hsn/${reportData}`)
        .then((response) => {
          setSummaryDetails(response.data);
          
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching API data:", err);
          setError("Failed to fetch data");
          setLoading(false);
        });
    }
  }, [reportData]);


  const numberFormat = (value) => {
       
    if (value === "(Blank)" || value === null) {
        return "₹ 0";
      }
  
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  };

  

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "14%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            HSN SUMMARY REPORT{" "}
            <SummarizeIcon style={{ marginBottom: "4px", color: "#043010" }} />
          </Typography>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            style={{ textAlign: "center", marginBottom: "2%" }}
          ></Typography>
        </div>
        <FormControl
          style={{ flex: "1", minWidth: 250, maxWidth: "20%" }}
          variant="outlined"
        >
          <InputLabel>Report By</InputLabel>
          <Select
            value={selectedReportBy}
            onChange={(e) => setSelectedReportBy(e.target.value)}
            label="Report By"
            fullWidth
            style={{ height: "44px" }}
          >
            <MenuItem value="hsn/sac_wise">HSN/SAC Wise Report</MenuItem>
            <MenuItem value="hsn/comparison">HSN Comparison Report</MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={handleExportExcel}
          variant="contained"
          color="primary"
          style={{ marginLeft: "2%" }}
          endIcon={<FileDownloadIcon />}
        >
          Export
        </Button>
      </div>
      <BoxWrapper
  boxTitle={selectedReportBy === "hsn/sac_wise" ? "HSN/SAC Wise Report" : "HSN Comparison Report"}
>
  {loading ? (
    <tr>
      <td
        colSpan={5}
        style={{
          color: "black",
          textAlign: "center",
          display:"flex",
          alignItems:"center",
          fontWeight: "bold",
          padding: "20px",
          fontSize: "16px",
        }}
      >
        Loading… Please wait
      </td>
    </tr>
  ) : error ? (
    <p>{error}</p>
  ) : !summaryDetails || !summaryDetails.data ? (
    <p>No data available</p>
  ) : selectedReportBy === "hsn/sac_wise" && summaryDetails.data.hsn_processed ? (
    <div className="responsive-table">
      <table className="custome-table">
        <thead>
          <tr>
            <th style={{fontSize: "15px"}}>HSN Code</th>
            <th style={{fontSize: "15px"}}>Rate</th>
            <th style={{fontSize: "15px"}}>UQC</th>
            <th style={{fontSize: "15px"}}>Quantity</th>
            <th style={{fontSize: "15px",whiteSpace:'nowrap'}}>TaxableValue</th>
            <th style={{fontSize: "15px"}}>SGST</th>
            <th style={{fontSize: "15px"}}>CGST</th>
            <th style={{fontSize: "15px"}}>IGST</th>
            <th style={{fontSize: "15px"}}>Cess</th>
            <th style={{fontSize: "15px"}}>FP</th>
          </tr>
        </thead>
        <tbody>
          {summaryDetails.data.hsn_processed.map((item, index) => (
            <tr key={index}>
              <td>{item.hsn_code}</td>
              <td>{item.rate}</td>
              <td>{item.uqc}</td>
              <td>{item.quantity}</td>
              <td>{numberFormat(item.taxable_value)}</td>
              <td>{numberFormat(item.sgst)}</td>
              <td>{numberFormat(item.cgst)}</td>
              <td>{numberFormat(item.igst)}</td>
              <td>{numberFormat(item.cess)}</td>
              <td>{item.fp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : selectedReportBy === "hsn/comparison" && summaryDetails.data.hsn_comparison ? (
    <div className="responsive-table">
      <table className="summary-tab-single-year-table-css" style={{ tableLayout: 'auto', width: '120%' }}>
        <thead>
        <tr>
          <th colSpan={1}  style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#d6712d'}} >Filing</th>
            <th colSpan={8} style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#3065d1'}} 
            >AS PER BOOKS</th>
          <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#1ed457'}} 
          >AS PER GSTR1</th>
     
                      </tr>
          <tr>
            
            <th></th>
            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",  }}>Month</th>

            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",}}>HSN Code</th>
            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap'}}> Quantity</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap'}}> CGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> SGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> IGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> CESS</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap',whiteSpace:'nowrap' }}>Taxable Value</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>HSN Code</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>Quantity</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>CGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>SGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>IGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' , paddingRight: "4px"}}>CESS</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap',whiteSpace:'nowrap' }}>Taxable Value</th>
      
          </tr>
        </thead>
        <tbody>
        
          {Object.keys(groupedData).map((month) => {
            const monthData = groupedData[month];
            const firstData = monthData[0];

            return (
              <React.Fragment key={month}>
                <tr>
                  <td>
                    <button onClick={() => toggleExpand(month)}
                      style={{
                        border: 'none', 
                        background: 'none', 
                        cursor: 'pointer', 
                      }}
                      >
                   
                      {expandedRows[month] ? (
                              <RemoveIcon style={{ color: '#c20a4a' }}  />
                            ) : (
                              <AddIcon style={{ color: '#172bb0' }} />
                            )}
                    </button>
                  </td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{firstData.month}</td>

                  <td  style={{ padding: "3px", paddingLeft: "15px" }}>{firstData.books_hsn_code}</td>
                  <td  style={{ padding: "3px", paddingLeft: "15px" }}>{firstData.books_total_quantity}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.books_total_cgst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.books_total_sgst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.books_total_igst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.books_total_cess)}</td>
                  <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(firstData.books_taxable_value)}</td>
                  <td  style={{ padding: "3px", paddingLeft: "15px" }} >{firstData.hsn_code}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{firstData.quantity}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.cgst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.sgst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(firstData.igst)}</td>
                  <td style={{ padding: "3px", paddingLeft: "15px",whiteSpace:'nowrap' }} >{numberFormat(firstData.cess)}</td>
                  <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(firstData.taxable_value)}</td>
          
                </tr>
                {expandedRows[month] &&
                  monthData.slice(1).map((item, index) => (
                    <tr key={`${month}-${index}`}>
                      <td></td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{item.month}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{item.books_hsn_code}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{item.books_total_quantity}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.books_total_cgst)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.books_total_sgst)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.books_total_igst)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.books_total_cess)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.books_taxable_value)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{item.hsn_code}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{item.quantity}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.cgst)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.sgst)}</td>
                      <td style={{ padding: "3px", paddingLeft: "15px" }} >{numberFormat(item.igst)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px",whiteSpace:'nowrap' }}>{numberFormat(item.cess)}</td>
                      <td  style={{ padding: "3px", paddingLeft: "15px" }}>{numberFormat(item.taxable_value)}</td>
                    
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <p>No data available</p>
  )}
</BoxWrapper>

    </Container>
  );
}

export default HsnSummaryDetails;

