import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";
import axios from "../../axios-order";
import Tooltip from "@material-ui/core/Tooltip";
import { NavLink } from "react-router-dom";
import cws_logo from "../../asserts/images/cws logo.jpg";
import "./layout.css";
import { toast } from "react-toastify";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Modal from "react-bootstrap/Modal";


import MuiTreeView from "material-ui-treeview";
import Button from "@material-ui/core/Button";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
// import ExpandCircleUpIcon from '@mui/icons-material/ExpandCircleUp'
import PanDashBoard from "./PanDashBoard/PanRouter";
import { Icon } from "@material-ui/core";


import {

  TextField,
  InputAdornment,
  withStyles
} from "@material-ui/core";

import SearchIcon from "@mui/icons-material/Search";
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Dosis",
    overflowX: 'auto',

  },
  primary: {
    fontFamily: "Dosis",
  },
  listStyleOnHover: {
    color: "#bfbfbf",
    "&:hover": {
      color: "#fff",
    },
  },

  menuText: {
    color: "#bfbfbf",
    fontWeight: "200px",
    fontFamily: "Dosis"
  },

  menuText1: {
    color: "#bfbfbf",
    fontWeight: "200px",
    paddingLeft: "14px",
  },

  subMenu: {
    marginLeft: "15px",
  },

  listItemText: {
    fontFamily: "Dosis",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "1px",
  },
  paddingOnList: {
    paddingLeft: "16px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#3B3B6B",
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    // padding: theme.spacing(2, 2),
    // background: "#fafafa",

    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  drawerHeaderSpace: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(7, 0),
    // background: "#fafafa",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  listStyle: {
    overflowY: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const activeLink = {
  fontWeight: "bold",
  color: "#fff",
  // background: "rgba(241,222,250,0.275)",
  borderLeft: "6px solid #fff",
};

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "7px 5px",
    },
    "& label.Mui-focused": {
      color: "#666666",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#737373",
      },
      "&:hover fieldset": {
        borderColor: "#595959",
      },
    },
  },
})(TextField);

// ---layout component---
export default function PersistentDrawerLeft(props) {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [footerStyle, setFooterStyle] = React.useState("footer-1");
  const [sitemapData, setSitemapData] = React.useState();

  const [ID_service, setID_service] = React.useState();
  const [IC_service, setIC_service] = React.useState();

  const [openListIdBl, setOpenListIdBl] = React.useState(false);
  const [openListARCandStandard, setOpenListARCandStandard] =
    React.useState(false);
  const [openListCashOpt, setOpenListCashOpt] = React.useState(false);
  const [openListARCUpload, setOpenListARCUpload] = React.useState(false);
  const [openEventForEINVOICE, setOpenEventForENVOICE] = React.useState(false);
  const [openEventForAIS, setOpenEventForAIS] = React.useState(false);
  const [openListARCDashboard, setOpenListARCDashboard] = React.useState(false);
  const [openListARCRecon, setOpenListARCRecon] = React.useState(false);
  const [openListIcOrigai, setOpenListIcOrigai] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState();
  const [openListARCUploadSales, setOpenListARCUploadsales] =
    React.useState(false);
  const [openListARCUploadPurchase, setOpenListARCUploadPurchase] =
    React.useState(false);
  const [
    openListARCUploadAgencyTransaction,
    setOpenListARCUploadAgencyTransaction,
  ] = React.useState(false);
  const [faqTree, setFaqTree] = React.useState([]);
  const [faqModalShow, setFaqModalShow] = React.useState(false);
  const [entitChangeModelShow, setEntitChangeModel] = React.useState(false);
  const [adminData, setadminData] = React.useState([]);

  const [openListARCDashboardSales, setOpenListARCDashboardSales] =
    React.useState(false);
  const [
    openListARCDashboardAgencyReport,
    setOpenListARCDashboardAgencyReport,
  ] = React.useState(false);
  const [openListARCDashboardPurchase, setOpenListARCDashboardPurchase] =
    React.useState(false);
  const [openListARCReconSales, setOpenListARCReconSales] =
    React.useState(false);
  const [openListARCReconPurchase, setOpenListARCReconPurchase] =
    React.useState(false);
  const [openEventForIARS, setOpenEventForIARS] = React.useState(false);
  const [entitySearchInput, setEntitySearchInput] = React.useState('')

  const [openEventForReports, setOpenEventForReports] = React.useState(false);

  const [openEventForWorkingCapitallReports, setOpenEventForWorkingCapitallReports] = React.useState(false);

  const [openEventForStatutoryReports, setOpenEventForStatutoryReports] = React.useState(false);

  const [openEventForFinancilaReports, setOpenEventForFinancilaReports] = React.useState(false);

  const [openEventForGSTR9, setOpenEventForGSTR9] = React.useState(false);

  React.useMemo(() => {
    if (open) {
      setFooterStyle("footer-1");
    } else {
      setFooterStyle("footer-2");
    }
  }, []);

  const [toggleMenu, setToggleButton] = React.useState(false);

  const getFaqTree = () => {
    axios.get("faq").then((res) => {
      setFaqTree(res.data);
    });
  };

  useEffect(() => {
    //
    // ---Current Date----
    const currentDate = new Date();
    const pad = "00";
    const yy = currentDate.getFullYear().toString();
    const mm = (currentDate.getMonth() + 1).toString();
    const dd = currentDate.getDate().toString();
    setCurrentDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);

    axios
      .get(
        `/cws_services/services_for_entity/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setSitemapData(res.data);

        // Reporting
        if (res.data.navigation_data["ARC DASHBOARD"]) {
          localStorage.setItem("showReport", "true");
        } else {
          localStorage.setItem("showReport", "false");
        }

        localStorage.setItem(
          "sitemap",
          JSON.stringify(res.data.navigation_data)
        );
      })

      .catch((error) => { });
    getFaqTree();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuId = "primary-search-account-menu";
  // ---Logout funtion---
  const logout = () => {
    toast.configure();
    axios
      .get("/logout")
      .then((res) => {
        localStorage.clear();

        history.push("/login");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  // ---Handle profile function---
  const handleProfile = (props) => {
    history.push("/personalProfile");
  };

  // ---Handle home function--
  const handleHomeButton = () => {
    localStorage.removeItem("PanNumber");
    localStorage.removeItem("business_entity_id");
    localStorage.removeItem("entity_name");
    localStorage.removeItem("gst");
    localStorage.removeItem("Client_ID");
    localStorage.setItem("TriggerPanDashBoard", "false");
    localStorage.removeItem("Trigger_Effitrac/Tally_Button");
    history.push("/home");
  };

  const handleClickFinancialReportingAndAnalytics = () => {
    setOpenEventForFinancilaReports(!openEventForFinancilaReports)
  }

  const handleClickStatutoryReportingAndAnalytics = () => {
    setOpenEventForStatutoryReports(!openEventForStatutoryReports)
  }

  const handleClickWorkingCapitalReportingAndAnalytics = () => {
    setOpenEventForWorkingCapitallReports(!openEventForWorkingCapitallReports)
  }

  const handleClickForBillionLoanList = () => {
    setOpenListIdBl(!openListIdBl);
  };

  const handleClickForARCandStandard = () => {
    setOpenListARCandStandard(!openListARCandStandard);
  };

  const handleClickForCashOpt = () => {
    setOpenListCashOpt(!openListCashOpt);
  };

  const handleClickEventForGSTR9 = () => {
    setOpenEventForGSTR9(!openEventForGSTR9)
  }

  const handleClickForIcOrigai = () => {
    setOpenListIcOrigai(!openListIcOrigai);
  };

  const handleClickForARCUpload = () => {
    setOpenListARCUpload(!openListARCUpload);
  };

  const handleClickForARCDashboard = () => {
    setOpenListARCDashboard(!openListARCDashboard);
  };

  const handleClickForARCRecon = () => {
    setOpenListARCRecon(!openListARCRecon);
  };

  const handleClickReportingAndAnalytics = () => {
    setOpenEventForReports(!openEventForReports)
  }

  const handleClickForARCUploadsales = () => {
    setOpenListARCUploadsales(!openListARCUploadSales);
  };

  const handleClickForARCUploadPurchase = () => {
    setOpenListARCUploadPurchase(!openListARCUploadPurchase);
  };

  const handleClickForARCDashboardSales = () => {
    setOpenListARCDashboardSales(!openListARCDashboardSales);
  };

  const handleClickForARCUploadAgencyTransaction = () => {
    setOpenListARCUploadAgencyTransaction(!openListARCUploadAgencyTransaction);
  };

  const handleClickForARCDashboardAgencyReport = () => {
    setOpenListARCDashboardAgencyReport(!openListARCDashboardAgencyReport);
  };

  const handleClickForARCDashboardPurchase = () => {
    setOpenListARCDashboardPurchase(!openListARCDashboardPurchase);
  };

  const handleClickForARCReconSales = () => {
    setOpenListARCReconSales(!openListARCReconSales);
  };

  const handleClickForARCReconPurchase = () => {
    setOpenListARCReconPurchase(!openListARCReconPurchase);
  };

  // const handleClickForARCReconPurchaseTDSPay = () => {
  //   setOpenListARCReconPurchaseTDSPayables(
  //     !openListARCReconPurchaseTDSPayables
  //   );
  // };

  const handleClickEventForIARS = () => {
    setOpenEventForIARS(!openEventForIARS);
  };

  const handleClickEventForENVOICE = () => {
    setOpenEventForENVOICE(!openEventForEINVOICE);
  };

  const handleClickEventForIAS = () => {
    setOpenEventForAIS(!openEventForAIS);
  };

  const recursion = (first_tree, original_tree) =>
    first_tree.map((v, i) => {
      let parent_id = v.id;
      const inside_tree = original_tree.filter(
        (v) => v.parent_id === parent_id
      );
      if (inside_tree.length) {
        const node = {
          value: v.title,
          id: v.id,
          file: v.file,
          nodes: recursion(inside_tree, original_tree),
        };
        console.log("inside");
        return node;
      } else {
        return {
          value: v.title,
          id: v.id,
          file: v.file,
          nodes: [],
        };
      }
    });

  const downloadPdf = (faq_id, file_name) => {
    axios.get(`download_faq/${faq_id}`).then((res) => {
      downloadBase64File(res.data.file, file_name);
    });
  };

  const downloadBase64File = (base64Data, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const FaqModal = (props) => {
    let first_tree = props.tree.filter((v) => v.parent_id === 0);
    let original_tree = recursion(first_tree, props.tree);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cappitall Want Solutions (CWS) FAQs?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiTreeView
            tree={original_tree}
            onLeafClick={(node) =>
              node["file"] ? downloadPdf(node["id"], node["value"]) : void 0
            }
            onParentClick={(node) =>
              node["file"] ? downloadPdf(node["id"], node["value"]) : void 0
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ color: "red" }} onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getResultApi = () => {
    let endpoint = '';

    let role = localStorage.getItem("role")

    const entityList = JSON.parse(localStorage.getItem('entity_list'))
    setadminData(entityList)


  };

  const workAction = (business_entity_id, entity_name, gst, contactEmailId) => {
    localStorage.setItem("business_entity_id", business_entity_id);
    localStorage.setItem("entity_name", entity_name);
    localStorage.setItem("gst", gst);
    localStorage.removeItem("PanNumber");
    localStorage.setItem("TriggerPanDashBoard", "false");
    localStorage.setItem("EmailId", contactEmailId);

    setEntitChangeModel(false)
    window.location.reload();
  };

  const entityTableHeader = () => {
    const tableHeader = ["Entity Name", "GSTIN", "Action"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  const entityTableData = () => {

    if (adminData?.length >= 1) {
      return adminData.map((tableData, index) => {
        const { business_entity_id, entity_name, gst, contact_email_id } = tableData;

        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

            <td>{gst}</td>
            <td>
              <Button
                size="small"
                onClick={() =>
                  workAction(business_entity_id, entity_name, gst, contact_email_id)
                }
                style={{
                  color: "#ba4242",
                  fontFamily: "Dosis",
                  fontWeight: "550",
                }}
              >
                select
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }

  }

  const searchInputOnchange = (event) => {
    setEntitySearchInput(event.target.value)
    const searchInput = event.target.value
    let searchAdminData = adminData.filter((value) => {
      const query = searchInput.toLowerCase();
      return (
        value.entity_name.toLowerCase().indexOf(query) >= 0

      );
    });
    setadminData(searchAdminData)
  }



  const EntityChangeModel = (props) => {

    return (
      <Modal
        {...props}
        dialogClassName="my-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Entity
          </Modal.Title>
          {/* <CssTextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder='Search Entity ...'
                    onChange={searchInputOnchange}
                    style={{
                      padding: "0px",
                      background: "#fff",
                      borderRadius: "7px",
                     marginLeft:'53%'
                    }}
                    value={entitySearchInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "#ddd" }} />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
        </Modal.Header>
        <Modal.Body style={{ height: "40vh", overflowY: "auto" }}>
          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>{entityTableHeader()}</tr>
              </thead>
              <tbody>{entityTableData()}</tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ height: "50px" }}>
          <Button style={{ color: "red" }} onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
    );
  };

  const sideBareMenusExpandedAllHandler = () => {
    if (toggleMenu) {
      setToggleButton(false);
      setOpenListIdBl(false);
      setOpenListARCUpload(false);
      setOpenListARCDashboard(false);
      setOpenListARCRecon(false);
      setOpenListIcOrigai(false);
      setOpenListARCUploadsales(false);
      setOpenListARCUploadPurchase(false);
      setOpenListARCUploadAgencyTransaction(false);
      setOpenListARCDashboardSales(false);
      setOpenListARCDashboardAgencyReport(false);
      setOpenListARCDashboardPurchase(false);
      setOpenListARCReconSales(false);
      setOpenListARCReconPurchase(false);
      setOpenEventForIARS(false);
      setOpenListARCandStandard(false);
      setOpenListCashOpt(false);
      setOpenEventForReports(false)
      setOpenEventForWorkingCapitallReports(false)
      setOpenEventForStatutoryReports(false)
      setOpenEventForFinancilaReports(false)
      setOpenEventForGSTR9(false)
      // setOpenListARCReconPurchaseTDSPayables(false);
    } else {
      setOpenListCashOpt(true);
      setOpenListARCandStandard(true);
      setToggleButton(true);
      setOpenListIdBl(true);
      setOpenListARCUpload(true);
      setOpenListARCDashboard(true);
      setOpenListARCRecon(true);
      setOpenListIcOrigai(true);
      setOpenListARCUploadsales(true);
      setOpenListARCUploadPurchase(true);
      setOpenListARCUploadAgencyTransaction(true);
      setOpenListARCDashboardSales(true);
      setOpenListARCDashboardAgencyReport(true);
      setOpenListARCDashboardPurchase(true);
      setOpenListARCReconSales(true);
      setOpenListARCReconPurchase(true);
      setOpenEventForIARS(true);
      setOpenEventForReports(true)
      setOpenEventForWorkingCapitallReports(true)
      setOpenEventForStatutoryReports(true)
      setOpenEventForFinancilaReports(true)
      setOpenEventForGSTR9(true)
      // setOpenListARCReconPurchaseTDSPayables(true);
    }
  };

  const handleEntityChangeOnClick = () => {

    setEntitChangeModel(!entitChangeModelShow)
    getResultApi()
  }

  return (
    <div className={classes.root} >
      <CssBaseline />
      {/* ---APPBAR--- */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ background: "#d04646" }}
      >
        <Toolbar>
          {/* LEFT */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Tooltip title="Profile">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfile}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>

          <p
            style={{ paddingLeft: "10px", paddingTop: "2px", fontSize: "16px" }}
          >
            {localStorage.getItem("user_name")}
          </p>

          {/* {localStorage.getItem("PanNumber")
              ? localStorage.getItem("PanNumber")
              : localStorage.getItem("entity_name")} */}

          {/* RIGHT */}
          <div style={{ marginLeft: "auto", textTransform: "capitalize" }}>
            <Tooltip title="FAQ">
              <IconButton
                edge="end"
                aria-label="FAQ"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => setFaqModalShow(true)}
                color="inherit"
              >
                <ContactSupportIcon />
              </IconButton>
            </Tooltip>
            {window.location.pathname !== "/home" ? (
              <Tooltip title="Home">
                <IconButton
                  edge="end"
                  aria-label="home"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleHomeButton}
                  color="inherit"
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}

            <Tooltip title="Logout">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={logout}
                color="inherit"
              >
                <ExitToAppIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        {/* SUB HEADER */}
        <div
          style={{
            borderTop: "1px solid #fff",
            padding: "10px 25px",
            fontSize: "18px",
            fontFamily: "Dosis",
            fontWeight: "600",
          }}
        >
          {localStorage.getItem("PanNumber")
            ? localStorage.getItem("PanNumber")
            : localStorage.getItem("entity_name")}
          {/* <IconButton  size="small" style={{ color: 'white',borderColor:'white',borderStyle:'solid',borderWidth:'0.5px',backgroundColor:'#FF7F7F',marginLeft:'3px' }} onClick={handleEntityChangeOnClick}>{entitChangeModelShow?<ExpandLess />:<ExpandMore/>}</IconButton> */}
        </div>
      </AppBar>

      {/* SIDE BAR--- */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* ---Drawer header--- */}
        <div className={classes.drawerHeader}>
          {/* LOGO SECTION */}
          <img
            style={{ cursor: "pointer" }}
            onClick={handleHomeButton}
            src={cws_logo}
            alt="cappitallwant"
            width="100%"
            height="100%"
          />
        </div>

        {/* MENUS */}
        <div className="sidebar">
          {localStorage.getItem("TriggerPanDashBoard") === "true" ? (
            <div className="sidebarMenus">
              <PanDashBoard />
            </div>
          ) : (
            <>
              {/* ---------------------Expand All Button--------------- */}

              <div className="menu-toggle-section">
                <p>menu Expand</p>
                <div className="menu-toggle-button">
                  <input
                    type="checkbox"
                    className="toggle"
                    id="rounded"
                    onChange={sideBareMenusExpandedAllHandler}
                  />
                  <label for="rounded" menu-off="Off" menu-on="On"></label>
                </div>
              </div>

              <div className="sidebarMenus">
                <List style={{ padding: "0px" }}>
                  {/* --------------Business Entity Details------------ */}
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/BusinessEntityDetails`}
                    activeStyle={activeLink}
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={"Business Entity Details"}
                    />
                  </ListItem>

                  <ListItem button onClick={handleClickForARCandStandard}>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      className={classes.menuText}
                      primary="Accounting & Reconciliation"
                    />
                    {openListARCandStandard ? (
                      <ExpandLess style={{ color: "#bfbfbf" }} />
                    ) : (
                      <ExpandMore style={{ color: "#bfbfbf" }} />
                    )}
                  </ListItem>
                  <Collapse
                    in={openListARCandStandard}
                    timeout="auto"
                    unmountOnExit
                  >
                    {/* --------------------ARC upload ------------------ */}
                    {sitemapData &&
                      (sitemapData.arcUploadSale ||
                        sitemapData.arcUploadPurchase) && (
                        <>
                          <ListItem button onClick={handleClickForARCUpload}>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              className={classes.menuText}
                              primary="Data-Upload"
                              style={{ marginLeft: '15px' }}
                            />
                            {openListARCUpload ? (
                              <ExpandLess style={{ color: "#bfbfbf" }} />
                            ) : (
                              <ExpandMore style={{ color: "#bfbfbf" }} />
                            )}
                          </ListItem>
                          <Collapse
                            in={openListARCUpload}
                            timeout="auto"
                            unmountOnExit
                          >
                            {/*....................Sales.............................*/}
                            {sitemapData.arcUploadSale && (
                              <>
                                <ListItem
                                  button
                                  onClick={handleClickForARCUploadsales}
                                >
                                  <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    className={classes.menuText1}
                                    primary="Sales"
                                    style={{ marginLeft: '15px' }}
                                  />
                                  {openListARCUploadSales ? (
                                    <ExpandLess style={{ color: "#bfbfbf" }} />
                                  ) : (
                                    <ExpandMore style={{ color: "#bfbfbf" }} />
                                  )}
                                </ListItem>

                                <Collapse
                                  in={openListARCUploadSales}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/BusinessCustomerManagement`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      className={classes.subMenu}
                                      primary={"Business Customer Mgmt"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>

                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/InvoiceManagement`}
                                    // style={{textAlign:'left'}}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      className={classes.subMenu}
                                      primary={"Invoice Management"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>

                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/uploadCollectionsData`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      className={classes.subMenu}
                                      primary={"Collections"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>

                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/uploadPaymentAdviceData`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      className={classes.subMenu}
                                      primary={"Payment Advice"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>
                                </Collapse>
                              </>
                            )}

                            {/* ..................Purchase.............................*/}
                            {sitemapData.arcUploadPurchase && (
                              <>
                                <ListItem
                                  button
                                  onClick={handleClickForARCUploadPurchase}
                                >
                                  <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    className={classes.menuText1}
                                    primary="Purchase"
                                    style={{ marginLeft: '15px' }}
                                  />
                                  {openListARCUploadPurchase ? (
                                    <ExpandLess style={{ color: "#bfbfbf" }} />
                                  ) : (
                                    <ExpandMore style={{ color: "#bfbfbf" }} />
                                  )}
                                </ListItem>

                                <Collapse
                                  in={openListARCUploadPurchase}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={"/vendorManagement"}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      className={classes.subMenu}
                                      primary={"Vendor Management "}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>
                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/PurchaseInvoiceMgmt`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      primary={"Bill Management"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>

                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/vendorCollections`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      className={classes.subMenu}
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      primary={"Vendor Payment"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>

                                  <ListItem
                                    button
                                    component={NavLink}
                                    to={`/VendorpaymentAdvice`}
                                    activeStyle={activeLink}
                                    className={classes.listStyleOnHover}
                                  >
                                    <ListItemText
                                      className={classes.subMenu}
                                      classes={{
                                        primary: classes.listItemText,
                                      }}
                                      primary={"Vendor Payment Advice"}
                                      style={{ marginLeft: '45px', width: '100%' }}
                                    />
                                  </ListItem>
                                </Collapse>
                              </>
                            )}

                            {/*..............Agency Transaction..............*/}
                            {/* <ListItem
                          button
                          onClick={handleClickForARCUploadAgencyTransaction}
                        >
                          <ListItemText
                            className={classes.menuText1}
                            primary="Agency Transaction"
                          />
                          {openListARCUploadAgencyTransaction ? (
                            <ExpandLess style={{ color: "#fff" }} />
                          ) : (
                            <ExpandMore style={{ color: "#fff" }} />
                          )}
                        </ListItem>

                        <Collapse
                          in={openListARCUploadAgencyTransaction}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItem
                            button
                            component={NavLink}
                            to={`/uploadCollectionAgenciesData`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.paddingOnList}
                              primary={"Collection Agencies"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/uploadCollectionAgencyTransactionsData`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.paddingOnList}
                              primary={"Collection Agency Transactions"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/uploadInvoiceDiscountingAgencyData`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.paddingOnList}
                              primary={"Invoice Discounting Agency"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/uploadInvoiceDiscountingData`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.paddingOnList}
                              primary={"Invoice Discounting"}
                            />
                          </ListItem>
                        </Collapse> */}
                          </Collapse>
                        </>
                      )}

                    {/*-------------------- ARC-Dashboard-----------------*/}
                    {sitemapData &&
                      sitemapData.navigation_data["ARC DASHBOARD"] && (
                        <>
                          <ListItem button onClick={handleClickForARCDashboard}>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              className={classes.menuText}
                              primary="ARC-Dashboard"
                              style={{ marginLeft: '15px' }}
                            />
                            {openListARCDashboard ? (
                              <ExpandLess style={{ color: "#bfbfbf" }} />
                            ) : (
                              <ExpandMore style={{ color: "#bfbfbf" }} />
                            )}
                          </ListItem>
                          <Collapse
                            in={openListARCDashboard}
                            timeout="auto"
                            unmountOnExit
                          >
                            {/* ARC-Dashboard Sales */}
                            {sitemapData.navigation_data["ARC DASHBOARD"]
                              .SALES && (
                                <>
                                  <ListItem
                                    button
                                    onClick={handleClickForARCDashboardSales}
                                  >
                                    <ListItemText
                                      classes={{ primary: classes.listItemText }}
                                      className={classes.menuText1}
                                      primary="Sales"
                                      style={{ marginLeft: '15px' }}
                                    />
                                    {openListARCDashboardSales ? (
                                      <ExpandLess style={{ color: "#bfbfbf" }} />
                                    ) : (
                                      <ExpandMore style={{ color: "#bfbfbf" }} />
                                    )}
                                  </ListItem>

                                  <Collapse
                                    in={openListARCDashboardSales}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {/* <ListItem
                          button
                          component={NavLink}
                          to={`/AgeingReceivablesSummary`}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            background: "rgba(241,222,250,0.275)",
                          }}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            className={classes.subMenu}
                            primary={"Ageing / Receivables"}
                          />
                        </ListItem> */}

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/AgeingReceivablesSummaryDashboardv2`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Ageing / Receivables"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/invoiceSummary`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Invoice Summary"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/unallocatedPayments`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Unallocated Receipts"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                    {/* 
                              <ListItem
                                button
                                component={NavLink}
                                to={`/AppBarForCfo`}
                                activeStyle={activeLink}
                                className={classes.listStyleOnHover}
                              >
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                  className={classes.subMenu}
                                  primary={"Debtors Status"}
                                />
                              </ListItem> */}

                                    {/* control reports */}
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/InvalidCrDrNoteSale`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Invalid Credit/Debit Note"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/InputTaxCrToBeReversedSale`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Input Tax Cr to be reversed"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  </Collapse>
                                </>
                              )}

                            {/* ARC-Dashboard Purchase  */}
                            {sitemapData.navigation_data["ARC DASHBOARD"]
                              .PURCHASE && (
                                <>
                                  <ListItem
                                    button
                                    onClick={handleClickForARCDashboardPurchase}
                                  >
                                    <ListItemText
                                      classes={{ primary: classes.listItemText }}
                                      className={classes.menuText1}
                                      primary="Purchase"
                                      style={{ marginLeft: '15px' }}
                                    />
                                    {openListARCDashboardPurchase ? (
                                      <ExpandLess style={{ color: "#bfbfbf" }} />
                                    ) : (
                                      <ExpandMore style={{ color: "#bfbfbf" }} />
                                    )}
                                  </ListItem>
                                  <Collapse
                                    in={openListARCDashboardPurchase}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {/* <ListItem
                          button
                          component={NavLink}
                          to={`/vendorAgeingSummary`}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            background: "rgba(241,222,250,0.275)",
                          }}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            className={classes.subMenu}
                            primary={"Ageing/Payables"} 
                          />
                        </ListItem> */}

                                    {/* ageing payables dashboard v2.O */}

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/vendorAgeingSummaryDashboardV2`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Ageing / Payables"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/vendorInvoiceSummary`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Bill Summary"} // old Name=> Vendor Invoice Summary // Again Change Old one Invoice Summary
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/payCycleSummaryDashboard`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Pay Cycle Summary"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/InvalidCrDrNote`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Invalid Credit/Debit Note"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/InputTaxCrToBeReversed`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Input Tax Cr to be reversed"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  </Collapse>
                                </>
                              )}

                            {/* ARC-Dashboard Agency Report  */}
                            {/* <ListItem
                              button
                              onClick={handleClickForARCDashboardAgencyReport}
                            >
                              <ListItemText
                                className={classes.menuText1}
                                primary="Agency Report"
                              />
                              {openListARCDashboardAgencyReport ? (
                                <ExpandLess style={{ color: "#fff" }} />
                              ) : (
                                <ExpandMore style={{ color: "#fff" }} />
                              )}
                            </ListItem>

                            <Collapse
                              in={openListARCDashboardAgencyReport}
                              timeout="auto"
                              unmountOnExit
                            >
                              <ListItem
                                button
                                component={NavLink}
                                to={`/invoiceCollectionEntries`}
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  background: "rgba(241,222,250,0.275)",
                                }}
                                className={classes.listStyleOnHover}
                              >
                                <ListItemText
                                  className={classes.subMenu}
                                  primary={"Invoice Collection Entries"}
                                />
                              </ListItem>

                              <ListItem
                                button
                                component={NavLink}
                                to={`/invoiceDiscountingEntries`}
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: "#fff",
                                  background: "rgba(241,222,250,0.275)",
                                }}
                                className={classes.listStyleOnHover}
                              >
                                <ListItemText
                                  className={classes.subMenu}
                                  primary={"Invoice Discounting Entries"}
                                />
                              </ListItem>
                            </Collapse> */}
                          </Collapse>
                        </>
                      )}

                    {/* ------------------ARC-Reconciliation--------------*/}
                    {sitemapData &&
                      sitemapData.navigation_data["ARC RECONCILLIATION"] && (
                        <>
                          <ListItem button onClick={handleClickForARCRecon}>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              className={classes.menuText}
                              primary="ARC-Reconciliation"
                              style={{ marginLeft: '15px' }}
                            />
                            {openListARCRecon ? (
                              <ExpandLess style={{ color: "#bfbfbf" }} />
                            ) : (
                              <ExpandMore style={{ color: "#bfbfbf" }} />
                            )}
                          </ListItem>
                          <Collapse
                            in={openListARCRecon}
                            timeout="auto"
                            unmountOnExit
                          >
                            {/*SALES*/}
                            {sitemapData.navigation_data["ARC RECONCILLIATION"]
                              .SALES && (
                                <>
                                  <ListItem
                                    button
                                    onClick={handleClickForARCReconSales}
                                  >
                                    <ListItemText
                                      classes={{ primary: classes.listItemText }}
                                      className={classes.menuText1}
                                      primary="Sales"
                                      style={{ marginLeft: '15px' }}
                                    />
                                    {openListARCReconSales ? (
                                      <ExpandLess style={{ color: "#bfbfbf" }} />
                                    ) : (
                                      <ExpandMore style={{ color: "#bfbfbf" }} />
                                    )}
                                  </ListItem>
                                  <Collapse
                                    in={openListARCReconSales}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("GSTR1-PREVALIDATION") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/gstPreValidation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-1 Pre-Validation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("FILLING") && (
                                        <ListItem
                                          className={classes.listStyleOnHover}
                                          button
                                          component={NavLink}
                                          to={`/Reporting`}
                                          activeStyle={activeLink}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            primary={"GSTR-1 Filing"}
                                            className={classes.subMenu}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("GSTR1-REPOSITORY") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/GSTRepo`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            primary={"GSTR-1 Repository"}
                                            className={classes.subMenu}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("GSTR1-RECONCILLATION") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/GSTReconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-1 Reconciliation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("26AS-TDS-REPOSITORY") && (localStorage.getItem("show_recon") != "N") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/TDSRepo`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"26AS-TDS Repository"} //old name TDS Repository
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {/* <ListItem
                          button
                          component={NavLink}
                          to={`/TDSReconciliation`}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            background: "rgba(241,222,250,0.275)",
                          }}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            className={classes.subMenu}
                            primary={"TDS Receivables Recon"} //Old Name TDS Reconciliation
                          />
                        </ListItem> */}
                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("TDS RECIEVABLE RECON") && (localStorage.getItem("show_recon") != "N") && (


                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/26ASRecon`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"26AS-TDS Recon"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ]?.SALES?.includes("TDS RECIEVABLE RECON") && (localStorage.getItem("show_recon") != "N") && (


                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/TCS/Recon/26AS`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"26AS-TCS Recon"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}


                                    {/* {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("TDS RECIEVABLE RECON") && (

                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/TDSReconciliationSaleV`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"TDS Receivables Recon"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>



                                      )} */}



                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes(
                                      "RECIEPT RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/BankReconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"Receipts Reconciliation"} //old name Bank Reconciliation
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("GSTR1-SAVE") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gstr1Save`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-1 Save"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}


                              {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].SALES.includes("E-INVOICE RECONCILIATION") && (
                                        <>
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/E-InvoiceRepo`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            primary={"E-Invoice Repository"}
                                            className={classes.subMenu}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>


                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/e_invoicereconcilation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            primary={"E-Invoice Reconciliation"}
                                            className={classes.subMenu}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>

                                        </>
                                      )}

                                   
                                  </Collapse>
                                </>
                              )}

                            {/*PURCHASE*/}
                            {sitemapData.navigation_data["ARC RECONCILLIATION"]
                              .PURCHASE && (
                                <>
                                  <ListItem
                                    button
                                    onClick={handleClickForARCReconPurchase}
                                  >
                                    <ListItemText
                                      classes={{ primary: classes.listItemText }}
                                      className={classes.menuText1}
                                      primary="Purchase"
                                      style={{ marginLeft: '15px' }}
                                    />
                                    {openListARCReconPurchase ? (
                                      <ExpandLess style={{ color: "#bfbfbf" }} />
                                    ) : (
                                      <ExpandMore style={{ color: "#bfbfbf" }} />
                                    )}
                                  </ListItem>

                                  <Collapse
                                    in={openListARCReconPurchase}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2-PREVALIDATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gstr2bPrevalidation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-2 Pre-Validation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}
                                    {/* //GSt2a Status */}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2-PREVALIDATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gst2aStaus`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-STATUS"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes("GSTR2A-REPOSITORY") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gst2aRepository`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-2A Repository"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2A-RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gst2aReconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-2A Reconciliation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}



                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes("GSTR2B-REPOSITORY") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gstr2bRepository`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-2B Repository"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2B-RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gstr2bReconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-2B Reconciliation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {/* {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2B-RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/itcAvailReportBooks`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"ITC Avail Report Books"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )} */}

                                    {/* {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2B-RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/ageing_report_dashboard`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"Aging Report"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )} */}

                                    {/* {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "GSTR2B-RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/itemWiseReportHsn`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"ITEM WISE REPORT"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )} */}


                                    {
                                      sitemapData.navigation_data[
                                        "ARC RECONCILLIATION"
                                      ].PURCHASE.includes(
                                        "GSTR3B"
                                      ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/GSTR_3B_Reconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-3B Report"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )
                                    }

                                    {
                                      sitemapData.navigation_data[
                                        "ARC RECONCILLIATION"
                                      ]
                                        .PURCHASE.includes("GSTR3B") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/GSTR_3B_Repository`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-3B Repository"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    {/* {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes("GSTR2B-REPOSITORY") && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gstr8aRepository`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-8A Repository"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )} */}


                                    {/* {
                                      sitemapData.navigation_data[
                                        "ARC RECONCILLIATION"
                                      ].PURCHASE.includes(
                                        "GSTR3B"
                                      ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Outward_comparison`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"Outward comparison"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )
                                    } */}



                                    {/* {
                                      sitemapData.navigation_data[
                                        "ARC RECONCILLIATION"
                                      ].PURCHASE.includes(
                                        "GSTR3B"
                                      ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Inward_comparison`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"Inward comparison"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )
                                    } */}



                                    {/* {
                                      sitemapData.navigation_data[
                                        "ARC RECONCILLIATION"
                                      ].PURCHASE.includes(
                                        "GSTR3B"
                                      ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/GSTR_9Reconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"GSTR-9 Reconcilation"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )
                                    } */}

                                    {/*
                          <ListItem
                          button
                          component={NavLink}
                          to={`/zohoTesting`}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            background: "rgba(241,222,250,0.275)",
                          }}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            className={classes.subMenu}
                            primary={"Zoho"}
                          />
                        </ListItem>
                      */}

                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "TDS PAYABLE RECONCILLATION"
                                    ) && (
                                        <ListItem
                                          button
                                          component={NavLink}
                                          to={`/Gst2aTdsReconciliation`}
                                          activeStyle={activeLink}
                                          className={classes.listStyleOnHover}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            className={classes.subMenu}
                                            primary={"TDS Payables Recon"}
                                            style={{ marginLeft: '45px', width: '100%' }}
                                          />
                                        </ListItem>
                                      )}

                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/TDSProvision`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Provision"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                    {sitemapData.navigation_data[
                                      "ARC RECONCILLIATION"
                                    ].PURCHASE.includes(
                                      "AIS REPOSITORY"
                                    ) && (
                                        <>
                                          <ListItem button onClick={handleClickEventForIAS}>
                                            <ListItemText
                                              classes={{ primary: classes.listItemText }}
                                              className={classes.menuText}
                                              primary="AIS"
                                              style={{ marginLeft: '45px', width: '100%' }}
                                            />
                                            {openEventForAIS ? (
                                              <ExpandLess style={{ color: "#bfbfbf" }} />
                                            ) : (
                                              <ExpandMore style={{ color: "#bfbfbf" }} />
                                            )}
                                          </ListItem>
                                          <Collapse
                                            in={openEventForAIS}
                                            timeout="auto"
                                            unmountOnExit
                                          >

                                            <ListItem
                                              button
                                              component={NavLink}
                                              to={`/AisUpload`}
                                              activeStyle={activeLink}
                                              className={classes.listStyleOnHover}
                                            >
                                              <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                className={classes.subMenu}
                                                primary={"AIS REPOSITORY"}
                                                style={{ marginLeft: '45px', width: '100%' }}

                                              />
                                            </ListItem>
                                            <ListItem
                                              button
                                              component={NavLink}
                                              to={`/AisDashboard`}
                                              activeStyle={activeLink}
                                              className={classes.listStyleOnHover}

                                            >
                                              <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                className={classes.subMenu}
                                                primary={"AIS RECONCILLATION"}
                                                style={{ marginLeft: '42px', width: '100%' }}
                                              />
                                            </ListItem>




                                          </Collapse>
                                        </>
                                      )}
                                  </Collapse>
                                </>
                              )}
                          </Collapse>
                        </>
                      )}
                  </Collapse>

                  {/* ID Service list */}
                  {/* {ID_service &&
                    ID_service.status === "Approved" &&
                    ID_service.from_date <= currentDate &&
                    ID_service.to_date >= currentDate && (
                      <>
                        <ListItem
                          button
                          onClick={handleClickForBillionLoanList}
                        >
                          <ListItemText
                            className={classes.menuText}
                            primary="ID-BillionLoans"
                          />
                          {openListIdBl ? (
                            <ExpandLess style={{ color: "#fff" }} />
                          ) : (
                            <ExpandMore style={{ color: "#fff" }} />
                          )}
                        </ListItem>
                        <Collapse
                          in={openListIdBl}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItem
                            button
                            component={NavLink}
                            to={`/Borroweronboarding`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Borrower on-boarding"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/Buyeronboarding`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Buyer on-boarding"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/InvoiceAuthorization`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Invoice Authorization"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/InvoiceFinancing`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Invoice Financing"}
                            />
                          </ListItem>
                        </Collapse>
                      </>
                    )}
                   */}

                  {/* IC service list */}
                  {/* {IC_service &&
                    IC_service.status === "Approved" &&
                    IC_service.from_date <= currentDate &&
                    IC_service.to_date >= currentDate && (
                      <>
                        <ListItem button onClick={handleClickForIcOrigai}>
                          <ListItemText
                            className={classes.menuText}
                            primary="IC-Origai"
                          />
                          {openListIcOrigai ? (
                            <ExpandLess style={{ color: "#fff" }} />
                          ) : (
                            <ExpandMore style={{ color: "#fff" }} />
                          )}
                        </ListItem>
                        <Collapse
                          in={openListIcOrigai}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItem
                            button
                            component={NavLink}
                            to={`/batchManagement`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Batch Management"}
                            />
                          </ListItem>
                          <ListItem
                            button
                            component={NavLink}
                            to={`/batchList`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Batch List"}
                            />
                          </ListItem>

                          <ListItem
                            button
                            component={NavLink}
                            to={`/viewCollectionStatus`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"View Collection Status"}
                            />
                          </ListItem>
                          <ListItem
                            button
                            component={NavLink}
                            to={`/receipt`}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#fff",
                              background: "rgba(241,222,250,0.275)",
                            }}
                            className={classes.listStyleOnHover}
                          >
                            <ListItemText
                              className={classes.subMenu}
                              primary={"Receipt"}
                            />
                          </ListItem>
                        </Collapse>
                        
                      </>
                    )} */}

                  {sitemapData && sitemapData.navigation_data['GSTR-9 RECONCILIATION'] && (
                    <>
                      <ListItem button onClick={handleClickEventForGSTR9}>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          className={classes.menuText}
                          primary="GSTR-9"
                        />
                        {openEventForGSTR9 ? (
                          <ExpandLess style={{ color: "#bfbfbf" }} />
                        ) : (
                          <ExpandMore style={{ color: "#bfbfbf" }} />
                        )}
                      </ListItem>
                      <Collapse
                        in={openEventForGSTR9}
                        timeout="auto"
                        unmountOnExit
                      >
                        {sitemapData.navigation_data[
                          "GSTR-9 RECONCILIATION"
                        ]['GSTR-9'].includes("GSTR-9 RECONCILIATION") && (
                            <ListItem
                              button
                              component={NavLink}
                              to={`/GSTR_9Reconciliation`}
                              activeStyle={activeLink}
                              className={classes.listStyleOnHover}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.listItemText,
                                }}
                                className={classes.subMenu}
                                primary={"GSTR-9 Reconcilation"}

                              />
                            </ListItem>
                          )}

                        {sitemapData.navigation_data[
                          "GSTR-9 RECONCILIATION"
                        ]['GSTR-9'].includes("GSTR-9 RECONCILIATION") && (
                            <ListItem
                              button
                              component={NavLink}
                              to={`/Gstr8aRepository`}
                              activeStyle={activeLink}
                              className={classes.listStyleOnHover}
                            >
                              <ListItemText
                                classes={{
                                  primary: classes.listItemText,
                                }}
                                className={classes.subMenu}
                                primary={"GSTR-8A Repository"}

                              />
                            </ListItem>
                          )}

                      </Collapse>
                    </>
                  )}


                  {sitemapData && sitemapData.navigation_data['REPORT ANALYTICS'] && (
                    <>
                      <ListItem button onClick={handleClickReportingAndAnalytics}>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          className={classes.menuText}
                          primary="Reporting & Analytics"
                        />
                        {openEventForReports ? (
                          <ExpandLess style={{ color: "#bfbfbf" }} />
                        ) : (
                          <ExpandMore style={{ color: "#bfbfbf" }} />
                        )}
                      </ListItem>
                      <Collapse
                        in={openEventForReports}
                        timeout="auto"
                        unmountOnExit
                      >
                        {sitemapData.navigation_data[
                          "REPORT ANALYTICS"
                        ]['WORKING CAPITAL'] && (
                            <>
                              <ListItem
                                button
                                className={classes.listStyleOnHover}

                                onClick={handleClickWorkingCapitalReportingAndAnalytics}
                              >
                                <ListItemText
                                  classes={{
                                    primary: classes.listItemText,
                                  }}
                                  className={classes.subMenu}
                                  primary={"Working Capital"}

                                />
                                {openEventForWorkingCapitallReports ? (
                                  <ExpandLess style={{ color: "#bfbfbf" }} />
                                ) : (
                                  <ExpandMore style={{ color: "#bfbfbf" }} />
                                )}
                              </ListItem>
                              <Collapse
                                in={openEventForWorkingCapitallReports}
                                timeout="auto"
                                unmountOnExit
                              >
                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['WORKING CAPITAL'].includes("ITEM WISE REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/itemWiseReportHsn`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Item wise report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}
                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['WORKING CAPITAL'].includes("AGEING REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/ageing_recivable_report_dashboard`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Ageing Receivable"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}
                                  {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['WORKING CAPITAL'].includes("AGEING REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/ageing_payable_report_dashboard`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Ageing Payable"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}



                              </Collapse>
                            </>
                          )}

                        {sitemapData.navigation_data[
                          "REPORT ANALYTICS"
                        ]['STATUTORY COMPLIANCE'] && (
                            <>
                              <ListItem
                                button
                                // component={NavLink}
                                // to={`/GSTR_9Reconciliati`}
                                // activeStyle={activeLink}
                                className={classes.listStyleOnHover}
                                onClick={handleClickStatutoryReportingAndAnalytics}
                              >
                                <ListItemText
                                  classes={{
                                    primary: classes.listItemText,
                                  }}
                                  className={classes.subMenu}
                                  primary={"Statutory Compliance"}

                                />
                                {openEventForStatutoryReports ? (
                                  <ExpandLess style={{ color: "#bfbfbf" }} />
                                ) : (
                                  <ExpandMore style={{ color: "#bfbfbf" }} />
                                )}
                              </ListItem>
                              <Collapse
                                in={openEventForStatutoryReports}
                                timeout="auto"
                                unmountOnExit
                              >

                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("ITC AVAILABILITY REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/itcAvailReportBooks`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"ITC Availability Report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}




                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("ITC REVERSAL REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/itcRevarsalReport`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"ITC Reversal Report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}

                                {/* VENDOR CANCELLATION REPORT */}
                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("VENDOR CANCELLATION REPORT") && (


                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/VendorCancellation`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Vendor Cancellation Report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                  )}

                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("MULTIMONTH REPORT") && (


                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/MultiMonth`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Multi-Month Report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>

                                  )}





{sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("HSN SUMMARY REPORT") && (


                                    
                                  <ListItem
                                  button
                                  component={NavLink}
                                  to={`/HsnSummaryReport`}
                                  activeStyle={activeLink}
                                  className={classes.listStyleOnHover}
                                >
                                  <ListItemText
                                    classes={{
                                      primary: classes.listItemText,
                                    }}
                                    className={classes.subMenu}
                                    primary={"HSN Summary Report"}
                                    style={{ marginLeft: '45px', width: '100%' }}
                                  />
                                </ListItem>

                                  )}

                                  


                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("GST INWARD COMPARISON") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/Inward_comparison`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Inward comparison"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}

                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("GST OUTWARD COMPARISON") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/Outward_comparison`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"Outward comparison"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}

                                {sitemapData.navigation_data[
                                  "REPORT ANALYTICS"
                                ]['STATUTORY COMPLIANCE'].includes("GST COMPUTATION REPORT") && (
                                    <ListItem
                                      button
                                      component={NavLink}
                                      to={`/gst_computation_report`}
                                      activeStyle={activeLink}
                                      className={classes.listStyleOnHover}
                                    >
                                      <ListItemText
                                        classes={{
                                          primary: classes.listItemText,
                                        }}
                                        className={classes.subMenu}
                                        primary={"GST Computation Report"}
                                        style={{ marginLeft: '45px', width: '100%' }}
                                      />
                                    </ListItem>
                                  )}


                              </Collapse>
                            </>
                          )}

                        {sitemapData.navigation_data[
                          "REPORT ANALYTICS"
                        ]['Financial Reports'] && (
                            <>
                              <ListItem
                                button
                                component={NavLink}
                                to={`/GSTR_9econciliati`}
                                activeStyle={activeLink}
                                className={classes.listStyleOnHover}
                                onClick={handleClickFinancialReportingAndAnalytics}
                              >
                                <ListItemText
                                  classes={{
                                    primary: classes.listItemText,
                                  }}
                                  className={classes.subMenu}
                                  primary={"Financial Reports"}

                                />
                                {openEventForFinancilaReports ? (
                                  <ExpandLess style={{ color: "#bfbfbf" }} />
                                ) : (
                                  <ExpandMore style={{ color: "#bfbfbf" }} />
                                )}
                              </ListItem>
                              <Collapse
                                in={openEventForFinancilaReports}
                                timeout="auto"
                                unmountOnExit
                              >




                              </Collapse>
                            </>
                          )}



                      </Collapse>
                    </>
                  )}



                  {/*------------------- IARS Service list------------- */}
                  {/* {sitemapData && sitemapData.navigation_data.IARS && (
                    <>
                      <ListItem button onClick={handleClickEventForIARS}>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          className={classes.menuText}
                          primary="IARS"
                        />
                        {openEventForIARS ? (
                          <ExpandLess style={{ color: "#bfbfbf" }} />
                        ) : (
                          <ExpandMore style={{ color: "#bfbfbf" }} />
                        )}
                      </ListItem>
                      <Collapse
                        in={openEventForIARS}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ListItem
                          button
                          component={NavLink}
                          to={`/iarsloginDashboard`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Login"}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component={NavLink}
                          to={`/iarsexecutivesummary`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Executive Summary"}
                          />
                        </ListItem>

                        <ListItem
                          button
                          component={NavLink}
                          to={`/iarsreasonwise`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Reasonwise Report"}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component={NavLink}
                          to={`/damagestocksummary`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Damaged Stock Report"}
                          />
                        </ListItem>
                      </Collapse>
                    </>
                  )} */}

                  {/*------------------- EINVOICE Service list------------- */}
                  {sitemapData && sitemapData.navigation_data.EINVOICE && (
                    <>
                      <ListItem button onClick={handleClickEventForENVOICE}>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          className={classes.menuText}
                          primary="E-INVOICE"
                        />
                        {openEventForEINVOICE ? (
                          <ExpandLess style={{ color: "#bfbfbf" }} />
                        ) : (
                          <ExpandMore style={{ color: "#bfbfbf" }} />
                        )}
                      </ListItem>
                      <Collapse
                        in={openEventForEINVOICE}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ListItem
                          button
                          component={NavLink}
                          to={`/einvoice`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Uploads"}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component={NavLink}
                          to={`/EinvoicePreValidation`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Pre-Validation"}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component={NavLink}
                          to={`/Summary`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Summary"}
                          />
                        </ListItem>

                        <ListItem
                          button
                          component={NavLink}
                          to={`/EinvoiceDashBoard`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Dashboard"}
                          />
                        </ListItem>


                        {/* <ListItem
                          button
                          component={NavLink}
                          to={`/damagestocksummary`}
                          activeStyle={activeLink}
                          className={classes.listStyleOnHover}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.subMenu}
                            primary={"Bulk IRN Cancel"}
                          />
                        </ListItem>  */}
                      </Collapse>
                    </>
                  )}

                  {sitemapData &&
                    sitemapData.navigation_data["CREDIT EVALUATION"] && (
                      <>
                        <ListItem button onClick={handleClickForCashOpt}>
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            className={classes.menuText}
                            primary="Cash Optimisation and Financing (COF)"
                          />
                          {openListCashOpt ? (
                            <ExpandLess style={{ color: "#bfbfbf" }} />
                          ) : (
                            <ExpandMore style={{ color: "#bfbfbf" }} />
                          )}
                        </ListItem>
                        <Collapse
                          in={openListCashOpt}
                          timeout="auto"
                          unmountOnExit
                        >
                          {/* --------------------Credit Evaluation------------ */}

                          <ListItem
                            className={classes.listStyleOnHover}
                            button
                            component={NavLink}
                            to={`/dashboardCreditEvalution`}
                            activeStyle={activeLink}
                          >
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={"Credit Evaluation"}
                              style={{ marginLeft: '15px' }}
                            />
                          </ListItem>
                        </Collapse>
                      </>
                    )}
                  {localStorage.getItem("UploadTallyStatus") === "true" && (
                    <ListItem
                      className={classes.listStyleOnHover}
                      button
                      component={NavLink}
                      to={`/UploadTallyBackUpFile`}
                      activeStyle={activeLink}
                    >
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary={"Upload Tally File"}
                      />
                    </ListItem>
                  )}

                  {sitemapData &&
                    sitemapData.navigation_data[
                    "MASTER DATA MAINTAINANCE [MDM]"
                    ] && (
                      <>
                        <ListItem
                          className={classes.listStyleOnHover}
                          button
                          component={NavLink}
                          to={`/mdm`}
                          activeStyle={activeLink}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={"Master Data Maintenance (MDM)"}
                          />
                        </ListItem>
                      </>
                    )}

                  {sitemapData &&
                    sitemapData.navigation_data[
                    "MSME"
                    ] && (
                      <>
                        <ListItem
                          className={classes.listStyleOnHover}
                          button
                          component={NavLink}
                          to={`/msme-page`}
                          activeStyle={activeLink}
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={"MSME"}
                          />
                        </ListItem>
                      </>
                    )}



                </List>
              </div>
            </>
          )}
        </div>
      </Drawer>

      {/*---main content of dashboards start---*/}
      <main
        style={{ overflowX: 'auto' }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeaderSpace} />
        <div style={{ marginBottom: "30px" }}>{props.children}</div>

        {/* FOOTER */}
        <footer className={footerStyle}>
          <div className="left">
            For support , please contact{" "}
            <a
              target="_self"
              href='mailto:support@cappitallwant.com'
              style={{ color: "#000", fontWeight: "600" }}
            >
              support@cappitallwant.com
            </a>
          </div>
          <div className="right">
            Powered by CWS&#8482; |{" "}
            <a href="https://www.cappitallwant.com/" target={"blank"}>
              {" "}
              Terms of use
            </a>
          </div>
        </footer>
      </main>

      <FaqModal
        show={faqModalShow}
        onHide={() => setFaqModalShow(false)}
        tree={faqTree}
      />

      <EntityChangeModel show={entitChangeModelShow}
        onHide={() => setEntitChangeModel(false)}
      />
    </div>
  );
}
